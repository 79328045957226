<template>
  <div class="content">
    <!--文章内容区域-->

    <!--    <div class="separator no-border mt60 full-width"></div>-->
    <section class="blog-list blog-style-two" style="margin-top: 20px">
      <div class="container">
        <div class="row clearfix">
          <div class="col-sm-12 col-xs-12">
            <div class="has-right-sidebar">
              <div class="thm-unit-test">
                <div
                  class="
                    unique_post_class
                    post-157 post
                    type-post
                    status-publish
                    format-standard
                    has-post-thumbnail
                    hentry
                    category-latest-news
                    tag-financial tag-investment tag-life
                  "
                >
                  <div class="single-blog-post-style-two">
                    <div class="text-box">
                      <!--平台介绍-->
                      <div class="text-center about-tit">
                        <h2>
                          <span class="a_font co_green" href="">平台介绍</span>
                          <p>INTRODUCTION</p>
                        </h2>
                      </div>

                      <p>
                        慢慢心理是一家面向个体和家庭的心理辅导，儿童青少年、抑郁焦虑恐惧情绪、职业心理辅导、创伤危机干预等心理咨询中心解决方案的提供商。
                      </p>

                      <p>
                        并且开发了新型对心理咨询机器人的整合式心理流派，并且有许多成功案例和丰富的经验积累。公司自成立以来，一直专注于心理咨询的研究和突破，致力于中国应用心理标准的引领和开拓，始终以行业最高标准严格律己，针对不同用户量身定做适合的产品及方案。经过多年的沉淀，已经成长为全国性的心理服务品牌。通过提升的心理产品开发与研究，不断优化心理问题解决方案，得到各地政府、机构和专家的大力指导和支持，在此特别感谢中科院心理研究所、中国心理学会、中国心理卫生协会、全国心理服务机构联合体及全国20余位资深的心理专家等。能够更好的普及心理学应用和心理健康实用知识，
                        让大众心理健康一直处于最理想的状态，不断提升国民的幸福指数。
                      </p>

                      <div class="bighenglan mt_60 mb_90" id="gswh"></div>
                      <!--平台介绍end-->
                      <!--公司文化-->
                      <div class="text-center about-tit">
                        <h2>
                          <span class="a_font co_green" href="">公司文化</span>
                          <p>Corporate culture</p>
                        </h2>
                      </div>

                      <p>
                        公司自成立以来，一直专注于心理咨询的研究和突破的理念，以在全社会广泛普及心灵成长教育的高度责任感为己任，我们很快就走在同行的前列，回归人性本质，致力于研究、传播本世纪最贴近生活的心灵成长文化，开发优秀的心灵成长课程，提供最具个性化的温馨心理咨询服务，运用最先进的心理培训行业拓展方法，帮助个人、家庭、团体走出生活、工作的迷茫和困境，更加充分认识到自身的价值和潜力，并最终为和谐家庭提升生活幸福指数，为和谐社会创造更多灵性价值。
                        经过多年的沉淀，已经成长为全国性的心理服务品牌。通过提升的心理产品开发与研究，不断优化心理问题解决方案，得到各地政府、机构和专家的大力指导和支持，在此特别感谢中科院心理研究所、中国心理学会、中国心理卫生协会、全国心理服务机构联合体及全国20余位资深的心理专家等。能够更好的普及心理学应用和心理健康实用知识，
                        让大众心理健康一直处于最理想的状态，不断提升国民的幸福指数。
                      </p>
                      <p class="text-center">
                        <img
                        style="width:100%;margin-top:40px;"
                          src="../../../assets/image/cooperative/about.png"
                          class="
                            attachment-appilo_1170x360
                            size-appilo_1170x360
                            wp-post-image
                          "
                          alt=""
                        />
                      </p>
                      <div class="bighenglan mt_60 mb_90" id="swhz"></div>
                      <!--公司文化end-->
                      <!--商务合作-->
                      <div class="text-center about-tit">
                        <h2>
                          <span class="a_font co_green" href="">商务合作</span>
                          <p>Business cooperation</p>
                        </h2>
                      </div>
                      <p
                        class="text-center"
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: space-around;
                          flex-wrap: wrap;
                        "
                      >
                        <img
                          style="flex: 0 0 21%;margin-bottom:40px;"
                          src="../../../assets/image/cooperative/pic1.png"
                        />
                        <img
                          style="flex: 0 0 21%;margin-bottom:40px;"
                          src="../../../assets/image/cooperative/pic2.png"
                        />
                        <img
                          style="flex: 0 0 21%;margin-bottom:40px;"
                          src="../../../assets/image/cooperative/pic3.png"
                        />
                        <img
                          style="flex: 0 0 21%;margin-bottom:40px;"
                          src="../../../assets/image/cooperative/pic4.png"
                        />
                        <img
                          style="flex: 0 0 21%"
                          src="../../../assets/image/cooperative/pic5.png"
                        />
                        <img
                          style="flex: 0 0 21%"
                          src="../../../assets/image/cooperative/pic6.png"
                        />
                        <img
                          style="flex: 0 0 21%"
                          src="../../../assets/image/cooperative/pic7.png"
                        />
                        <img
                          style="flex: 0 0 21%"
                          src="../../../assets/image/cooperative/pic8.png"
                        />
                        <!-- <img  src="static/images/hezuo.png" class="attachment-appilo_1170x360 size-appilo_1170x360 wp-post-image" alt=""/> -->
                      </p>

                      <div class="bighenglan mt_60 mb_90" id="lxfs"></div>
                      <!--商务合作end-->
                      <!--联系方式-->
                      <div class="text-center about-tit">
                        <h2>
                          <span class="a_font co_green" href="">联系方式</span>
                          <p>Contact information</p>
                        </h2>
                      </div>

                      <p class="co_green">慢慢心理</p>
                      <p>
                        Phone: 010-67793819<br />Email:
                        contact@manmanxinli.com<br />
                        Address: 北京市朝阳区大郊亭中街2号院3号楼18层3-18C
                      </p>
                      <div class="bighenglan mt_60" id="lxfs"></div>
                      <!--联系方式end-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--文章内容区域end-->
  </div>
</template>

<script>
export default {
  name: "AboutPage",
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.content {
  background-color: #fff;
  border-radius: 6px;
  width: 960px;
  padding: 30px;
  box-sizing: border-box;
  h2{
      text-align: center;
      margin: 40px 0 20px;
  }
}
</style>