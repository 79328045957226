<template>
	<div class="content">
		<div class="qaBox">
			<div class="qaTop u-flex u-row-between">
				<div class="u-flex pointer" @click="goPersonal">
					<el-avatar :size="52" :src="questionsInfo.user_avatar"></el-avatar>
					<div class="qaUser u-m-l-10">
						<div class="">{{questionsInfo.user_name}}</div>
						<div class="" v-if="questionsInfo.create_date">
							{{questionsInfo.create_date.split(' ')[0].split('-').join('.')}}发布问题
						</div>
					</div>
				</div>
				<div class="comfort u-flex pointer" @click="comfort">
					<el-image style="width: 34px;height: 32px;" :src="require('@/assets/image/comfort.png')"></el-image>
					<span class="u-m-l-4">安慰一下</span>
				</div>
			</div>
			<div class="qaContent u-m-t-20 u-flex">
				<div class="u-flex">
					<i class="el-icon-chat-line-round u-m-r-4"></i>
					<div class="u-line-1">{{questionsInfo.title}}</div>
				</div>
				<div class="qaCon">
					{{questionsInfo.content}}
				</div>
				<div style="position: relative;">
					<div class="reward u-flex u-m-t-16" :style="{filter:questionsInfo.accept?'blur(6px)':'blur(0px)'}"
						v-if="questionsInfo.reward_money>0">
						<iconpark-icon name="financing-one"></iconpark-icon>
						<div class="u-m-l-4">回复一经作者采纳将获得{{questionsInfo.reward_money}}元，发放至个人中心可进行提现申请！</div>
					</div>
					<div v-if="questionsInfo.accept"
						style="position: absolute;top: 30px;left: 50%;transform: translate(-50%,-50%);color: #D7A753;">
						已被采纳</div>
				</div>
				<div class="u-flex u-col-top u-m-t-16">
					<div class="u-flex" style="flex-wrap: wrap;flex: 1;">
						<div class="category u-flex u-m-r-6" v-for="(v,i) in questionsInfo.category_names" :key="i">
							#{{ v }}
						</div>
					</div>
					<div class="u-flex u-m-l-8" style="color: #717083;font-size: 12px;line-height: 22px;">
						<div class="">
							<i class="el-icon-view u-m-r-4"></i>
							<span>共{{questionsInfo.read_num}}次查看</span>
						</div>
						<div class="line u-m-l-6 u-m-r-6" style="width: 1px;height: 10px;background-color: #717083;">
						</div>
						<div class="">当前{{questionsInfo.answer_num+questionsInfo.comment_num}}人参与</div>
					</div>
				</div>
			</div>
			<div class="qaAnswer u-flex">
				<div class="qaIcon u-flex u-row-center">
					<i class="el-icon-user"></i>
				</div>
				<div class="u-flex-1" style="width: 100%;">
					<el-input class="Qatext" type="textarea" :rows="1" :autosize="true" placeholder="请输入内容"
						v-model="textarea">
					</el-input>
				</div>
				<div class="anonymous u-m-l-10 u-flex" v-if="false">
					<el-switch v-model="value" active-color="#EC977A" inactive-color="#eeeeee">
					</el-switch>
					<div class="u-m-l-6" :style="{color:value?'#EC977A':'#C0C4CC'}">匿名</div>
				</div>

				<div class="sendBtn" v-if="textarea.length">
					<el-button type="primary" @click="submit">发 表</el-button>
				</div>
			</div>

			<div class="u-m-t-20">
				<div class="answertit">
					全部回复
				</div>
				<div class="answerList">
					<div class="answerOne u-m-t-20" v-for="(item,index) in answerList" :key="index">
						<div class="u-flex pointer" @click="goPersonal(item)">
							<div class="">
								<el-avatar :size="40" :src="item.user_avatar"></el-avatar>
							</div>
							<div class="u-m-l-10 userName">{{item.user_name}}</div>
						</div>
						<div class="answerCon u-m-t-20" style="word-break: break-all;">{{item.content}}</div>

						<div class="comment-reply-box" v-if="item.comment_list">
							<div class="comment-reply " v-for="(v, i) in item.comment_list.slice(0,2)" :key="v.id">
								<span class="user-name">
									{{ v.user_name }}：
								</span>
								<span class="reply-content" style="word-break: break-all;">
									{{ v.content }}
								</span>
							</div>
							<!-- 更多评论 -->
							<div class="comment-reply " v-for="(v, i) in item.comment_list.slice(2)" :key="v.id"
								v-if="item.show">
								<span class="user-name">
									{{ v.user_name }}：
								</span>
								<span class="reply-content" style="word-break: break-all;">
									{{ v.content }}
								</span>
							</div>

							<div class="more-reply pointer" v-if="item.comment_list.length>2 && !item.show"
								@click="item.show = true">
								更多{{ item.comment_list.slice(2).length }}条回复
								<span class="cuIcon-right"></span>
							</div>
						</div>

						<div class="u-flex u-row-between u-m-t-20">
							<div class="answerDate">
								<span>{{ item.create_date.split(' ')[0] }}回答</span>
								<span v-if="item.ip_place">·{{ item.ip_place }}</span>

								<span class="u-m-l-10 pointer" style="color: #5473E8;"
									@click="openReply(item)">回复</span>
							</div>
							<div class="u-flex">
								<div class="answerDate" style="cursor: pointer;" @click="reportFunc(item)">举报</div>
								<div class="u-flex u-m-l-8 islike" :class="[item.is_like?'like':'']"
									@click="thumbs(item)">
									<iconpark-icon style="font-weight: 800;" name="thumbs-up"></iconpark-icon>
									<span class="u-m-l-4">点赞{{item.like_num}}</span>
								</div>
							</div>
						</div>
					
						<div class="rewardPic" v-if="questionsInfo.accept_answer_id==item.id">
							<el-image style="width: 70px;" :src="require('@/assets/image/bigShang.png')"></el-image>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class=""></div>

		<el-dialog title="回复" :visible.sync="dialogReply" width="40%">
			<div>
				<el-input type="textarea" placeholder="请输入回复内容" v-model="replyVal"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogReply = false">取 消</el-button>
				<el-button type="primary" @click="submitCom">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog title="举报" :visible.sync="dialogVisible" width="30%">
			<div class="reportList">
				<el-radio-group v-model="radio">
					<el-radio :label="item" v-for="(item,index) in reportList" :key="index"
						class="u-m-b-10">{{item}}</el-radio>
				</el-radio-group>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitReport">确 定</el-button>
			</span>
		</el-dialog>

		<div class="gifOpen image-container animate__animated"
			:class="{ show: isImageVisible ,animate__fadeOut:animationHide }">
			<el-image class="gifPic" :src="require('@/assets/image/comfort.gif')"></el-image>
		</div>
	</div>
</template>

<script>
	import {
		getQuestions,
		addQuestionsAnswer,
		addCommunityReport,
		addCollectRecord,
		cancelCollectRecord,
		addAnswerComment
	} from '@/api/api.js'
	export default {
		data() {
			return {
				answerList: [],
				questionsInfo: {},
				textarea: '',
				value: false,
				dialogVisible: false,
				reportList: [
					'垃圾广告',
					'有害信息',
					'涉嫌侵权',
					'低俗内容',
					'自杀倾向',
					'其他'
				],
				radio: '',
				key_id: null,
				flag: false,
				showMore: false,

				dialogReply: false,
				replyVal: '',
				activeId: null,

				isImageVisible: false,
				animationHide: false,
				isAnimating: false
			}
		},
		mounted() {
			this.initData()
		},
		watch: {
			"$route"() {
				this.initData();
			}
		},
		methods: {
			goPersonal(item) {
				if (item.user_type == 2) { //是咨询师
					let arr = [{
							name: "心理咨询",
							url: "/ZxsHome"
						},
						{
							name: item.user_name,
							url: ""
						},
					];
					localStorage.setItem("route", JSON.stringify(arr));
					this.$store.commit("newRoute", arr);
					this.$router.push({
						name: "ZxsInfo",
						query: {
							id: item.user_id
						}
					});
					return
				}
				if (this.questionsInfo.user_id == this.$store.state.chatid) { //是自己
					let arr = [{
							name: "首页",
							url: "/AllHome"
						},
						{
							name: "个人中心",
							url: ""
						},
					];
					localStorage.setItem("route", JSON.stringify(arr));
					this.$store.commit("newRoute", arr);
					this.$router.push({
						name: "GoApp",
						query: {
							title: '消息通知'
						}
					});
					return
				}

				let id = 0
				if (this.questionsInfo.anonymity) {
					id = 0
				} else {
					id = this.questionsInfo.user_id
				}
				this.$router.push({
					name: "PersonalPage",
					query: {
						id
					}
				});
			},
			async comfort() {
				if (this.isAnimating) return;
				this.isAnimating = true;
				this.isImageVisible = true;
				await new Promise(resolve => setTimeout(resolve, 5000));
				this.animationHide = true
				await new Promise(resolve => setTimeout(resolve, 1000));
				this.animationHide = false
				this.isImageVisible = false;
				this.isAnimating = false;
			},
			submitCom() {

				if (this.replyVal) {
					addAnswerComment({
						answer_id: this.activeId,
						content: this.replyVal,
						user_type: 1,
					}).then(res => {
						this.dialogReply = false
						this.$message.success('回复成功')
						this.replyVal = ''
						this.initData();
					})
				} else {

				}

			},
			openReply(item) {
				this.dialogReply = true
				this.activeId = item.id
			},
			thumbs(item) {
				console.log(item)
				if (this.flag) return
				this.flag = true;
				if (item.is_like) {
					this.cancelCollectRecord(3, 1, item.id)
				} else {
					this.addCollectRecord(3, 1, item.id);
				}
			},
			addCollectRecord(type, operate_id, key_id) {
				addCollectRecord({
					type,
					operate_id,
					key_id,
					user_type: 1
				}).then(res => {
					this.initData()
				}).finally(() => {
					this.flag = false
				})
			},
			cancelCollectRecord(type, operate_id, key_id) {
				cancelCollectRecord({
					type,
					operate_id,
					key_id,
					user_type: 1
				}).then(res => {
					this.initData()
				}).finally(() => {
					this.flag = false
				})
			},
			submitReport() {
				if (!this.radio.length) {
					this.$message.error('请选择举报原因！')
					return
				}
				addCommunityReport({
					type: 3,
					reason_cause: this.radio,
					key_id: this.key_id,
					user_type: 1,
				}).then(res => {
					this.dialogVisible = false
					this.radio = ''
					this.$message.success('举报成功')
				})
			},
			reportFunc(item) {
				console.log(item)
				this.key_id = item.id
				this.dialogVisible = true
			},
			goLogin() {
				this.$router.push({
					name: "LoginPage",
					query: {
						back: true
					}
				});
			},
			submit() {
				if (!this.$store.state.token) {
					this.goLogin();
				} else {
					this.$confirm('是否确认发表当前回复内容?', '提示', {
						confirmButtonText: '确认回复',
						cancelButtonText: '再考虑一下',
						type: 'warning'
					}).then(() => {
						if (!this.textarea.length) {
							this.$message.error('请输入发表内容！')
							return
						}
						addQuestionsAnswer({
							questions_id: this.$route.query.id,
							content: this.textarea
						}).then(res => {
							this.textarea = ''
							this.initData()
							this.$message({
								type: 'success',
								message: '发表成功!'
							});
						})

					})
				}
			},
			initData() {
				getQuestions({
					questions_id: this.$route.query.id
				}).then(res => {
					console.log(111111, res);
					this.$store.commit("iscollection", res.data.questions_info.is_collect);
					res.data.answer_list.forEach(val => {
						val.show = false
					})
					this.answerList = res.data.answer_list;
					this.questionsInfo = res.data.questions_info;
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.content {
		background-color: #fff;
		border-radius: 6px;
		// min-width: 960px;
		// width: 960px;
		width: calc(100% - 430px);
		min-width: calc(100% - 430px);
		overflow: hidden;
		padding: 30px;

		.qaBox {
			.qaTop {
				.comfort {
					font-size: 16px;
					font-weight: 400;
					color: #C0C4CC;
					line-height: 22px;

					span {
						text-decoration: underline;
					}
				}

				.qaUser {
					:first-child {
						font-size: 16px;
						font-weight: 600;
						color: #4B4B65;
						line-height: 25px;
					}

					:last-child {
						font-size: 12px;
						font-weight: 400;
						color: #C0C4CC;
						line-height: 20px;
					}
				}
			}

			.qaContent {
				background-color: #FAFAFA;
				width: 100%;
				padding: 20px;
				box-sizing: border-box;
				margin-top: 20px;
				border-radius: 3px;
				flex-direction: column;
				align-items: flex-start;

				.qaCon {
					margin-top: 10px;
					font-size: 12px;
					font-weight: 400;
					color: #908F9D;
					line-height: 22px;
				}

				.reward {
					padding: 6px 12px;
					font-size: 12px;
					font-weight: 400;
					color: #EC977A;
					line-height: 17px;
					white-space: nowrap;
					background: rgba(236, 151, 122, 0.03);
					border-radius: 3px 12px 3px 12px;
					border: 1px solid #EC977A;
				}

				.category {
					background-color: #EEEEEE;
					padding: 4px 12px;
					border-radius: 17px;
					font-size: 12px;
					color: #4B4B65;
					line-height: 14px;
					margin-bottom: 16px;
					box-sizing: content-box;
				}
			}

			.qaAnswer {
				margin-top: 20px;
				width: 100%;
				padding: 20px;
				border-radius: 6px;
				border: 1px solid #DCDFE6;
				position: relative;

				.sendBtn {
					position: absolute;
					bottom: -60px;
					right: 0;
				}

				.qaIcon {
					border: 1px solid #C0C4CC;
					color: #C0C4CC;
					border-radius: 50%;
					overflow: hidden;
					width: 22px;
					height: 22px;
					flex: 0 0 22px;
				}

				.Qatext ::v-deep .el-textarea__inner {
					border: none;
					width: 100%;
				}

				.anonymous {
					font-size: 16px;
					font-weight: 400;
					color: #C0C4CC;
					line-height: 22px;
				}
			}


			.answertit {
				display: inline-block;
				font-size: 18px;
				font-weight: 600;
				color: #EC977A;
				line-height: 25px;
				position: relative;

				&::after {
					content: '';
					position: absolute;
					bottom: -10px;
					left: 50%;
					transform: translateX(-50%);
					width: 12px;
					height: 3px;
					background: #EC977A;
					border-radius: 3px;
				}
			}

			.answerList {
				.answerOne {
					padding: 20px;
					border-radius: 8px;
					border: 1px solid #EAEEF3;
					position: relative;
					.rewardPic{
						position: absolute;
						top: 20px;
						right: 20px;
					}
					.userName {
						font-size: 16px;
						font-weight: 600;
						color: #4B4B65;
						line-height: 22px;
					}

					.answerCon {
						font-size: 14px;
						font-weight: 400;
						color: #8888a1;
						line-height: 25px;
					}

					.comment-reply-box {
						background: #F8F8F8;
						border-radius: 12px;
						margin-top: 20px;
						padding: 0 20px 10px;

						.comment-reply {
							padding-top: 10px;
							font-size: 14px;
							line-height: 20px;

							.user-name {
								font-weight: 700;
								color: #252631;
							}

							.reply-content {
								color: #5A5B60;
							}
						}

						.more-reply {
							margin-top: 10px;
							font-size: 14px;
							font-weight: 400;
							color: #5473E8;
							line-height: 20px;
						}
					}


					.answerDate {
						font-size: 12px;
						font-weight: 400;
						color: #C0C4CC;
						line-height: 20px;
					}

					.islike {
						font-size: 12px;
						font-weight: 400;
						color: #C0C4CC;
						line-height: 20px;
						cursor: pointer;
					}

					.like {
						color: #EC977A;
					}
				}
			}
		}
	}


	.gifOpen {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 50vw;
		z-index: 5;
	}

	.gifPic {
		width: 100%;
		max-width: 480px;
		max-height: 382px;
		// opacity: 0;
	}

	.image-container {
		opacity: 0;
		height: 0;
		overflow: hidden;
		transition: opacity 1s ease-in-out, height 0.5s ease-in-out;
	}

	.image-container.show {
		opacity: 1;
		height: auto;
	}

	.image-container.hide {
		display: none;
	}

	@keyframes hideAni {
		0% {
			opacity: 1;
		}

		100% {
			opacity: 0;
		}
	}
</style>