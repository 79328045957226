// 封装页面请求
import request from './index.js'

/*登录及用户模块*/
//手机号码验证码
export const SendPhoneCode = (data) => request({ url: 'api/sms/send', method: "post", data, })
// 邮箱验证码
export const SendEmailCode = (data) => request({ url: 'api/ems/send', method: "post", data, })
//手机号登录
export const LoginByPhone = (data) => request({ url: 'api/user/mobilelogin', method: "post", data, })
// 邮箱登录
export const LoginByEmail = (data) => request({ url: 'api/user/emaillogin', method: "post", data, })
//获取用户信息
export const GetUserinfo = (data) => request({ url: 'api/user_mine/myinfo', method: "post", data, })
//检测token状况
export const GetTokenState = (data) => request({ url: 'api/token/check', method: "get", data, })
//获取用户余额
export const GetUserWallet = (data) => request({ url: 'api/user_order/wallet', method: "post", data, })
//获取用户余额明细
export const GetUserWalletDetail = (data) => request({ url: 'api/user_mine/walletDetails', method: "post", data, })
//获取充值列表
export const GetDenomination = (data) => request({ url: 'api/user_pay/denomination', method: "post", data, })
//用户钱包充值
export const UserRecharge = (data) => request({ url: 'api/user_pay/wallet_pay', method: "post", data, })
//获取支付状态
export const GetPayState = (data) => request({ url: 'api/user_pay/wechatScanResult', method: "post", data, })
/*登录及用户模块*/


/*测试模块*/
// 获取测试热门列表
export const GetCestHandpick = (data) => request({ url: 'api/mental/testHandpick', method: "post", data, })
// 获取测试热门列表
export const GetMental = (data) => request({ url: 'api/mental/mentalSlideshow', method: "post", data, })
// 获取测试分类列表
export const GetCategoryList = (data) => request({ url: 'api/mental/categoryList', method: "post", data, })
// 获取测试列表
export const GetTestList = (data) => request({ url: 'api/mental/testList', method: "post", data, })
// 获取测试列表
export const GetTestDetails = (data) => request({ url: 'api/mental/testDetails', method: "post", data, })
// 获取关注的测试列表
export const GetUserCollection = (data) => request({ url: 'api/mental/userCollection', method: "post", data, })
// 余额购买题库
export const BuyTestWallet = (data) => request({ url: 'api/mental/testPay', method: "post", data, })
// 进入题库判断
export const TestEnter = (data) => request({ url: 'api/mental/testEnter', method: "post", data, })
// 获取测试题目
export const GetTestsubject = (data) => request({ url: 'api/mental/testingInit', method: "post", data, })
// 答题
export const AnswerQuestions = (data) => request({ url: 'api/mental/testing', method: "post", data, })
// 测试结果
export const GetTestResult = (data) => request({ url: 'api/mental/testResult', method: "post", data, })
// 发表评论
export const SubmitEva = (data) => request({ url: 'api/mental/evaluation', method: "post", data, })
// 收藏测试
export const CollectionTest = (data) => request({ url: 'api/mental/testCollection', method: "post", data, })
/*测试模块*/


/*系统信息模块*/
//获取轮播图信息
export const GetSlideshow = (data) => request({ url: 'api/user/Slideshow', method: "post", data, })
//获取协议信息
export const GetAgreementInfo = (data) => request({ url: 'api/common/agreementInfo', method: "post", data, })
//获取帮助中心
export const GetQuestionList = (data) => request({ url: 'api/user_mine/questionList', method: "post", data, })
/*系统信息模块*/



/*咨询师模块*/
export const GetUserIndex = (data) => request({ url: 'api/user/index', method: "post", data, })
//咨询师详情
export const consultantDetails = (data) => request({ url: 'api/user_consult/consultantDetails', method: "post", data, })
//查看咨询师评论
export const consultantEvaluation = (data) => request({ url: 'api/user_consult/consultantEvaluation', method: "post", data, })
//关注咨询师
export const follow = (data) => request({ url: 'api/user_consult/follow', method: "post", data, })
//咨询师立即咨询套餐
export const immed = (data) => request({ url: 'api/user_consult/immed', method: "post", data, })
//咨询师问答列表
export const queryQuestionsListById = (params) => request({ url: 'api/community.questions_user/queryQuestionsListById', method: "get", params, })
//我的关注
export const myFollow = (data) => request({ url: 'api/user_mine/myFollow', method: "post", data, })
//咨询师筛选
export const consultantIndexList = (params) => request({ url: 'api/user_consult/consultantIndexList', method: "get", params, })
//咨询师举报原因
export const inform_type = (data) => request({ url: 'api/inform/inform_type', method: "post", data, })
//提交咨询师举报
export const informSubmit = (data) => request({ url: 'api/inform/informSubmit', method: "post", data, })
//首页咨询师推荐
export const queryHomeRecommendConsultantList = (params) => request({ url: 'api/user.user_web/queryHomeRecommendConsultantList', method: "get", params, })
/*咨询师模块*/


/*社区文章模块*/
//全部文章列表
export const queryArticleList = (params) => request({ url: 'api/community.article_user/queryArticleList', method: "get", params, })
//咨询师文章
export const queryArticleListById = (params) => request({ url: 'api/community.article_user/queryArticleListById', method: "get", params, })
//文章分类获取
export const queryArticleCategoryList = (params) => request({ url: 'api/community.article/queryArticleCategoryList', method: "get", params, })
//文章详情
export const getArticle = (params) => request({ url: 'api/community.article_user/getArticle', method: "get", params, })
//文章关联篇
export const getArticlePreAndNext = (params) => request({ url: 'api/user.user_web/getArticlePreAndNext', method: "get", params, })
//文章回复
export const queryArticleCommentList = (params) => request({ url: 'api/community.article/queryArticleCommentList', method: "get", params, })
// 发表回复
export const addArticleComment = (data) => request({ url: 'api/community.article/addArticleComment', method: "post", data, })
//用户关注文章列表
export const queryFollowArticleList = (params) => request({ url: 'api/community.article_user/queryFollowArticleList', method: "get", params, })

/*社区文章模块*/


/*社区问答模块*/
//获取问题分类
export const queryQuestionsCategoryList = (params) => request({ url: 'api/community.questions/queryQuestionsCategoryList', method: "get", params, })
//获取问答推荐
export const queryQuestionsWebList = (params) => request({ url: 'api/user.user_web/queryQuestionsWebList', method: "get", params, })
//用户问答列表
export const queryQuestionsList = (params) => request({ url: 'api/community.questions_user/queryQuestionsList', method: "get", params, })
//获取问答详情 
export const getQuestions = (params) => request({ url: 'api/community.questions_user/getQuestions', method: "get", params, })
//取消点赞收藏
export const cancelCollectRecord = (data) => request({ url: 'api/community.community/cancelCollectRecord', method: "post", data, })
//点赞收藏
export const addCollectRecord = (data) => request({ url: 'api/community.community/addCollectRecord', method: "post", data, })
//回复问答
export const addQuestionsAnswer = (data) => request({ url: 'api/community.questions_user/addQuestionsAnswer', method: "post", data, })
// 回复问答回答
export const addAnswerComment = (data) => request({ url: 'api/community.questions/addAnswerComment', method: "post", data, })
//社区举报
export const addCommunityReport = (data) => request({ url: 'api/community.community/addCommunityReport', method: "post", data, })
//社区用户信息
export const getCommunityWebUserHomePage = (params) => request({ url: 'api/user.user_web/getCommunityWebUserHomePage', method: "get", params, })
//用户关注列表
export const queryFollowQuestionsList = (params) => request({ url: 'api/community.questions_user/queryFollowQuestionsList', method: "get", params, })

/*社区问答模块*/

/*优惠券模块*/
//领取优惠券
export const receiveCouponPlatform = (data) => request({ url: 'api/coupon.platform/receiveCouponPlatform', method: "post", data, })
/*优惠券模块*/

/*聊天模块*/
//获取聊天列表
export const chatListNew = (data) => request({ url: 'api/user_message/chatListNew', method: "post", data, })
//聊天双方信息
export const queryChatInfo = (data) => request({ url: 'api/chatmessage.chat_message/queryChatInfo', method: "post", data, })
//获取聊天记录
export const queryChatMessageLog = (data) => request({ url: 'api/chatmessage.chat_message/queryChatMessageLog', method: "post", data, })
//敏感词验证
export const sensitive = (data) => request({ url: 'api/chatmessage.chat_message/sensitive', method: "post", data, })
//消息持久化
export const messageSave = (data) => request({ url: 'api/chatmessage.chat_message/messageSave', method: "post", data, })
//用户拉黑
export const shieldConsultant = (data) => request({ url: 'api/user_message/shieldConsultant', method: "post", data, })
//用户取消拉黑
export const shieldCancel = (data) => request({ url: 'api/user_message/shieldCancel', method: "post", data, })
//查询订单剩余时间
export const orderRemindTime = (data) => request({ url: 'api/chatmessage.chat_message/orderRemindTime', method: "post", data, })
//结束图文咨询
export const overImageText = (data) => request({ url: 'api/chatmessage.chat_message/overImageText', method: "post", data, })
//分配客服
export const presaleChatInfoNew = (data) => request({ url: 'api/customer_service.presale_chat/presaleChatInfoNew', method: "post", data, })
//上传文件
export const upload = (data) => request({ url: 'api/common/upload', method: "post", data, })



/*聊天模块*/