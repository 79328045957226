<template>
	<div class="content">
		<div class="topBox">
			<el-carousel class="bannerBox maxWidth" style="border-radius: 6px" trigger="click" :height="bannerHeight+'px'"
				:interval="50000">
				<el-carousel-item v-for="(item, index) in bannerList" :key="index">
					<!-- <el-image @click="bannerFunc(item)" style="height: 100%;width: 100%;" :src="item.image" fit="cover">
					</el-image> -->
					<!-- <div class="bannerPic" :style="{backgroundImage:'url('+ item.image +')'}"></div> -->
					<img class="pointer" ref="bannerHeight" style="width: 100%;;object-fit: cover;" :src="item.image"
						alt="[图片]" @click="bannerFunc(item)" @load="loadImg">
				</el-carousel-item>
			</el-carousel>

			<div class="suggest maxWidth">
				<!-- 推荐 -->
				<div class="suggestOne pointer" :class="['bg'+(index+1)]" v-for="(item,index) in suggestList"
					:key="index" @click="goZxsInfo(item)">
					<!-- 左上角标签 -->
					<div class="label u-flex">
						<el-image style="width: 14px;height: 10px;margin-right: 6px;vertical-align: middle;"
							:src="require('@/assets/logo.png')"></el-image>
						<span style="vertical-align: middle;">慢慢心理</span>
					</div>
					<div class="sugInfoBox">
						<div class="sugInfo1 u-line-1">心理导师 {{item.name}}</div>
						<div class="sugInfo2">{{item.qualification}}</div>
						<div class="sugInfo3 u-line-1" style="width: 50%;">擅长：{{item.good_field_str}}</div>
						<div class="sugInfo4">累积帮助：{{item.inquiries}}人</div>
					</div>
					<div class="suggestPic">
						<el-image style="width: 50%;max-height: 100%;" fit="cover" :src="item.image_full"></el-image>
					</div>
				</div>
			</div>

			<div class="homeNav maxWidth">
				<div class="navList">
					<div class="navOne" :class="[active==index?'navActive':'']" v-for="(item,index) in navList"
						:key="index" @click="changeNav(item,index)">{{item.title}}</div>
				</div>
				<!-- <div class="navPic">
					<el-image style="width: 180px;" :src="require('@/assets/image/concatUs.png')"></el-image>
				</div> -->
			</div>
		</div>

		<div class="testBox">
			<div class="titleBox maxWidth" style="margin: 0 auto;">
				<span class="">精选测试</span>
				<span class="">权威测评，为您推荐</span>
			</div>
			<div class="testList maxWidth" style="margin: 40px auto 0;">
				<div class="testOne pointer" v-for="(item,index) in testList" :key="index" :style="{order: index+1}"
					@click="goInfo(item.id, item.title)">
					<div class="pic">
						<el-image fit="cover" style=";height: 150px;" :src="item.test_cover_image">
						</el-image>
					</div>
					<div class="testinfo">
						<div class="infotop display">
							<div class="testName overflow">
								{{ item.title }}
							</div>
							<div v-if="item.buynum!=2" class="testPrice" style="white-space: nowrap;"
								:class="[!item.price ? 'testFree' : '']">
								{{ item.price ? "¥" + item.price : "免费" }}
							</div>
							<div v-else class="testPrice" :class="[!item.price ? 'testFree' : '']">
								已购买
							</div>
						</div>
						<div class="infomiddle">{{ item.category_name }}测试</div>
						<div class="infobottom display">
							<div>
								<i class="el-icon-star-off"></i>
								{{ item.collection_num || "0" }}
							</div>
							<div style="margin-left: 10px">
								<i class="el-icon-chat-dot-round"></i>
								{{ item.evaluation_num || "0" }}
							</div>
							<div class="time" style="flex: 1; text-align: right;" v-if="item.test_createtime">
								{{ item.test_createtime | dateformat1 }}
							</div>
							<div class="time" style="flex: 1; text-align: right;" v-else>
								<!-- {{ formatTime(item.createtime * 1000,'{y}.{m}.{d}')}} -->
								{{ (item.createtime * 1000) | dateformat }}
							</div>
						</div>
					</div>
				</div>

				<div class="testOne testTwo" style="">
					<div class="testPoint">
						<i class="el-icon-document-checked"></i>
						<span>1600+</span>
						<span>篇测试</span>
					</div>
					<div class="testPoint1">
						专业权威帮您分析
					</div>
					<div class="testPoint2">
						<i class="el-icon-caret-right"></i>
						<i class="el-icon-caret-right"></i>
						<i class="el-icon-caret-right"></i>
					</div>
					<div class="testPoint3">
						已帮助1538人完成测评
					</div>
					<div class="testPoint4" @click="goTestHome('TestHome')">
						<span>全部</span>
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
			</div>

		</div>
		<div class="articleBox">
			<div class="articleList maxWidth">
				<div class="titleBox">
					<span class="">聚焦好文</span>
					<span class="">人人都能学，人人都会用的心理学</span>
				</div>
				<el-row :gutter="30">
					<el-col :span="16" style="position: relative;">
						<div class="detailBox" v-for="(item,index) in newArr" :key="index"
							:class="[index?'detailBox2':'']"
							:style="{opacity:articleActive==index?'1':'0',zIndex:articleActive==index?'2':'1'}">
							<div class="pointer" style="margin-right: 20px;flex: 1;width: 0;" v-for="(ite,idx) in item"
								:key="idx" @click="goArticle(ite)">
								<el-image fit="cover" style="width: 100%;height: 180px;border-radius: 6px;"
									:src="ite.image">
								</el-image>
								<div class="detailInfo">
									<div class="info1 u-line-1">
										<span>{{ite.title}}</span>
									</div>
									<div class="info2 u-line-2">
										{{ite.describe}}
									</div>
									<div class="info3 u-m-t-6 u-flex u-col-top">
										<div class="">
											<el-image style="width: 32px;height: 32px;"
												v-if="objClass[ite.category_names[0]]"
												:src="objClass[ite.category_names[0]]"></el-image>
										</div>
										<div class="right u-flex u-m-t-2">
											<div class="">
												#{{ite.category_names[0]}}
											</div>
											<div class="u-m-l-6 u-m-r-6"
												style="width: 1px;height: 14px;background-color: #C0C4CC;">

											</div>
											<div class="" style="color: #C0C4CC;">
												{{ite.create_date.split(' ')[0]}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="indicator u-flex u-row-right u-m-t-20">
							<div class="indicatorOne u-m-r-10" @click="articleActive = index"
								:class="[articleActive==index?'indicatorActive':'']"
								v-for="(item,index) in Math.ceil(articleList.length/2)" :key="index"></div>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="chooseArticle">
							<div class="chooseOne" v-for="(item,index) in articleList" :key="index"
								:class="[chooseArticle!=index?'chooseActive':'']" @mouseover="chooseArticle=index">
								<div class="detailInfo">
									<div class="info1 u-line-1">{{item.title}}</div>
									<div class="info2 u-line-2" :style="{height:chooseArticle==index?'32px':0}">
										{{item.describe}}
									</div>
									<div class="info3 u-m-t-6 u-flex u-col-top">
										<div class="">
											<el-image style="width: 32px;height: 32px;"
												v-if="objClass[item.category_names[0]]"
												:src="objClass[item.category_names[0]]"></el-image>
										</div>
										<div class="right u-flex u-m-t-2">
											<div class="">
												#{{item.category_names[0]}}
											</div>
											<div class="u-m-l-6 u-m-r-6"
												style="width: 1px;height: 14px;background-color: #C0C4CC;">
											</div>
											<div class="" style="color: #C0C4CC;">
												{{item.create_date.split(' ')[0]}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>

		<div class="zxsBox">
			<div class="zxsList maxWidth">
				<div class="titleBox">
					<span class="">心理咨询</span>
					<span class="">每人心中都有疑惑，为您解答世间烦恼</span>
				</div>


				<div style="display: grid;grid-template-columns: repeat(5,1fr);grid-gap: 15px;">
					<div v-for="(item, index) in zxsList" :key="index" style="min-width: 0;">
						<div class="testOne pointer" @mouseover="showId = item.id" @mouseleave="showId = null"
							@click="goZxsInfo(item)">
							<div class="subTit u-line-1">
								<iconpark-icon style="color: #FF7695;" v-if="item.gender==0" name="female">
								</iconpark-icon>
								<iconpark-icon style="color: #6495ED;" v-else name="male"></iconpark-icon>
								<span style="margin-left: 3px;">{{item.qualification}}</span>
							</div>
							<div style="width: 100%; height: 170px;overflow: hidden;">
								<el-image style="height: 170px;width: 100%;" :src="item.avatar" fit="cover"
									class="imgBig" alt='图片'></el-image>
							</div>

							<div class="zxsInfo u-flex-1">
								<div class="infoTop">
									<span class="u-line-1">咨询师<span
											style="margin-left: 10px;">{{item.nickname}}</span></span>
									<!-- <div style="display: flex;align-items: center;">
										<div class="point"></div>
										<span class="infoSmall u-line-1">从业{{item.employed_age}}年</span>
									</div> -->
								</div>
								<div class="infoBot u-line-1" v-if="item.good_field">
									<span>擅长：</span>
									<div class="u-line-1">
										<span class="infoBotSub" v-for="(item,index) in item.good_field" v-if="index<3"
											:key="index">
											{{item}}
										</span>
									</div>
								</div>

								<div style="overflow: hidden;transition: height 0.3s;"
									:class="[showId == item.id?'heightAni':'height0']">
									<!-- :style="{height:showId == item.id?'auto':0}" -->
									<div class="helpNum">
										<i style="color: #EC977A;" class="el-icon-s-opportunity u-m-r-4"></i>
										<span>已帮助{{item.inquiries}}人</span>
									</div>
									<div class="u-flex u-row-between" style="text-align: center;font-size: 12px;">
										<div class="" style="color: #F04352;font-weight: 800;">
											￥{{parseInt(item.confide_price)}}起</div>
										<div class="zxBtn">
											立即咨询
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="testOne testTwo" style="background-color: #ffffff;">
						<div class="testPoint">
							<i class="el-icon-document-checked"></i>
							<span style="color: #EC977A;">300+</span>
							<span>咨询师</span>
						</div>
						<div class="testPoint1">
							行业权威帮助心理
						</div>
						<div class="testPoint2" style="color: #EC977A;">
							<i class="el-icon-caret-right"></i>
							<i class="el-icon-caret-right"></i>
							<i class="el-icon-caret-right"></i>
						</div>
						<div class="testPoint3">
							已帮助8936人咨询
						</div>
						<div class="testPoint4" @click="goTestHome('ZxsHome')" style="color: #EC977A;">
							<span>全部</span>
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="qaBox">
			<div class="qaList maxWidth">
				<div class="u-flex u-col-bottom">
					<div class="titleBox u-m-b-20">
						<span class="">求助问答</span>
						<span class="">了解自己，认识他人</span>
					</div>
					<div class="titleBox u-m-b-20">
						<span class="" style="opacity: 0;">1</span>
						<span class="qaSelect" style="margin-left: 10px;">
							<span class="selectNav" :class="[qaActive=='new1'?'activeNav':'']"
								@click="qaActive = 'new1'">最新</span>
							<span style="margin: 0 8px;color: #eeeeee;">/</span>
							<span class="selectNav" :class="[qaActive=='hot1'?'activeNav':'']"
								@click="qaActive = 'hot1'">最热</span>
							<span style="margin: 0 8px;color: #eeeeee;">/</span>
							<span class="selectNav" :class="[qaActive=='all1'?'activeNav':'']"
								@click="qaActive = 'all1'">全部</span>
						</span>
					</div>
				</div>

				<div class="" style="position: relative;">
					<div class="qaAll qaType u-flex u-row-between" v-for="(item,index) in qaObj[qaActive]" :key="index">
						<div class="qaOne" :class="[hoverQa==idx?'qaHover':'',articleActive == index?'qaShow':'qaHide']"
							v-for="(ite,idx) in item" :key="ite.id" @mouseover="hoverQa = idx" @click="goQa(ite,idx)">
							<div class="qaTop u-line-1 u-flex u-row-between">
								<div class="u-line-1">
									<i class="el-icon-chat-line-round u-m-r-4"></i>
									<span>{{ite.title}}</span>
								</div>
								<div class="u-flex u-m-l-20">
									<el-image class="gray" style="width: 32px;height: 32px;"
										v-if="objClass1[ite.category_names[0]]"
										:src="objClass1[ite.category_names[0]]"></el-image>
									<span class="u-m-l-4">#{{ite.category_names[0]}}</span>
								</div>
							</div>
							<div class="qaMid u-line-2 u-m-t-6 u-m-b-6">
								{{ite.content}}
							</div>
							<div class="qaBot u-flex u-row-between">
								<el-avatar :size="40" :src="ite.answer_info.user_avatar"></el-avatar>
								<!-- <el-image style="width: 40px;height: 40px;" :src=></el-image> -->
								<div class="u-flex-1 u-m-l-12">
									<div class="">
										<el-image class="gray u-m-r-4" style="width: 14px;height: 14px;"
											v-if="ite.answer_info.user_type==3"
											:src="require('@/assets/image/official.png')"></el-image>
										<i v-if="ite.answer_info.user_type==2&&!ite.answer_info.gender"
											class="u-m-r-4 el-icon-female" style="color: #FF7695;font-weight: 800;"></i>
										<i v-if="ite.answer_info.user_type==2&&ite.answer_info.gender"
											class="u-m-r-4 el-icon-male" style="color: #5473E8;font-weight: 800;"></i>
										<span>{{ite.answer_info.user_name}}</span>
									</div>
									<div class="">
										<!-- user_type 1使用户 2是咨询师 3是官方回复-->
										<span v-if="ite.answer_info.user_type == 1" style="opacity: 0;">用户</span>
										<span
											v-if="ite.answer_info.user_type == 2">{{ite.answer_info.qualification}}</span>
										<span v-if="ite.answer_info.user_type == 3">慢慢心理官方</span>
									</div>
								</div>
								<div class="">
									{{ite.answer_info.create_date.split(' ')[0]}}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="indicator u-flex u-row-center u-m-t-20">
					<div class="indicatorOne u-m-r-10" @click="articleActive = index"
						:class="[articleActive==index?'indicatorActive':'']"
						v-for="(item,index) in Math.ceil(articleList.length/2)" :key="index"></div>
				</div>
			</div>
		</div>


		<!-- <div class="active u-flex u-row-center" style="width: 100%;">
			<el-image style="max-width: 1400px;max-height: 130px;width: 80%;margin-top: 40px;"
				:src="require('@/assets/image/active.png')"></el-image>
		</div> -->
	</div>
</template>

<script>
	import {
		GetSlideshow,
		consultantIndexList,
		GetTestList,
		queryArticleList,
		queryArticleCategoryList,
		queryQuestionsCategoryList,
		queryQuestionsWebList,
		queryHomeRecommendConsultantList
	} from "@/api/api.js";
	export default {
		data() {
			return {
				bannerList: [],
				suggestList: [],
				navList: [{
						title: '首页推荐'
					}, {
						title: '立即咨询'
					},
					// {
					// 	title: '精选活动'
					// }, {
					// 	title: '我的关注'
					// },
				],
				active: 0, //nav激活项
				articleList: [], //文章列表
				testList: [],
				newArr: [], //转换后的2维数组
				articleActive: 0,
				chooseArticle: 0,
				zxsList: [],
				showId: null,
				objClass: {}, //文章分类图标
				objClass1: {}, //问答分类图标
				qaActive: 'new1',
				hoverQa: 0, //选中的问答
				qaObj: {}, //问答列表
				
				bannerHeight:200
			}
		},
		mounted() {
			this.initList()
		},
		watch: {
			chooseArticle(val) {
				this.articleActive = Math.floor(val / 2)
			},
			articleActive(val) {
				this.chooseArticle = val * 2
			}
		},
		destroyed(){
			window.removeEventListener('resize',this.loadImg)
		},
		methods: {
			loadImg(){
				this.bannerHeight = this.$refs.bannerHeight[0].offsetHeight
			},
			changeNav(item, index) {
				switch (index) {
					case 0:
						this.active = index
						break;
					case 1:
						this.$router.push({
							name: 'ZxsHome'
						})
						break;
					case 2:
						break;
				}
			},
			goZxsInfo(item) {
				let arr = [{
						name: "心理咨询",
						url: "/ZxsHome"
					},
					{
						name: item.name,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "ZxsInfo",
					query: {
						id: item.consultant_id || item.id
					}
				});
			},
			goQa(item, idx) {
				let arr = [{
						name: "求助问答",
						url: "/QaHome"
					},
					{
						name: item.title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "QaInfo",
					query: {
						id: item.id
					}
				});
			},
			goArticle(item) {
				console.log(item)
				let arr = [{
						name: "文章阅读",
						url: "/ArticleHome"
					},
					{
						name: item.title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "ArticleInfo",
					query: {
						id: item.id,
						category_id: item.category_ids[0]
					}
				});
			},
			initList() {
				this.getSlide()
				this.getZxsList()
				this.getTestList()
				this.getClass()
				this.getArticleList()
				this.getQaList()
				this.getRecommend()
			},
			getRecommend() {
				queryHomeRecommendConsultantList().then(res => {
					if (res.data.list.length > 4) {
						res.data.list.length = 4
					}
					this.suggestList = res.data.list
				})
			},
			getQaList() {
				queryQuestionsWebList().then(res => {
					let obj = res.data
					obj.all1 = this.NewArr(obj.all, 6)
					obj.hot1 = this.NewArr(obj.hot, 6)
					obj.new1 = this.NewArr(obj.new, 6)
					this.qaObj = obj
				})
			},
			goInfo(id, title) {
				let arr = [{
						name: "心理测试",
						url: "/TestHome"
					},
					{
						name: title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "TestInfo",
					query: {
						id
					}
				});
			},
			getClass() {
				queryArticleCategoryList({
					page: 1,
					perpage: 100
				}).then(res => {
					let obj = {}
					res.data.list.forEach(val => {
						obj[val.name] = val.image
					})
					this.objClass = obj
				})
				queryQuestionsCategoryList({
					user_type: 1
				}).then(res => {
					let obj = {}
					res.data.list.forEach(val => {
						obj[val.name] = val.image
					})
					this.objClass1 = obj
				})
			},
			getArticleList() {
				queryArticleList({
					user_type: 1,
					page: 1,
					// recommend: 1,//推荐
					// choiceness:1,//精选
					istop: 1, //置顶
					perpage: 4,
				}).then(res => {
					this.articleList = res.data.list
					this.newArr = this.NewArr(res.data.list, 2)
				})
			},
			getTestList() {
				let data = {
					ids: 0,
					page: 1,
					limit: 9,
					select: 1,
				};
				GetTestList(data).then((res) => {
					this.isFollow = false
					this.testList = res.data.list;
				});
			},
			//获取测试列表
			getZxsList() {
				consultantIndexList({
					page: 1,
					limit: 9
				}).then(res => {
					if (res.data.consultant.length > 9) {
						res.data.consultant.length = 9
					}
					res.data.consultant.forEach(item => {
						item['show'] = false
					})
					this.zxsList = res.data.consultant;
				})
			},
			NewArr(arr, n = 2) {
				const len = arr.length //len为数组的长度
				const Num = len % n === 0 ? len / n : Math.floor((len / n) + 1) //得出多少份
				const res = [] //定义数组接受最终的数据
				for (let i = 0; i < Num; i++) {
					// slice() 方法返回一个从开始到结束（不包括结束）的数组。且原始数组不会被修改。
					const newArr = arr.slice(i * n, i * n + n) //得到每份的数据
					res.push(newArr) //往res数组里加数据
				}
				return res
			},
			goTestHome(name) {
				this.$router.push({
					name
				});
			},
			getSlide() {
				GetSlideshow({
					type: 3,
					place: 1
				}).then(res => {
					this.bannerList = res.data.list;
					//监听resize事件，重新获取轮播图高度
					window.addEventListener('resize',this.loadImg)
				})
			}
		},
		filters: {
			dateformat(time) {
				const date = new Date(time);
				const year = date.getFullYear();
				const month = (date.getMonth() + 1 + "").padStart(2, "0");
				const day = (date.getDay() + "").padStart(2, "0");
				return `${year}.${month}.${day}`;
			},
			dateformat1(time) {
				let arr = time.split(" ")[0].split("-");
				return arr[0] + "." + arr[1] + "." + arr[2];
			},
		}
	}
</script>

<style lang="scss" scoped>
	.maxWidth {
		max-width: 1300px;
	}

	.content {
		width: 100%;

		.topBox {
			padding-top: 30px;
			margin-top: -30px;
			background-color: #fff;

			.bannerBox {
				margin: 0 auto;
				width: 90%;
				// .bannerPic{
				// 	width: 100%;
				// 	height: 100%;
				// 	background-repeat: no-repeat;
				// 	background-size: contain;
				// 	// background-size: 100% 100%;
				// }
			}

			.suggest {
				margin: 0 auto;
				margin-top: 30px;
				width: 90%;
				display: flex;
				align-items: center;
				justify-content: space-between;

				> :not(:last-child) {
					margin-right: 16px;
				}

				.suggestOne {
					border-radius: 4px;
					width: 25%;
					// height: 260px;
					padding: 40px 20px 60px;
					position: relative;
					overflow: hidden;

					.suggestPic {
						position: absolute;
						bottom: 0;
						right: 0;
						// width: 40%;
						font-size: 0;
						height: 100%;
						display: flex;
						justify-content: flex-end;
						align-items: flex-end;

						img {
							height: 100%;
							width: 100%;
						}
					}

					.sugInfoBox {
						position: relative;
						z-index: 3;
						font-size: 12px;
						font-weight: 300;
						color: #51516A;
						line-height: 18px;

						.sugInfo1 {
							font-size: 20px;
							font-weight: 800;
							color: #4B4B65;
							line-height: 36px;
						}

						.sugInfo2 {
							font-size: 14px;
							font-weight: 500;
							color: #51516A;
							line-height: 21px;
							margin-top: 10px;
						}

						.sugInfo3 {
							margin-top: 20px;
						}

						.sugInfo4 {
							margin-top: 10px;
						}
					}
				}

				.label {
					position: absolute;
					top: 0;
					left: 0;
					background: #EC977A;
					padding: 6px 10px;
					font-size: 12px;
					font-weight: 600;
					color: #FFFFFF;
					line-height: 17px;
					border-radius: 0 0 10px 0;
					z-index: 2;
				}

				.bg1 {
					background: linear-gradient(180deg, #DDDAEB 0%, #DDDAEB 100%);
				}

				.bg2 {
					background: linear-gradient(180deg, #E6F0E3 0%, #F4F0E8 100%);
				}

				.bg3 {
					background: linear-gradient(360deg, #FDECEC 0%, #FFF1F1 100%);
				}

				.bg4 {
					background: linear-gradient(180deg, #FFF9DA 0%, #FFF1DE 100%);
				}
			}

			.homeNav {
				margin: 0 auto;
				width: 90%;
				position: relative;
				margin-top: 34px;

				.navList {
					display: flex;
					align-items: center;
					justify-content: center;
					padding-bottom: 10px;

					.navOne {
						margin: 0 30px;
						font-size: 14px;
						font-weight: 400;
						color: #4B4B65;
						line-height: 24px;
						letter-spacing: 1px;
						cursor: pointer;
						position: relative;
						transition: all 0.1s;
					}

					.navActive {
						font-size: 16px;
						font-weight: 800;
						color: #4B4B65;
						letter-spacing: 1px;
						transition: all 0.1s;

						&::after {
							position: absolute;
							content: '';
							width: 8px;
							height: 8px;
							border-radius: 50%;
							border: 6px solid #ffffff;
							background-color: #000;
							bottom: -18px;
							left: 50%;
							transform: translateX(-50%);
							box-shadow: 0px 7px 5px 0px rgba(230, 230, 230, 0.5);
						}
					}
				}

				.navPic {
					position: absolute;
					right: 0;
					bottom: 0;
					font-size: 0;
				}
			}
		}


		.titleBox {
			margin-bottom: 40px;

			> :first-child {
				font-size: 28px;
				font-weight: 800;
				color: #4B4B65;
				line-height: 48px;
			}

			> :last-child {
				margin-left: 20px;
				font-size: 12px;
				font-weight: 400;
				color: #717083;
				line-height: 24px;
			}
		}

		.testBox {
			width: 90%;
			margin: 0 auto;
			margin-top: 40px;

			.testList {
				display: flex;
				align-items: stretch;
				justify-content: space-between;
				flex-wrap: wrap;
				// margin-top: 40px;



				.testOne {
					width: 19%;
					background-color: #fff;
					margin-bottom: 18px;
					border-radius: 6px;
					overflow: hidden;
					// cursor: pointer;

					.pic {
						overflow: hidden;
					}

					.testinfo {
						padding: 20px;

						.infotop {
							font-size: 16px;
							line-height: 22px;
							color: #4b4b65;
							justify-content: space-between;
							font-weight: 600;

							.testName {
								width: 190px;
							}

							.testPrice {
								font-size: 16px;
								font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
								font-weight: 800;
								color: #f04352;
								line-height: 24px;
							}

							.testFree {
								color: #5473e8;
							}
						}

						.infomiddle {
							font-size: 12px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #b6b5c5;
							line-height: 17px;
							margin: 10px 0 23px;
						}

						.infobottom {
							color: #c0c4cc;
							font-size: 12px;
							line-height: 17px;
							white-space: nowrap;
						}
					}


				}
			}
		}

		.testTwo {
			order: 5;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
		}

		.testPoint {
			text-align: center;
			font-size: 16px;
			font-weight: 600;
			color: #5473E8;
			line-height: 28px;

			> :first-child {
				color: #000000;
				margin-right: 11px;
			}

			> :last-child {
				color: #4b4b65;
			}
		}

		.testPoint1 {
			margin-top: 8px;
			font-size: 16px;
			font-weight: 800;
			color: #4B4B65;
			line-height: 28px;
		}

		.testPoint2 {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #5473E8;
			margin: 10px 0;

			i {
				margin: 0 6px;
			}

			> :first-child {
				opacity: 0.1;
			}

			> :nth-child(2) {
				opacity: 0.3;
			}
		}

		.testPoint3 {
			font-size: 12px;
			font-weight: 400;
			color: #717083;
			line-height: 18px;
		}

		.testPoint4 {
			cursor: pointer;
			margin-top: 30px;
			font-size: 12px;
			font-weight: 600;
			color: #5473E8;
			line-height: 17px;
		}

		.articleBox {
			margin-top: 40px;
			width: 100%;
			background-color: #fff;

			.articleList {
				width: 90%;
				margin: 0 auto;
				padding: 40px 0;

				.detailBox {
					display: flex;
					align-items: center;
					justify-content: space-between;
					transition: opacity 1.4s;
					position: relative;
				}

				.detailInfo {
					width: 100%;

					.info1 {
						font-size: 16px;
						font-weight: 600;
						color: #4B4B65;
						line-height: 24px;
					}

					.info2 {
						margin-top: 6px;
						font-size: 12px;
						font-weight: 400;
						color: #717083;
						line-height: 16px;
						height: 32px;
					}

					.info3 {
						.right {
							font-size: 14px;
							font-weight: 400;
							color: #51516A;
							line-height: 20px;
						}
					}
				}

				.detailBox2 {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					width: calc(100% - 30px);
				}

				.chooseArticle {
					width: 100%;
					height: 387px;

					.chooseOne {
						cursor: pointer;

						color: #C0C4CC;

						.info2 {
							transition: all 0.4s;
						}

						.info3 {
							filter: none;
						}

						&:not(:last-child) {
							margin-bottom: 20px;
							border-bottom: 1px solid #f4f4f4;
						}
					}

					.chooseActive {
						.info1 {
							color: #C0C4CC;
						}

						.info2 {
							color: #C0C4CC;
						}

						.info3 {
							filter: grayscale(100%);
							color: #C0C4CC;

							.right {
								color: #C0C4CC;
							}
						}
					}
				}
			}
		}

		.indicator {
			.indicatorOne {
				width: 40px;
				height: 10px;
				background: #F4F4F4;
				border-radius: 6px;
				cursor: pointer;
				transition: all 0.6s;
			}

			.indicatorActive {
				width: 10px;
				height: 10px;
				background: #5473E8;
				border-radius: 50%;
			}
		}

		.zxsBox {
			margin-top: 40px;
			width: 100%;

			.zxsList {
				width: 90%;
				margin: 0 auto;

				// padding: 40px 0;
				.testOne {
					// width: 280px;
					width: 100%;
					border-radius: 6px;
					overflow: hidden;
					// border: 1px solid #eaeef3;
					// margin-bottom: 30px;
					position: relative;
					height: 300px;
					display: flex;
					flex-direction: column;

					.subTit {
						position: absolute;
						left: 0;
						top: 0;
						z-index: 3;
						padding: 7px 10px;
						background-color: rgba(243, 228, 228, 0.4);
						border-radius: 0 0 8px 0;

						font-size: 10px;
						font-weight: 400;
						color: #4B4B65;
						line-height: 14px;
					}

					.imgBig:hover {
						transition: all 0.7s;
						// transform: scale(1.5,1.5);
					}
				}

				.height0 {
					height: 0;
				}

				.heightAni {
					height: 80px;
				}

				.zxsInfo {
					padding: 20px;
					background-color: #fff;
					display: flex;
					align-items: stretch;
					justify-content: center;
					flex-direction: column;

					.infoTop {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 18px;
						font-weight: 600;
						color: #4B4B65;
						line-height: 25px;

						.point {
							width: 3px;
							height: 3px;
							border-radius: 50%;
							background-color: #EC977A;
							margin-right: 5px;
						}

						.infoSmall {
							font-size: 12px;
							font-weight: 400;
							color: #717083;
							line-height: 17px;
						}
					}

					.infoBot {
						margin-top: 10px;
						max-width: 238px;
						height: 30px;
						border: 1px solid #F4F4F4;
						font-size: 12px;
						font-weight: 400;
						color: #B6B5C5;
						line-height: 30px;
						padding-left: 6px;
						box-sizing: border-box;
						display: flex;

						.infoBotSub {
							&:not(:last-child)::after {
								content: '、';
								font-size: 10px;
							}
						}
					}

					.helpNum {
						color: #4b4b65;
						font-size: 12px;
						text-align: center;
						margin: 20px 0;
					}

					.zxBtn {
						color: #ffffff;
						background-color: #EC977A;
						padding: 2px 8px;
						border-radius: 12px;
						cursor: pointer;
					}
				}
			}
		}

		.qaBox {
			margin-top: 40px;
			width: 100%;
			background-color: #fff;

			.qaSelect {
				.selectNav {
					display: inline-block;
					cursor: pointer;
				}

				.activeNav {
					color: #5473E8;
				}

			}

			.qaList {
				width: 90%;
				margin: 0 auto;
				padding: 40px 0;
				position: relative;

				.qaAll {
					flex-wrap: wrap;

					>:not(:nth-last-child(-n+2)) {
						border-bottom: 1px solid #eeeeee;
					}

					&:last-child {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
					}

					.qaOne {
						padding: 20px 0;

						flex: 0 0 49%;
						width: 0;
						font-size: 14px;
						color: #cdcccf;
						cursor: pointer;
						transition: all 0.5s;

						.qaTop {}

						.qaMid {
							height: 32px;
							line-height: 16px;
						}

						.qaBot {
							font-size: 12px;
						}

						.gray {
							filter: grayscale(100%);
						}
					}

					.qaHover {
						color: #4B4B65;

						.gray {
							filter: grayscale(0);
						}
					}

					.qaHide {
						position: relative;
						z-index: 1;
						opacity: 0;
					}

					.qaShow {
						position: relative;
						z-index: 2;
						opacity: 1;
					}
				}
			}
		}

	}
</style>