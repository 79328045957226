<template>
	<div>
		<div class="message-body">
			<div class="message-head u-p-l-20">
				<div>
					{{ nickName }}
					<!-- 在线状态 -->
					<!-- <span v-if="activeInfo.type != 3">({{ privateChatIndex.online?'进入':'离开' }})</span> -->
					<!-- <el-tag class="bg-light-purple" size="small" v-if="activeInfo.user_identity">
						{{ activeInfo.user_identity }}
					</el-tag> -->
				</div>
				<div class="u-flex-1 u-m-l-10" style="font-size: 14px;" v-if="chatType==2">
					咨询将在{{countDownTime}}分钟之后结束
				</div>
				<div class="u-m-r-10" v-if="chatType==2">
					<el-button size="mini" @click="endChat" type="primary">结束咨询
					</el-button>
				</div>
				<div class="u-flex u-row-right" v-if="activeInfo.type != 3">
					<el-dropdown style="cursor: pointer;" class="u-m-r-10" v-if="privateChatIndex">
						<span class="el-dropdown-link">
							拉黑/举报<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>
								<span @click="clickBlock">{{!privateChatIndex.shield?'拉黑':'取消拉黑'}}</span>
							</el-dropdown-item>
							<el-dropdown-item>
								<span @click="clickReport">举报</span>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
			<div ref="chatLog" class="chat-log">
				<div class="chat-log-body" @contextmenu.prevent="test">
					<p v-if="chatLoading=='loading'" style="text-align: center;">加载中...</p>
					<div class="chat-log-item" v-for="(item,index) in chatLog" :key="index">
						<div class="notice" v-if="item.msg_type==6">
							— {{ item.content }} —
						</div>
						<div v-else>
							<div class="createtime">
								{{ item.createtime }}
							</div>
							<div class="message-item"
								:class="item.receiverid==privateChatIndex.sender.senderid?'receive-item':'send-item'">
								<el-avatar class="avatar" v-if="item.receiverid==privateChatIndex.sender.senderid"
									:src="privateChatIndex.receiver.receiverid_avatar">
								</el-avatar>
								<div v-else>
									<el-avatar class="avatar" v-if="item.msg_type==7||item.msg_type==13"
										:src="require('@/assets/aiavatar.png')"></el-avatar>
									<el-avatar class="avatar" v-else
										:src="privateChatIndex.sender.senderid_avatar"></el-avatar>
								</div>
								<div class="content-box">
									<div class="user-name" v-if="item.receiverid==privateChatIndex.sender.senderid">
										{{ privateChatIndex.receiver.receiverid_name }}
									</div>
									<div class="user-name" v-else>
										<span v-if="item.msg_type==7||item.msg_type==13">
											AI助理
										</span>
										<span v-else>
											{{ privateChatIndex.sender.senderid_name }}
										</span>
									</div>
									<div class="content-bottom">
										<i v-if="item.re_hidden==0" class="el-icon-warning ico"></i>
										<div class="content"
											:class="[item.receiverid==privateChatIndex.sender.senderid?'bg-user':'bg-zxs',(item.msg_type==34||item.msg_type==7||item.msg_type==14||item.msg_type==19||item.msg_type==18)?'bg-white':'',item.msg_type==3?'bg-none':'']">
											<div v-if="item.msg_type==1" v-html="item.content"></div>
											<div class="u-flex u-row-between" v-else-if="item.msg_type==2"
												style="cursor: pointer;" @click="playVoice(item)">
												<!-- <iconpark-icon name="wifi" style="transform: rotate(90deg);margin-bottom: 2px;margin-right: 5px;"></iconpark-icon> -->
												<i class="el-icon-video-pause"
													style="font-size: 22px;margin-bottom: 1px;margin-right: 5px;"
													:style="{color: item.receiverid==privateChatIndex.sender.senderid?'#4263E3':'#FFFFFF'}"
													v-if="item.isPlay"></i>
												<i class="el-icon-video-play"
													style="font-size: 22px;margin-bottom: 1px;margin-right: 5px;"
													:style="{color: item.receiverid==privateChatIndex.sender.senderid?'#4263E3':'#FFFFFF'}"
													v-else></i>
												<div>{{ item.length }}</div>{{ item.play }}
											</div>
											<div v-else-if="item.msg_type==3">
												<el-image style="height: 150px;max-width: 300px;"
													class="u-flex u-row-right lookPic" :src="item.content"
													fit="scale-down" :preview-src-list="[item.content]">
												</el-image>
											</div>
											<div v-else-if="item.msg_type==4">
												【语音通话】请打开APP查看
											</div>
											<div v-else-if="item.msg_type==5">
												【视频通话】请打开APP查看
											</div>
											<div v-else-if="item.msg_type==7">
												<div style="border-bottom: 1px solid #F7F7F7;line-height: 40px;">
													是什么问题一直困扰您呢？</div>
												<div class="u-flex"
													style="width: 360px;padding-top: 10px;flex-wrap: wrap;">
													<div v-for="(ite, idx) in item.content" class="u-flex pointer"
														style="border: 1px solid #e6e6e6;border-radius: 8px;padding: 6px;margin-bottom: 10px;margin-right: 10px;"
														:key="idx" @click="consultingDirection(ite,idx)">
														<el-image style="width: 26px; height: 26px; margin-right: 6px;"
															:src="ite.img" fit="scale-down"></el-image>
														{{ ite.name }}
													</div>
												</div>
											</div>
											<div v-else-if="item.msg_type==8">
												【新用户首次免费】请打开APP查看
											</div>
											<div v-else-if="item.msg_type==9">
												{{ item.content }}
											</div>
											<div v-else-if="item.msg_type==10">
												<div style="line-height: 40px;">以上基础表单我已经填写完成，请尽快回复我</div>
												<div>
													<div style="margin-top: 10px;">
														<span>问题类型：</span>
														<span
															style="color: #C8D3FF;">{{ item.content.zxOrientation }}</span>
													</div>
													<div style="margin-top: 10px;">
														<span>问题描述：</span>
														<span
															style="color: #C8D3FF;">{{ item.content.problemdescribe }}</span>
													</div>
													<div style="margin-top: 10px;">
														<span>持续时间：</span>
														<span
															style="color: #C8D3FF;">{{ item.content.problemTime }}</span>
													</div>
													<div style="margin-top: 10px;">
														<span>期望效果：</span>
														<span style="color: #C8D3FF;">{{ item.content.effect }}</span>
													</div>
												</div>
											</div>
											<div v-else-if="item.msg_type==11">
												【推荐套餐已失效】
												<!-- <div style="font-weight: 600;color: #252631;">推荐立即咨询套餐</div>
												<div style="" v-for="val in item.content" :key="val.id">
													<el-avatar class="avatar" shape="square" :size="48"
														:src="val.img"></el-avatar>
													<div>
														<div>{{ val.name }}</div>
														<div>
															【{{ val.direction }}】<span>￥{{ val.price }}</span>/{{ item.consultation_time }}分钟
														</div>
													</div>
												</div> -->
											</div>
											<div v-else-if="item.msg_type==12">
												【推荐咨询师】请打开APP查看
											</div>
											<div v-else-if="item.msg_type==13">
												{{ item.content }}
											</div>
											<div v-else-if="item.msg_type==14||item.msg_type==15"
												style="margin: 5px 8px;">
												<div style="font-weight: 600;">
													您好，以下是您的【{{ item.msg_type==14?'立即咨询':'预约咨询' }}】订单信息</div>
												<div
													style="padding-top: 10px;padding-right: 20px;font-size: 14px;font-weight: 400;line-height: 20px;">
													<div>
														<span>订单号：</span>
														<span>{{ item.content.order_number }}</span>
													</div>
													<div v-if="item.content.order_type==1">
														<span>套餐名称：</span>
														<span>{{ item.content.consultation_name }}</span>
													</div>
													<div>
														<span>服务类型：</span>
														<span>{{ item.content.consulting_type }}</span>
													</div>
													<div v-if="item.content.order_type==1">
														<span>咨询日期：</span>
														<span>{{ item.content.createtime }}</span>
													</div>
													<div v-if="item.content.order_type==2">
														<span>预约时间：</span>
														<span>{{ item.content.day }}（{{ item.content.week }}）{{ item.content.consult_start_time }}
															-
															{{ item.content.consult_end_time }}</span>
													</div>
													<div>
														<span>金额：</span>
														<span
															style="color: #F04352;">￥{{ item.content.order_money }}</span>
													</div>

													<!-- <div class="u-flex u-row-right u-m-t-10"
														v-if="item.content.take_order_status==1">
														<el-button size="mini" @click="chargeback(item.content.id)">拒绝
														</el-button>
														<el-button type="primary" size="mini"
															@click="takeOrder(item.content.id,item)">接受
														</el-button>
													</div> -->
												</div>
											</div>

											<!-- 测试题发送 -->
											<div v-else-if="item.msg_type==19">
												<div class="message-test-result" style="width: 225px;">
													<div class="message-test-result-title">
														已为您推荐心理自测题，请选择：
													</div>
													<div class="message-test-list">
														<div class="message-test-list-one pointer"
															:style="{width:item.content.length==1?'225px':'105px'}"
															v-for="(x,y) in item.content" :key="y" @click="goTest(x)">
															<div class="" style="font-size: 0;margin-bottom: 8px;">
																<el-image style="height: 70px;border-radius: 4px;"
																	:style="{width:item.content.length==1?'225px':'105px',height:item.content.length==1?'120px':'70px'}"
																	:src="x.test_cover_image+'?imageView2/0/w/'+(item.content.length==1?'225':'105')"
																	fit="scale-down"></el-image>
															</div>
															<div class="u-line-1">
																{{x.title}}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div v-else-if="item.msg_type==18">
												<div class="message-test-result" style="width: 225px;">
													<div class="message-test-result-title">
														我的测试结果出来了，请尽快查看~
													</div>
													<div class="message-test-list">
														<div class="message-test-list-one" :style="{width:'225px'}"
															@click="openTest(item)">
															<div class="" style="font-size: 0;margin-bottom: 8px;">
																<el-image style="height: 70px;border-radius: 4px;"
																	:style="{width:'225px',height:'120px'}"
																	:src="item.content.results_cover_image+'?imageView2/0/w/225'"
																	fit="scale-down"></el-image>
															</div>
															<div class="u-line-1">
																{{item.content.title}}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div v-else-if="item.msg_type==20">
												{{ item.content }}
											</div>
											<div v-else-if="item.msg_type==21">
												{{ item.content }}
											</div>
											<div v-else-if="item.msg_type==22">
												<div style="font-weight: 600;">推荐咨询师</div>
												<div style="padding-bottom: 10px;padding-right: 20px;">
													<div style="display: flex;align-items: center;margin-top: 20px;"
														v-for="val in item.content" :key="val.id">
														<el-avatar shape="square" :size="41"
															:src="val.portrait_image"></el-avatar>
														<div style="margin-left: 10px;">
															<div>
																<span
																	style="font-weight: 600;font-size: 14px;">{{ val.name }}</span>
																<span
																	style="margin-left: 5px;font-size: 12px;">{{ val.qualification }}</span>
															</div>
															<div style="font-size: 12px;margin-top: 5px;">
																好评<span
																	style="color: #EC977A;">{{ val.favorable_rate }}%</span>
																|
																服务<span
																	style="color: #EC977A;">{{ val.inquiries }}</span>次
															</div>
														</div>
													</div>
												</div>
											</div>
											<div v-else-if="item.msg_type==23">
												<div style="font-weight: 600;">推荐立即咨询套餐</div>
												<div style="padding-bottom: 10px;padding-right: 20px;">
													<div style="display: flex;align-items: center;margin-top: 20px;"
														v-for="val in item.content" :key="val.id">
														<el-image style="width: 41px; height: 41px" :src="val.img"
															fit="scale-down"></el-image>
														<div style="margin-left: 10px;">
															<div>
																<span
																	style="font-weight: 600;font-size: 14px;">{{ val.name }}</span>
															</div>
															<div style="font-size: 12px;margin-top: 5px;">
																【{{ val.direction }}】<span
																	style="color: #F04352;">￥{{ val.low_price }}</span>/{{ val.consultation_time }}分钟
															</div>
														</div>
													</div>
												</div>
											</div>
											<div v-else-if="item.msg_type==24">
												<div class="u-flex u-row-between">
													<div style="font-weight: 600;margin-right: 20px;">推荐预约咨询套餐
													</div>
													<div>{{ item.content.appoint.day }}</div>
												</div>
												<div>
													<div class="u-flex u-row-between" style="padding: 6px 0;">
														<div style="font-size: 16px;font-weight: 500;">
															{{ item.content.appoint.start_time }} 至
															{{ item.content.appoint.end_time }}
														</div>
														<div style="font-size: 16px;font-weight: 600;color: #F04352;">
															￥{{ item.content.appoint.price }}起
														</div>
													</div>
													<div class="u-flex" style="font-size: 14px;font-weight: 400;">
														<div v-for="v in item.content.appoint.choose_type.split(',')"
															:key="v" class="u-flex" style="margin-right: 10px;">
															<div
																style="width: 20px;height: 20px;background-color: #FFFFFF;display: flex;border-radius: 50%;align-items: center;justify-content: center;margin-right: 4px;">
																<el-image style="height: 12px"
																	:src="require('@/assets/icons/type'+v+'.png')"
																	fit="scale-down"></el-image>
															</div>
															{{ item.content.appoint.type_text.split(',')[item.content.appoint.type.split(',').indexOf(v)] }}
														</div>
													</div>
												</div>
											</div>
											<div v-else-if="item.msg_type==27">
												<div style="font-weight: 600;">推荐立即咨询套餐</div>
												<div style="padding-bottom: 10px;padding-right: 20px;">
													<div style="display: flex;align-items: center;margin-top: 20px;">
														<el-image style="width: 41px; height: 41px"
															:src="item.content.img" fit="scale-down"></el-image>
														<div style="margin-left: 10px;">
															<div>
																<span
																	style="font-weight: 600;font-size: 14px;">{{ item.content.name }}</span>
															</div>
															<div style="font-size: 12px;margin-top: 5px;">
																【{{ item.content.direction }}】<span
																	style="color: #F04352;">￥{{ item.content.low_price }}</span>/{{ item.content.consultation_time }}分钟
															</div>
														</div>
													</div>
												</div>
											</div>
											<div v-else-if="item.msg_type==28">
												<div class="u-flex u-row-between">
													<div style="font-weight: 600;margin-right: 20px;">推荐预约咨询套餐
													</div>
													<div>{{ item.content.day }}</div>
												</div>
												<div>
													<div class="u-flex u-row-between" style="padding: 6px 0;">
														<div style="font-size: 16px;font-weight: 500;">
															{{ item.content.start_time }} 至
															{{ item.content.end_time }}
														</div>
														<div style="font-size: 16px;font-weight: 600;color: #F04352;">
															￥{{ item.content.price }}起
														</div>
													</div>
													<div class="u-flex" style="font-size: 14px;font-weight: 400;">
														<div v-for="v in item.content.type.split(',')" :key="v"
															class="u-flex" style="margin-right: 10px;">
															<div
																style="width: 20px;height: 20px;background-color: #FFFFFF;display: flex;border-radius: 50%;align-items: center;justify-content: center;margin-right: 4px;">
																<el-image style="height: 12px"
																	:src="require('@/assets/icons/type'+v+'.png')"
																	fit="scale-down"></el-image>
															</div>
															{{ item.content.type_text.split(',')[item.content.type.split(',').indexOf(v)] }}
														</div>
													</div>
												</div>
											</div>
											<div v-else-if="item.msg_type==30||item.msg_type==31">
												<div style="font-weight: 600;">
													您好，以下是您的【{{ item.content.order_type_text }}】订单信息</div>
												<div
													style="padding-top: 10px;padding-right: 20px;font-size: 14px;font-weight: 400;line-height: 20px;">
													<div>
														<span>订单号：</span>
														<span>{{ item.content.order_number }}</span>
													</div>
													<div v-if="item.content.order_type==1">
														<span>套餐名称：</span>
														<span>{{ item.content.consultation_name }}</span>
													</div>
													<div>
														<span>服务类型：</span>
														<span>{{ item.content.consulting_type_text }}</span>
													</div>
													<div v-if="item.content.order_type==1">
														<span>咨询时长：</span>
														<span>{{ item.content.duration }}分钟</span>
													</div>
													<div v-if="item.content.order_type==2">
														<span>预约时间：</span>
														<span>{{ item.content.appointment_starttime }} 至
															{{ item.content.appointment_endtime }}</span>
													</div>
													<div>
														<span>金额：</span>
														<span
															style="color: #F04352;">￥{{ item.content.order_money }}</span>
													</div>
													<div>
														<span>订单状态：</span>
														<span>{{ item.content.order_status_text }}</span>
													</div>
												</div>
											</div>

											<div v-else-if="item.msg_type==33" class="cardInfo">
												<div class="cardTit u-flex u-flex u-row-between u-p-t-10 u-p-b-10">
													<div class="">购买咨询卡</div>
													<div class="">已购买</div>
												</div>

												<div class="u-p-t-10 u-flex ">
													<el-avatar class="avatar u-m-l-0"
														:src="privateChatIndex.sender.senderid_avatar">
													</el-avatar>
													<span>{{ privateChatIndex.sender.senderid_name }}</span>
													<div class="useType u-flex u-m-l-10">
														<el-image
															:src="require('@/assets/icons/type'+item.content.consultation_type+'.png')"
															style="height: 12px;height: 12px;margin-right: 2px;"
															fit="scale-down"></el-image>
														<span v-if="item.content.consultation_type==1">图文</span>
														<span v-if="item.content.consultation_type==2">语音</span>
														<span v-if="item.content.consultation_type==3">视频</span>
														<span v-if="item.content.consultation_type==4">面对面</span>
													</div>
												</div>
												<div class="card-duration u-flex u-flex u-row-between u-m-t-10">
													<div class="u-flex">
														<el-image style="width: 12px;height: 12px;"
															:src="require('@/assets/icons/date.png')">
														</el-image>
														<span class="cardTime">卡时长</span>
													</div>
													<div class="card-duration-right">
														<span
															class="">{{item.content.validtime*1000 | formatDate('yyyy.MM.dd到期')}}</span>
														<span
															style="color: #8D8D94;">/{{item.content.valid_days}}天</span>
													</div>
												</div>
												<div class="card-duration u-flex u-flex u-row-between u-m-b-0">
													<div class="u-flex">
														<el-image style="width: 12px;height: 12px;"
															:src="require('@/assets/icons/time.png')">
														</el-image>
														<span class="cardTime">卡权益</span>
													</div>
													<div class="card-duration-right">
														<span class="">可用{{item.content.remaining_duration}}分钟</span>
														<span
															style="color: #8D8D94;">/总:{{item.content.duration}}分钟</span>
													</div>
												</div>
											</div>


											<div v-else-if="item.msg_type==34" class="cardInfo">
												<div class="cardTit u-flex u-flex u-row-between u-p-t-10 u-p-b-10">
													<div class="">我提交的预约申请</div>
													<span class="bought">{{item.content.status_text}}</span>
												</div>

												<div class="u-m-t-10">
													<div class="cardOrder">
														<span>订单编号：</span>
														<span>{{ item.content.order_number }}</span>
													</div>
													<div class="u-flex cardOrder">
														<span class="">服务类型：</span>
														<span
															class="">{{item.content.order_type==2?'预约咨询':'立即咨询'}}</span>
														<div class="useType u-flex u-m-l-8">
															<el-image
																:src="require('@/assets/icons/type'+item.content.consulting_type+'.png')"
																style="height: 12px;height: 12px;margin-right: 2px;"
																fit="scale-down"></el-image>
															<span v-if="item.content.consulting_type==1">图文</span>
															<span v-if="item.content.consulting_type==2">语音</span>
															<span v-if="item.content.consulting_type==3">视频</span>
															<span v-if="item.content.consulting_type==4">面对面</span>
														</div>
													</div>

													<div class="cardOrder">
														<span class="">支付方式：</span>
														<span class="">咨询卡支付</span>
													</div>

													<div class="cardOrder">
														<span class="">支付时长：</span>
														<span class="">{{item.content.duration}}分钟</span>
													</div>
													<div class="cardOrder">
														<span class="">咨询日期：</span>
														<span class="">{{ item.content.appointment_starttime }}开始</span>
													</div>
												</div>
											</div>
											<div v-else>【消息信息】请打开APP查看</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="send-message-box" :style="{height: sendBoxHei + 'px'}">
				<div class="resize-bar u-flex u-row-between">
					<div class="u-flex">
						<div class="u-row-center u-flex">
							<el-popover placement="top" v-model="emojiPopover" width="500" trigger="click" @show=""
								@after-leave="">
								<div style="padding: 20px 0;">
									<div v-for="(parentItem, parentIndex) in emojiList" :key="parentIndex">
										<div class="emoji-list">
											<div class="emojiOne" v-for="item in parentItem" :key="item.value"
												@click="clickEmoji(item)">
												<el-image style="width: 100%;height: 100%;cursor: pointer;"
													:src="item.value | textOrEmoji" fit="scale-down"></el-image>
											</div>
										</div>
									</div>
								</div>
								<div slot="reference" style="display: flex;">
									<el-image style="height: 20px;cursor: pointer;"
										:src="require('@/assets/operationFace.png')" fit="scale-down"></el-image>
								</div>
							</el-popover>

							<el-upload v-if="privateChatIndex?privateChatIndex.function.image:false" :action="baseUrl+'/api/common/upload'"
								:headers="{token:$store.state.token}" accept=".png,.jpg,.JPG,.jpeg,.JPEG"
								:show-file-list="false" :on-success="upLoadSuccess">
								<i class="el-icon-picture-outline" style="font-size: 22px;margin-left: 15px;"></i>
							</el-upload>
							
							
							<el-popover placement="top" v-model="recPopover" width="300" trigger="click"
								@show="popoverShow" @after-leave="resetRec" :disabled="privateChatIndex?privateChatIndex.function.voice==0:false">
								<div style="padding: 20px 0;">
									<div style="text-align: center;padding-bottom: 52px;" v-if="!recStatus">
										<p>点击录音</p>
										<i class="el-icon-microphone"
											style="font-size: 40px;cursor: pointer;color: #1890ff;"
											@click="recStart" />
									</div>
									<div style="text-align: center;padding-bottom: 52px;" v-else-if="recStatus==1">
										<p>{{ bufferDuration }}</p>
										<el-button type="danger" style="width: 40px;height: 40px;" @click="recStop">
										</el-button>
									</div>
									<div v-else>
										<div style="text-align: center;" v-if="recStatus==2">
											<p>{{ bufferDuration }}</p>
											<i class="el-icon-video-play" style="font-size: 40px;cursor: pointer;"
												@click="recPlay" />
										</div>
										<div style="text-align: center;" v-if="recStatus==3">
											<p>{{ bufferDuration }}</p>
											<i class="el-icon-video-pause" style="font-size: 40px;cursor: pointer;"
												@click="recPause" />
										</div>
										<div style="margin-top: 20px;display: flex;">
											<el-button size="small" style="flex: 1;" @click="colsePopover">取 消
											</el-button>
											<el-button type="primary" :loading="uploadRecLoading" size="small"
												style="flex: 1;" @click="uploadRec">发 送</el-button>
										</div>
									</div>
								</div>
								<i slot="reference" class="el-icon-mic" @click="isDisable"
									style="font-size: 22px;cursor: pointer;margin-left: 10px;" />
							</el-popover>
						</div>
					</div>
					<div class="right-box" ref="resizeBar">
						<iconpark-icon name="expand-text-input" ref="resizeBar" style="cursor: row-resize;">
						</iconpark-icon>
					</div>
				</div>
				<el-input type="textarea" id="reply" style="flex: 1"
					:placeholder="privateChatIndex?privateChatIndex.input_title:''" :autofocus="true" resize="none"
					v-model="chatContent" @keydown.enter.prevent.native="messageEenter">
				</el-input>
				<div class="send">
					<el-button type="primary" :loading="sendLoading" @click="sendMessage">发送</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		queryChatInfo,
		queryChatMessageLog,
		sensitive,
		messageSave,

		shieldCancel,
		shieldConsultant,

		orderRemindTime,
		overImageText,
		upload
	} from '@/api/api.js'

	import {
		createSocket,
		sendWSPush,
		closeSocket
	} from '@/utils/websocket'
	import EmojiList from '@/utils/emoji';
	
	let rec, loading;
	let audio = new Audio();//发送录音
	const audio1 = new Audio() // 消息语音
	
	let rotationtime = null
	let ordertime = null
	export default {
		data() {
			return {
				chatType: 1, //1私聊2图文3助手 如果是图文需要切换到chatType为2
				currentIndex: 0,
				privateChatIndex: null,
				last_id: '',
				chatLog: [],
				userInfo: null,
				chatLoading: 'loadmore', // 上拉加载历史消息状态
				sendLoading: false, // 发送按钮状态
				sendBoxHei: 200,
				emojiPopover: false,
				//表情图片
				emojiList: [],
				//语音开始
				recPopover: false, //语音
				recStatus: 0,
				bufferDuration: '00:00',
				uploadRecLoading: false,
				//语音结束
				baseUrl: process.env.VUE_APP_BASE_API,
				orderid: '',
				countDownTime: '',

				nickName: '',
				chatContent: '',
			}
		},
		props: {
			activeInfo: {
				type: Object,
				default: {}
			}
		},
		watch: {
			activeInfo: {
				handler: function(newV) { //切换聊天框重新获取信息
					console.log('activeInfo', newV)
					this.chatLoading = 'loadmore'
					this.last_id = ''
					this.orderid = ''
					this.getChatInfo()
				},
				deep: true,
			}
		},
		filters: {
			//表情地址
			textOrEmoji(value) {
				return `https://qiniu.manmanxinli.com/wechat/user/order/privateChat/emojiNew/d_${value}.gif`
			}
		},
		mounted() {
			this.getChatInfo()
			this.$EventBus.$on('sensitive', this.sensitive)
			this.$EventBus.$on('refresh',this.getChatInfo)
			this.chatType = this.activeInfo.type
			//表情
			this.emojiList = EmojiList.emojiList;
			this.onlineEmoji = EmojiList.onlineEmoji;
		},
		beforeDestroy() {
			console.log('窗口关闭即将销毁了')
			this.$EventBus.$off('sensitive', this.sensitive)
			this.$EventBus.$off('refresh',this.getChatInfo)
			sendWSPush({
				type: 'leavegroup',
				group: this.privateChatIndex.group
			})
		},
		methods: {
			goTest(item){
				console.log(item)
				let arr = [{
						name: "心理测试",
						url: "/TestHome"
					},
					{
						name: item.title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "TestInfo",
					query: {
						id:item.id
					}
				});
				this.$emit('closeWindow')
			},
			consultingDirection(item){
				var data = {
					type: "field",
					content: item.name,
					senderid: this.privateChatIndex.sender.senderid,
					receiverid: this.privateChatIndex.receiver.receiverid,
					group: this.privateChatIndex.group,
					avatar: this.privateChatIndex.sender.senderid_avatar,
					order_id: this.orderid
				}
				sendWSPush(data)
				this.messageSave({ 
					senderid: data.senderid,
					receiverid: data.receiverid,
					msg_type: 1,
					content:item.name,
					status: this.privateChatIndex.online ? 1 : 0
				});
			},
			isDisable(){
				if(this.privateChatIndex.function.voice==0){
					this.$message.warning(this.privateChatIndex.function.voice_tip)
				}
			},
			/**调用open打开录音请求好录音权限**/
			recOpen(success) { //一般在显示出录音按钮或相关的录音界面时进行此方法调用，后面用户点击开始录音时就能畅通无阻了
				let _this = this;
				rec = Recorder({ //本配置参数请参考下面的文档，有详细介绍
					type: "mp3",
					sampleRate: 16000,
					bitRate: 16 //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
						,
					onProcess: function(buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx,
						asyncEnd) {
						//录音实时回调，大约1秒调用12次本回调
						//可实时绘制波形（extensions目录内的waveview.js、wavesurfer.view.js、frequency.histogram.view.js扩展功能）
						//可利用extensions/sonic.js扩展实时变速变调，此扩展计算量巨大，onProcess需要返回true开启异步模式
						//可实时上传（发送）数据，配合Recorder.SampleData方法，将buffers中的新数据连续的转换成pcm上传，或使用mock方法将新数据连续的转码成其他格式上传，可以参考文档里面的：Demo片段列表 -> 实时转码并上传-通用版；基于本功能可以做到：实时转发数据、实时保存数据、实时语音识别（ASR）等
						_this.bufferDuration = '0' + Math.floor(bufferDuration / 60000) + ':' + (Math.floor(
							bufferDuration % 60000 / 1000) < 10 ? '0' + Math.floor(bufferDuration %
							60000 / 1000) : Math.floor(bufferDuration % 60000 / 1000))
			
						if (bufferDuration > 60000) {
							_this.recStop();
						}
					}
				});
			
				//var dialog=createDelayDialog(); 我们可以选择性的弹一个对话框：为了防止移动端浏览器存在第三种情况：用户忽略，并且（或者国产系统UC系）浏览器没有任何回调，此处demo省略了弹窗的代码
				rec.open(function() { //打开麦克风授权获得相关资源
					//dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消
					//rec.start() 此处可以立即开始录音，但不建议这样编写，因为open是一个延迟漫长的操作，通过两次用户操作来分别调用open和start是推荐的最佳流程
			
					success && success();
				}, function(msg, isUserNotAllow) { //用户拒绝未授权或不支持
					//dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消
			
					_this.$message.error((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg)
				});
			},
			
			/**开始录音**/
			recStart() { //打开了录音后才能进行start、stop调用
				rec.start();
				this.recStatus = 1;
			},
			
			/**结束录音**/
			recStop() {
				this.recStatus = 2;
				let _this = this;
				rec.stop(function(blob, duration) {
					console.log(blob, (window.URL || webkitURL).createObjectURL(blob), "时长:" + duration + "ms");
					rec.close(); //释放录音资源，当然可以不释放，后面可以连续调用start；但不释放时系统或浏览器会一直提示在录音，最佳操作是录完就close掉
					rec = null;
			
					//已经拿到blob文件对象想干嘛就干嘛：立即播放、上传
					_this.blob = blob
			
					/*** 【立即播放例子】 ***/
					// var audio=document.createElement("audio");
					// audio.controls=true;
					// document.body.appendChild(audio);
					//简单利用URL生成播放地址，注意不用了时需要revokeObjectURL，否则霸占内存
					audio.src = (window.URL || webkitURL).createObjectURL(blob);
				}, function(msg) {
					_this.$message.error("录音失败:" + msg)
					rec.close(); //可以通过stop方法的第3个参数来自动调用close
					rec = null;
				});
			},
			// 播放录音
			recPlay() {
				this.recStatus = 3;
				audio.play();
				audio.onended = () => {
					this.recStatus = 2;
				}
			},
			recPause() {
				this.recStatus = 2;
				audio.pause();
			},
			colsePopover() {
				this.recPopover = false
			},
			uploadRec() {
				const form = new FormData()
				form.append('file', this.blob, 'recorder.mp3')
				this.uploadRecLoading = true
				upload(form).then(res => {
					this.recOpen()
					this.recStatus = 0;
					this.sendVoice(res.data.url);
				})
			},
			popoverShow() {
				// 初始化录音
				this.recOpen()
			},
			resetRec() {
				if (!this.recStatus) {
					rec.close(); //释放录音资源
				}
				if (this.recStatus == 1) {
					this.recStop()
				}
				if (this.recStatus > 1) {
					(window.URL || webkitURL).revokeObjectURL(audio.src);
				}
				this.recStatus = 0;
			
			},
			// 发送聊天语音
			sendVoice(filePath) {
				let data = JSON.parse(JSON.stringify(this.privateChatIndex));
				sendWSPush({
					type: 'voice',
					content: filePath,
					length: this.bufferDuration,
					senderid: data.sender.senderid,
					receiverid: data.receiver.receiverid,
					group: data.group,
					avatar: data.sender.senderid_avatar
				})
				this.messageSave({
					senderid: data.sender.senderid,
					receiverid: data.receiver.receiverid,
					msg_type: 2,
					content: filePath,
					length: this.bufferDuration,
					status: this.privateChatIndex.online ? 1 : 0
				})
			},
			// 上传图片发送
			upLoadSuccess(res, file) {
				//上传图片完成，发送图片路径
				let data = JSON.parse(JSON.stringify(this.privateChatIndex));
				sendWSPush({
					type: 'img',
					content: res.data.url,
					senderid: data.sender.senderid,
					receiverid: data.receiver.receiverid,
					group: data.group,
					avatar: data.sender.senderid_avatar
				})
				this.messageSave({
					msg_type: 3,
					content: res.data.url,
					senderid: data.sender.senderid,
					receiverid: data.receiver.receiverid,
					status: this.privateChatIndex.online ? 1 : 0
				})
			
			},
			endChat() {
				this.$confirm('点击确认将结束当前图文订单, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					overImageText({
						order_id: this.orderid,
					}).then(res => {
						if (res.code == 1) {
							this.$message({
								type: 'success',
								message: '结束成功'
							});
							this.getChatInfo()
						}
					})
				})
			},
			//敏感词验证 send
			sensitive(data) {
				sensitive(data).then(res => {
					if (this.privateChatIndex.group == data.group) {
						data.content = res.data.content;
						data.createtime = res.data.createtime
						data.re_hidden = res.data.re_hidden;
						data.updatetime = res.data.createtime;
						if (res.data.re_hidden == 1) {
							this.chatLog.push(data);
							// 滚动到底部
							this.$nextTick(() => {
								this.$refs.chatLog.scrollTop = this.$refs.chatLog.scrollHeight - this.$refs
									.chatLog.clientHeight;
							})
						} else {
							if (data.senderid == this.privateChatIndex.sender.senderid) {
								this.$message.error('您发送的消息含有违禁字词');
								this.chatLog.push(data);
								// 滚动到底部
								this.$nextTick(() => {
									this.$refs.chatLog.scrollTop = this.$refs.chatLog.scrollHeight - this
										.$refs
										.chatLog.clientHeight;
								})
							} else {
								this.$message.error('对方发送的消息含有违禁字词');
							}
						}
					}
				})
			},
			//获取textarea光标位置（给光标位置添加表情）
			changeSelectedText(obj, str) {
				if (window.getSelection) {
					// 非IE浏览器
					obj.setRangeText(str);
					// 在未选中文本的情况下，重新设置光标位置
					obj.selectionStart += str.length;
					obj.focus()
				} else if (document.selection) {
					// IE浏览器
					obj.focus();
					var sel = document.selection.createRange();
					sel.text = str;
				}
			},
			//点击emoji
			clickEmoji(item) {
				var textArea = document.getElementById('reply');
				this.changeSelectedText(textArea, item.lable);
				this.chatContent = textArea.value; // 要同步data中的数据
			},
			messageEenter(e) {
				if (e.shiftKey) {
					let content = this.chatContent;
					let str = content.substring(0, e.srcElement.selectionStart);
					this.chatContent = content.replace(str, str + '\n');
				} else if (!this.sendLoading) {
					this.sendMessage();
				}
			},
			//发送消息
			sendMessage() {
				let data = JSON.parse(JSON.stringify(this.privateChatIndex));
				data.chatContent = this.chatContent;
				console.log(data)
				if (data.chatContent && !data.chatContent.trim()) {
					this.$message.error('请输入消息内容');
					return
				}
				if (data.chatContent) {
					this.sendLoading = true;
					let content = this.replaceEmoji(data.chatContent);
					sendWSPush({
						type: "text",
						content,
						senderid: data.sender.senderid,
						receiverid: data.receiver.receiverid,
						group: data.group,
						avatar: data.sender.senderid_avatar
					})
					this.messageSave({
						senderid: data.sender.senderid,
						receiverid: data.receiver.receiverid,
						msg_type: 1,
						content,
						status: this.privateChatIndex.online ? 1 : 0
					});
				}
			},
			// 消息持久化
			messageSave(data) {
				data.type = this.activeInfo.type
				messageSave(data).then(res => {
					data.createtime = '';
					if (data.msg_type == 1) {
						this.chatContent = '';
						this.sendLoading = false;
					} else {
						this.dialogConfirmLoading = false;
						this.dialogVisible = false;
					}
					// this.chatLog.push(data);
					this.chatLog.push(res.data.list);
					// this.getChatList(true)
					// 滚动到底部
					this.$nextTick(() => {
						this.$refs.chatLog.scrollTop = this.$refs.chatLog.scrollHeight - this.$refs
							.chatLog.clientHeight;
					})
				})
			},
			//替换表情
			replaceEmoji(message) {
				// 处理富文本图片无法显示问题
				let newStr = message.replace(/\<img/gi, '<img style="max-width:100%;height:auto"')
				// 处理表情
				let temp = []
				let reg = /\[.*?\]/g
				while ((temp = reg.exec(newStr))) {
					let str = temp[0].substring(1, temp[0].length - 1)
					if (this.onlineEmoji[str]) {
						let value = this.onlineEmoji[str]
						let html =
							`<img src='https://qiniu.manmanxinli.com/wechat/user/order/privateChat/emojiNew/d_${value}.gif' style='width:18px;height:18px;vertical-align: middle;display:inline-block;'></img>`
						newStr = newStr.replace(temp[0], html)
					}
				}
				return '<div style="word-break:break-all;">' + newStr + '</div>'
			},
			//拉黑
			clickBlock() {
				let msg = ''
				if (this.privateChatIndex.shield) {
					msg = '您是否确定取消拉黑该咨询师？取消拉黑后可以接收到该咨询师消息'
				} else {
					msg = '您是否确定拉黑该咨询师？拉黑后不会接收到该咨询师任何消息'
				}
				this.$confirm(msg, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.shiledFunc(this.privateChatIndex.shield)
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消操作'
					});
				});
			},
			// 拉黑取消拉黑接口
			shiledFunc(type) {
				if (type) {
					shieldCancel({
						consultant_id: this.privateChatIndex.consultant.id
					}).then(res => {
						this.privateChatIndex.shield = 0
						this.$message({
							type: 'success',
							message: '取消成功!'
						});
					})
				} else {
					shieldConsultant({
						receiverid: this.activeInfo.chatid
					}).then(res => {
						this.privateChatIndex.shield = 1
						this.$message({
							type: 'success',
							message: '拉黑成功!'
						});
					})
				}
			},
			//举报
			clickReport() {
				console.log('点击了')
				this.$emit('closeWindow')
				// 打开举报弹窗
				let arr = [{
						name: "心理咨询",
						url: "/ZxsHome"
					},
					{
						name: this.privateChatIndex.consultant.name,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "ZxsInfo",
					query: {
						id: this.privateChatIndex.consultant.id,
						report:true
					}
				});
			},
			//获取聊天信息
			getChatInfo(type) {
				queryChatInfo({
					senderid: this.$store.state.chatid,
					receiverid: this.activeInfo.chatid,
					type: this.activeInfo.type == 3 ? 3 : 1,
				}).then(res => {
					console.log('信息', res)
					this.nickName = res.data.receiver.receiverid_name
					this.privateChatIndex = res.data;
					if (this.activeInfo.type == 3) {

					} else {
						if (res.data.order) {
							this.chatType = 2;
							this.orderid = res.data.order.id;
							this.getOrderTime();
							clearInterval(rotationtime);
							rotationtime = setInterval(() => {
								this.getOrderTime();
							}, 60000)
						} else {
							clearInterval(rotationtime)
							clearInterval(ordertime)
							this.chatType = 1;
							this.orderid = null;
						}
					}
					if(!type){
						this.getChatLog()
						sendWSPush({
							type: 'bindgroup',
							group: res.data.group
						})
					}
				})
			},
			// 获取订单倒计时
			getOrderTime() {
				console.log(this.chatType, this.orderid)
				if (this.chatType != 2 || !this.orderid) return
				orderRemindTime({
					order_id: this.orderid
				}).then(res => {
					console.log(res)
					this.countDownTime = res.data.remain_time
					clearInterval(ordertime)
					if (res.data.start == 1) {
						console.log('res.data.start', res.data.start)
						ordertime = setInterval(this.countDownFunc, 1000)
					}
				})
			},
			//订单倒计时
			countDownFunc() {
				let countDownTime = this.countDownTime
				let timeArr = countDownTime.split(':')
				let second = Number(timeArr[0] * 60 * 60) + Number(timeArr[1] * 60) + Number(timeArr[2])
				if (second == 0) {
					clearInterval(rotationtime)
					clearInterval(ordertime)
					this.getOrderTime()
					return
				}
				this.$nextTick(() => {
					this.formateSeconds(second - 1)
				})
			},
			formateSeconds(endTime) {
				let secondTime = parseInt(endTime) //将传入的秒的值转化为Number
				let min = 0 // 初始化分
				let h = 0 // 初始化小时
				let result = ''
				if (secondTime > 60) { //如果秒数大于60，将秒数转换成整数
					min = parseInt(secondTime / 60) //获取分钟，除以60取整数，得到整数分钟
					secondTime = parseInt(secondTime % 60) //获取秒数，秒数取佘，得到整数秒数
					if (min >= 60) { //如果分钟大于等于60，将分钟转换成小时
						h = parseInt(min / 60) //获取小时，获取分钟除以60，得到整数小时
						min = parseInt(min % 60) //获取小时后取佘的分，获取分钟除以60取佘的分
					}
				}
				this.countDownTime =
					`${h.toString().padStart(2,'0')}:${min.toString().padStart(2,'0')}:${secondTime.toString().padStart(2,'0')}`
				return result
			},
			// 获取聊天日志
			getChatLog(index) {
				if (this.chatLoading != 'loadmore') return
				this.chatLoading = 'loading';
				if (this.last_id) {
					var height = this.$refs.chatLog.scrollHeight;
				}
				let type = this.activeInfo.type
				queryChatMessageLog({
					senderid: this.$store.state.chatid,
					receiverid: this.activeInfo.chatid,
					type: this.activeInfo.type == 3 ? 3 : 1,
					last_id: this.last_id,
				}).then(res => {
					if (res.data.list.length) {
						this.chatLoading = 'loadmore';
					} else {
						this.chatLoading = 'nomore';
						return
					}
					if (this.last_id) {
						this.chatLog.unshift(...res.data.list);
					} else {
						this.chatLog = res.data.list
					}
					this.$nextTick(() => {
						if (this.last_id) {
							this.$refs.chatLog.scrollTop = this.$refs.chatLog.scrollHeight - height;
						} else {
							this.$refs.chatLog.scrollTop = this.$refs.chatLog.scrollHeight - this.$refs
								.chatLog.clientHeight;
							this.$refs.chatLog.onscroll = e => {
								if (!e.target.scrollTop) {
									this.getChatLog();
								}
							}
						}
						this.last_id = res.data.list[0].id;
					})
				})
			},
			//播放
			changeSrcPlay(e) {
				audio1.src = e.content
				console.log(e.content)
				e.isPlay = true;
				audio1.play();
			},
			playVoice(e) {
				audio1.onended = () => {
					e.isPlay = false;
				}
				if (audio1.src) {
					if (audio1.ended) {
						this.changeSrcPlay(e)
					} else {
						if (audio1.src == e.content) {
							if (audio1.paused) {
								this.changeSrcPlay(e)
								e.isPlay = true;
								audio1.play();
							} else {
								e.isPlay = false;
								audio1.pause()
							}
						} else {
							this.chatLog.find(v => v.content == audio1.src).isPlay = false;
							this.changeSrcPlay(e)
						}
					}
				} else { // audio1第一次播放语音
					this.changeSrcPlay(e)
				}
				this.$forceUpdate();
			},
		}
	}
</script>

<style lang="scss" scoped>
	.bg-zxs {
		background-color: #4263E3;
		color: #FFFFFF;
	}

	.bg-user {
		background-color: #ffffff;
		color: #222222;
	}

	.bg-white {
		background-color: #ffffff;
		color: #222222;
	}

	.bg-none {
		background-color: transparent;
	}

	.message-body {
		height: calc(90vh - 50px);
		min-height: 500px;
		display: flex;
		flex-direction: column;
		position: relative;

		.message-head {
			height: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 10px;
		}

		.chat-log {
			flex: 1;
			height: 0;
			overflow-y: auto;
			background-color: #f7f7f7;

			.chat-log-body {
				padding: 0 10px;
				background-color: #f7f7f7;

				.chat-log-item {
					padding-top: 16px;

					.notice {
						font-size: 14px;
						font-weight: 400;
						color: #C0C4CC;
						text-align: center;
						padding-bottom: 6px;
					}

					.createtime {
						text-align: center;
						color: gray;
					}

					.message-item {
						display: flex;
						align-items: flex-start;
						padding: 5px 0;

						.avatar {
							margin: 0 10px;
							flex: 0 0 auto;
						}

						.content-box {
							max-width: 500px;
							display: flex;
							flex-direction: column;

							.content-bottom {
								margin-top: 5px;

								.content {
									display: inline-block;
									padding: 6px;
									border-radius: 4px;
									word-break: break-word;
								}
							}
						}

					}

					.receive-item {}

					.send-item {
						flex-direction: row-reverse;

						.content-box {
							align-items: flex-end;

							.content-bottom {
								display: flex;
								align-items: center;

								.ico {
									color: red;
									// margin-top: 20px;
									margin-right: 6px;
								}
							}
						}
					}
				}
			}
		}

		.send-message-box {
			padding: 20px;
			padding-top: 0;
			// border-top: 1px solid rgb(0 21 41 / 8%);
			display: flex;
			flex-direction: column;
			max-height: 200px;

			::v-deep .el-textarea__inner {
				height: 100%;
			}

			.resize-bar {
				height: 50px;
				min-height: 50px;


			}

			.send {
				padding-top: 10px;
				text-align: right;
			}
		}
	}

	.emoji-list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.emojiOne {
			width: 8.33%;
			height: 30px;
			margin-bottom: 30px;
		}
	}

	// 测试题
	.message-test-result {
		.message-test-result-title {
			font-size: 14px;
			font-weight: 600;
			color: #252631;
			line-height: 20px;
		}

		.message-test-result-tips {
			background: #F7F7F7;
			border-radius: 3px;
			padding: 6px 10px;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			margin-top: 10px;

			.message-test-result-tips-ico {
				flex: 0 0 12px;
				width: 12px;
				height: 12px;
				background: #4263E3;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 0;
				line-height: 17px;
				margin-right: 5px;
			}

			.message-test-result-tips-text {
				font-size: 12px;
				font-weight: 400;
				color: #8D8D94;
				line-height: 17px;
			}
		}

		.message-test-result-image {
			margin-top: 15px;
		}

		.message-test-result-name {
			font-size: 12px;
			font-weight: 400;
			color: #5A5B60;
			line-height: 20px;
			margin-top: 5px;
		}

		.message-test-list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;

			.message-test-list-one {
				width: 105px;
				margin-top: 15px;

				font-size: 12px;
				font-weight: 400;
				color: #5A5B60;
				line-height: 17px;
			}
		}
	}
</style>