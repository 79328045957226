<template>
	<div class="content" v-loading="loadingData">
		<div class="top u-flex u-row-between">
			<div class="topTit">
				<span>{{categoryName}}</span>
				<span>在知识的海洋中穿梭</span>
			</div>
		</div>

		<div class="qaList">
			<div class="qaOne u-flex u-row-between pointer" v-for="(item,index) in dataList" :key="index" @click="goInfo(item)">
				<div class="" style="flex: 0 0 200px;">
					<el-image style="width: 200px;height: 120px;border-radius: 8px;" :src="item.image" fit="cover"></el-image>
				</div>
				<div class="u-m-l-20 u-flex-1" style="width: 0;">
					<div class="conT u-line-1">{{item.title}}</div>
					<div class="conM u-line-3 u-m-t-8">{{item.describe}}</div>
					<div class="conB u-m-t-8 u-flex u-row-between">
						<div class="">
							<span class="u-m-r-10">#{{item.category_names[0]}}</span>
							<span>{{item.create_date.split(' ')[0].split('-').join('.')}}</span>
						</div>
						<div class="operate u-flex">
							<div class="u-flex u-flex-nowrap">
								<i class="el-icon-view"></i>
								<span>{{item.read_num}}</span>
							</div>
							<div class="u-flex u-flex-nowrap">
								<i class="el-icon-chat-dot-round"></i>
								<span>{{item.comment_num}}</span>
							</div>
							<div class="u-flex u-flex-nowrap">
								<iconpark-icon name="thumbs-up"></iconpark-icon>
								<span>{{item.like_num}}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<el-empty description="暂无信息" v-if="!dataList.length"></el-empty>
			<!-- 分页 -->
			<div style="width: 100%;overflow: auto;">
				<el-pagination
					style="text-align: center;margin-top: 20px;width: 100%;"
					@size-change="handleSizeChange" :pager-count="5" @current-change="handleCurrentChange"
					:current-page.sync="queryData.page" :page-size="10" :page-sizes="[5,10,20,30]"
					layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		queryArticleList,
		queryArticleCategoryList
	} from "@/api/api.js";
	export default {
		data() {
			return {
				categoryName: '全部文章',
				dataList: [],
				total: 0,
				queryData: {
					page: 1,
					perpage: 10,
					category_id: '',
					user_type:1,
					// recommend: 1,
				},
				loadingData: true,
			}
		},
		computed: {
			classArr() {
				return this.$store.state.classArr
			}
		},
		watch: {
			changeType(val) {
				if (val) {
					this.queryData.reward = 1
					this.queryData.recommend = 0
				} else {
					this.queryData.reward = 0
					this.queryData.recommend = 1
				}
				this.queryData.page = 1
				this.getData();
			}
		},
		mounted() {
			this.getData();
			this.getClass();
			//监听切换分类
			this.$EventBus.$on('changeIds', (data, name) => {
				this.categoryName = name
				this.queryData.category_id = data
				this.queryData.page = 1
				this.getData()
			})
		},
		destroyed() {
			this.$EventBus.$off("changeIds")
		},
		methods: {
			goInfo(item){
				let arr = [{
						name: "文章阅读",
						url: "/ArticleHome"
					},
					{
						name: item.title,
						url: ""
					},
				];
				// this.$store.commit("iscollection", item.is_collect);
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "ArticleInfo",
					query: {
						id:item.id
					}
				});
			},
			handleSizeChange(val) {
				this.queryData.perpage = val;
				this.queryData.page = 1
				this.getData();
			},
			handleCurrentChange(val) {
				this.queryData.page = val;
				this.getData();
			},
			getData() {
				this.loadingData = true
				let data = this.queryData
				queryArticleList(data).then(res => {
					res.data.list.forEach(val => {
						val.showMore = true
					})
					this.dataList = res.data.list
					this.total = res.data.total
					this.loadingData = false
				})
			},
			getClass() {
				queryArticleCategoryList({
					user_type: 1
				}).then(res => {
					res.data.list.unshift({
						name: '全部文章',
						id: ''
					})
					this.$store.commit('SET_CLASS_ARR', res.data.list)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		width: calc(100% - 390px);
		max-width: calc(100% - 390px);
		background-color: #fff;
		padding: 30px;
		border-radius: 6px;
		box-sizing: border-box;

		.top {
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 12px;

			.topTit {
				:first-child {
					font-size: 26px;
					color: #4B4B65;
					line-height: 38px;
				}

				:last-child {
					margin-left: 20px;
					font-size: 14px;
					color: #717083;
					line-height: 19px;
				}
			}

			.topRight {
				font-size: 16px;
				color: #4B4B65;
				line-height: 22px;

				.activeType {
					color: #5473E8;
				}

				div {
					cursor: pointer;
				}

				.line {
					cursor: default;
					width: 1px;
					height: 16px;
					background-color: #eeeeee;
					margin: 0 10px;
				}
			}
		}

		.qaList {
			.qaOne {
				padding: 20px 0;
				.conT{
					font-size: 20px;
					font-weight: 600;
					color: #252631;
					line-height: 28px;
				}
				.conM{
					font-size: 14px;
					font-weight: 300;
					color: #717083;
					line-height: 20px;
					height: 60px;
				}
				.conB{
					font-size: 14px;
					font-weight: 400;
					color: #51516A;
					line-height: 20px;
					.operate{
						>div{
							margin-left: 8px;
							>span{
								margin-left: 4px;
							}
						}
					}
				}
			}
		}
	}
</style>
