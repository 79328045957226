<template>
	<div class="content">
		<div class="topPicList">
			<el-carousel v-if="bannerList.length" style="border-radius: 6px" trigger="click" height="320px" :interval="5000">
				<el-carousel-item v-for="(item, index) in bannerList" :key="index">
					<el-image  @click="bannerFunc(item)" style="width: 100%; height: 100%" :src="item.image" fit="cover">
					</el-image>
				</el-carousel-item>
			</el-carousel>

			<div class="classification" :style="{marginTop:bannerList.length?'20px':'10px'}">
				<div class="btn left" @click="nextScroll(0)">
					<i class="el-icon-arrow-left"></i>
				</div>
				<div class="classList display">
					<div v-for="(item, index) in classList" :key="index"
						:class="['classOne', activeName == index ? 'active' : '']" @click="handleClick(index, item.id)">
						{{ item.name }}
					</div>
				</div>
				<div class="btn right" @click="nextScroll(1)">
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>
		</div>

		<div class="mainCon" v-loading="loading">
			<div class="title" v-if="!loading">
				{{ classList[activeName].name }}分类
			</div>
			<div class="subtit">走过不要错过，你身上的秘密都在这里</div>

			<div class="btn display">
				<div class="btn1" @click="getfollowList">
					<i class="el-icon-star-off"></i>
					<div class="btnfont" style="">关注</div>
				</div>

				<el-dropdown @command="changeSort">
					<div class="btn1">
						<i class="el-icon-s-fold"></i>
						<div class="btnfont">
							{{ sortList[select - 1].name.slice(0, 2) }}
						</div>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item v-for="(item, index) in sortList" :key="index" :command="item.val">
							{{ item.name }}
						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>

			<div class="screen">
				<div class="screenL">

				</div>
				<div class="screenR">
					<div class="" @click="dialogTableVisible=true" v-if="false">
						<i class="el-icon-location-outline" style="margin-right: 10px;"></i>
						<span>{{city||'城市'}}</span>
					</div>
					<div class="" style="margin: 0 30px;" @click="dialogTableVisible1 = true">
						<i class="el-icon-location-outline" style="margin-right: 10px;"></i>
						<span>{{price ?price+'元':'选择价格'}}</span>
					</div>
					<div class="">
						<el-checkbox v-model="checked">仅看在线</el-checkbox>
					</div>
				</div>
			</div>

			<div class="testList">
				<el-row :gutter="16">
					<el-col :span="8" v-for="(item, index) in testList" :key="index">
						<div class="testOne" @click="goInfo(item.id, item.nickname)">
							<div class="subTit u-line-1">
								<iconpark-icon style="color: #FF7695;" v-if="item.gender==0" name="female">
								</iconpark-icon>
								<iconpark-icon style="color: #6495ED;" v-else name="male"></iconpark-icon>
								<span style="margin-left: 3px;">{{item.qualification}}</span>
							</div>
							<div style="width: 100%; height: 170px;overflow: hidden;">
								<el-image style="height: 170px;width: 100%;" :src="item.avatar" fit="cover"
									class="imgBig" alt='图片'></el-image>
							</div>

							<div class="zxsInfo">
								<div class="infoTop">
									<span class="u-line-1">咨询师<span style="margin-left: 10px;">{{item.nickname}}</span></span>
									<div style="display: flex;align-items: center;">
										<div class="point"></div>
										<span class="infoSmall u-line-1">从业{{item.employed_age}}年</span>
									</div>
								</div>
								<div class="infoBot u-flex" v-if="item.good_field">
									<div class="u-flex-1 u-line-1">
										<span>擅长：</span>
										<span class="infoBotSub" v-for="(item,index) in item.good_field" v-if="index<3" :key="index">
											{{item}}
										</span>
									</div>
								</div>
							</div>
						</div>
					</el-col>

					<el-empty description="暂无咨询师～" v-if="!testList.length"></el-empty>
				</el-row>

				<!-- 分页 -->
				<el-pagination style="text-align: center" @size-change="handleSizeChange" v-if="!isFollow"
					@current-change="handleCurrentChange" :current-page.sync="page" :page-size="9"
					:page-sizes="[9, 30, 60, 90]" layout="total, sizes, prev, pager, next, jumper" :total="totalNum">
				</el-pagination>
			</div>
		</div>


		<!-- 选择价格 -->
		<el-dialog title="选择价格范围" :visible.sync="dialogTableVisible1" width="40%">
			<div style="display: flex;align-items: center;justify-content: flex-start;">
				<div style="margin-bottom: 22px;margin-right: 10px;">价格区间</div>
				<el-form style="display: flex;align-items: center;justify-content: start;width: 60%;" ref="form"
					:rules="rules" :model="form">
					<el-form-item prop="min">
						<el-input v-model="form.min" maxlength="8" placeholder="最低价" />
					</el-form-item>
					<span style="margin: 0 10px 22px;">~</span>
					<el-form-item prop="max">
						<el-input v-model="form.max" maxlength="8" placeholder="最高价" />
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="reset">重 置</el-button>
				<el-button type="primary" @click="choosePrice">确 定</el-button>
			</span>
		</el-dialog>

		<!-- 选择地址 -->
		<el-dialog title="选择地址" :visible.sync="dialogTableVisible" width="30%">
			<el-cascader size="large" :options="options" v-model="selectedOptions" @change="handleChange"
				:clearable="true">
			</el-cascader>
		</el-dialog>
	</div>
</template>

<script>
	import {
		GetCategoryList,
		GetTestList,
		GetUserCollection,
		GetMental,
		GetSlideshow,
		
		GetUserIndex,
		myFollow,
		consultantIndexList
	} from "@/api/api.js";

	import {
		provinceAndCityData,
		CodeToText
	} from 'element-china-area-data'
	export default {
		name: "TestHome",
		data() {
			var checkAge = (rule, value, callback) => {
				let reg = /^[1-9]\d*(,\d{3})*(\.\d{1,2})?$|^0\.\d{1,2}$/
				if(!reg.test(value)){
					callback(new Error('请输入正确金额'));
				}else{
					callback();
				}
			};
			return {
				activeName: 0, //当前激活项
				classList: [], //分类列表
				testList: [], //题目列表
				loading: true, //加载
				page: 1, //当前页码
				bannerList: [], //banner列表
				totalNum: 0,
				limit: 9,
				sortList: [{
						name: "综合排序",
						val: 1,
					},
					{
						name: "评分由高到低",
						val: 2,
					},
					{
						name: "咨询量由高到低",
						val: 3,
					},
					{
						name: "价格由高到低",
						val: 4,
					},
				],
				select: 1,
				checked: false,
				options: provinceAndCityData,
				selectedOptions: [],
				dialogTableVisible: false,
				dialogTableVisible1: false,
				city: '',
				isFollow: false,
				form: {
					min: '',
					max: ''
				},
				price: '',
				good_field:'',
				rules: {
					min: [{
						validator: checkAge,
						trigger: 'blur'
					}],
					max: [{
						validator: checkAge,
						trigger: 'blur'
					}]
				},
			};
		},
		filters: {
			dateformat(time) {
				const date = new Date(time);
				const year = date.getFullYear();
				const month = (date.getMonth() + 1 + "").padStart(2, "0");
				const day = (date.getDay() + "").padStart(2, "0");
				//   const hour = (date.getHours() + "").padStart(2, "0");
				//   const minute = (date.getMinutes() + "").padStart(2, "0");
				//   const second = (date.getSeconds() + "").padStart(2, "0");
				//   return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
				return `${year}.${month}.${day}`;
			},
			dateformat1(time) {
				let arr = time.split(" ")[0].split("-");
				return arr[0] + "." + arr[1] + "." + arr[2];
			},
		},
		watch:{
			checked(val){
				this.page = 1
				this.gettestList()
			}
		},
		created() {
			this.gettestList()
			GetSlideshow({
				type: 3,
				place: 2
			}).then((res) => {
				this.bannerList = res.data.list;
			});
		},
		methods: {
			reset(){
				this.form = {
					min:'',
					max:''
				}
				this.price = ''
				this.dialogTableVisible1 = false
				this.page = 1
				this.gettestList()
			},
			//数组乱序
			randomArray(array = []) {
				// 原理是sort排序,Math.random()产生0<= x < 1之间的数,会导致x-0.05大于或者小于0
				return array.sort(() => Math.random() - 0.5);
			},
			choosePrice() {
				let reg = /^[1-9]\d*(,\d{3})*(\.\d{1,2})?$|^0\.\d{1,2}$/
				if(Number(this.form.min)===0&&Number(this.form.max)===0){
					this.reset()
					return
				}
				if( reg.test(this.form.min) && reg.test(this.form.max) ){
					if(Number(this.form.min)>Number(this.form.max)){
						let a = 0
						a = this.form.min
						this.form.min = this.form.max
						this.form.max = a
					}
					this.price = this.form.min+'-'+this.form.max
					this.dialogTableVisible1 = false
					this.page = 1
					this.gettestList()
				}else{
					this.$message.error('请输入正确金额')
				}
			},
			handleChange(e) {
				let city = ''
				if (city = CodeToText[e[1]] == '市辖区') {
					city = CodeToText[e[0]]
				} else {
					city = CodeToText[e[1]]
				}
				this.city = city
				this.dialogTableVisible = false
			},
			goUrl(item) {
				if (item.link_type == 2) {
					//测试题链接
					this.goInfo1(item.link)
				} else if (item.link_type == 3) {
					window.open(item.link, '_blank')
				} else if (item.link_type == 1) {
					//文章链接
				}
			},
			getfollowList() {
				if (this.$store.state.token) {
					this.page = 1;
					this.activeName = 0;
					this.totalNum = this.classList[0].num;
					this.loading = true;
					myFollow().then((res) => {
						this.isFollow = true
						this.loading = false;
						this.testList = res.data.list;
					});
				} else {
					this.goLogin();
				}
			},
			goLogin() {
				this.$router.push({
					name: "LoginPage",
					query: {
						back: true
					}
				});
			},
			goInfo(id, title) {
				let arr = [{
						name: "心理咨询",
						url: "/ZxsHome"
					},
					{
						name: title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "ZxsInfo",
					query: {
						id
					}
				});
			},
			goInfo1(id, title) {
				let arr = [{
						name: "心理测试",
						url: "/TestHome"
					},
					{
						name: title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit('changeId', 0)
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "TestInfo",
					query: {
						id
					}
				});
			},
			changeSort(val) {
				this.select = val;
				this.gettestList();
			},
			handleSizeChange(val) {
				this.limit = val;
				this.gettestList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.gettestList();
			},
			handleClick(index,item) {
				this.page = 1;
				this.good_field = item;
				this.activeName = index;
				this.totalNum = this.classList[index].num;
				this.gettestList();
			},
			nextScroll(type) {
				if (type) {
					let box = this.$el.querySelector(".classList");
					box.scrollLeft += 200;
				} else {
					let box = this.$el.querySelector(".classList");
					box.scrollLeft -= 200;
				}
			},
			//获取测试列表
			gettestList() {
				this.loading = true;
				consultantIndexList({
					tsort:this.select,
					price:this.price,
					online:this.checked?1:'',
					good_field:this.good_field,
					page:this.page,
					limit:this.limit
				}).then(res=>{
					console.log(123123,res.data)
					this.classList = res.data.option;
					this.totalNum = res.data.total;
					this.testList = res.data.consultant;
					this.loading = false;
				})
			},
		},
		
	};
</script>

<style scoped lang="scss">
	::v-deep .el-checkbox__inner {
		border-radius: 50%;
	}

	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: #d3dce6;
	}

	::v-deep .classList::-webkit-scrollbar {
		display: none;
	}

	.classList {
		scrollbar-width: none;
	}

	.content {
		width: calc(100% - 390px);
		min-width: calc(100% - 390px);
		// width: 960px;
		// min-width: 960px;

		.topPicList {
			background-color: #fff;
			border-radius: 6px;
			overflow: hidden;
			transition: all 1s;
		}

		.classification {
			margin-top: 20px;
			padding: 0 34px;
			width: 100%;
			box-sizing: border-box;
			position: relative;
			transition: all 1s;

			&:hover .btn {
				opacity: 1;
			}

			.btn {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;
				font-size: 24px;
				cursor: pointer;
				background-color: rgba(0, 0, 0, 0.4);
				color: #ffffff;
				border-radius: 6px;
			}

			.left {
				left: 10px;
			}

			.right {
				right: 10px;
			}

			.classList {
				width: 100%;
				overflow-x: auto;

				.classOne {
					white-space: nowrap;
					padding: 20px;
					margin: 0 20px;
					cursor: pointer;
				}

				.active {
					background-color: #f7f7f7;
					border-radius: 6px 6px 0 0;
					font-size: 18px;
					font-weight: 600;
					color: #4b4b65;
					line-height: 25px;
					position: relative;

					&::after {
						content: "";
						width: 15px;
						height: 3px;
						background-color: #4b4b65;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
		}

		.mainCon {
			margin-top: 30px;
			background-color: #fff;
			border-radius: 6px;
			padding: 20px;

			.title {
				text-align: center;
				font-size: 24px;
				font-weight: 600;
				color: #4b4b65;
				line-height: 33px;
			}

			.subtit {
				text-align: center;
				font-size: 14px;
				font-weight: 400;
				color: #c0c4cc;
				line-height: 20px;
				margin-top: 10px;
			}

			.btn {
				text-align: center;
				justify-content: center;
				margin-bottom: 30px;

				.btn1 {
					width: 36px;
					height: 36px;
					line-height: 36px;
					background: #f7f7f7;
					border-radius: 6px;
					margin: 10px 15px 0;

					.btnfont {
						font-size: 12px;
						color: #c0c4cc;
						line-height: 17px;
						margin-top: 5px;
					}
				}
			}

			.screen {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				width: 100%;
				padding: 20px 0;
				border-bottom: 1px solid #f4f4f4;
				margin-bottom: 30px;

				.screenR {
					display: flex;
					align-items: center;
					justify-content: flex-end;
					font-size: 14px;
					color: #606266;
					font-weight: 500;
				}
			}

			.testList {

				.testOne {
					// width: 280px;
					width: 100%;
					border-radius: 6px;
					overflow: hidden;
					border: 1px solid #eaeef3;
					margin-bottom: 16px;
					position: relative;

					.subTit {
						position: absolute;
						left: 0;
						top: 0;
						z-index: 3;
						padding: 7px 10px;
						background-color: rgba(243, 228, 228, 0.4);
						border-radius: 0 0 8px 0;

						font-size: 10px;
						font-weight: 400;
						color: #4B4B65;
						line-height: 14px;
					}

					.imgBig:hover {
						transition: all 0.7s;
						// transform: scale(1.5,1.5);
					}
				}

				.testinfo {
					padding: 20px;

					.infotop {
						font-size: 16px;
						line-height: 22px;
						color: #4b4b65;
						justify-content: space-between;
						font-weight: 600;

						.testName {
							width: 190px;
						}

						.testPrice {
							font-size: 16px;
							font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
							font-weight: 800;
							color: #f04352;
							line-height: 24px;
						}

						.testFree {
							color: #5473e8;
						}
					}

					.infomiddle {
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #b6b5c5;
						line-height: 17px;
						margin: 10px 0 23px;
					}

					.infobottom {
						color: #c0c4cc;
						font-size: 12px;
						line-height: 17px;
					}
				}

				.zxsInfo {
					padding: 10px;

					.infoTop {
						display: flex;
						align-items: center;
						justify-content: space-between;
						font-size: 18px;
						font-weight: 600;
						color: #4B4B65;
						line-height: 25px;

						.point {
							width: 3px;
							height: 3px;
							border-radius: 50%;
							background-color: #EC977A;
							margin-right: 5px;
						}

						.infoSmall {
							font-size: 12px;
							font-weight: 400;
							color: #717083;
							line-height: 17px;
						}
					}

					.infoBot {
						margin-top: 10px;
						max-width: 238px;
						height: 30px;
						border: 1px solid #F4F4F4;
						font-size: 12px;
						font-weight: 400;
						color: #B6B5C5;
						line-height: 30px;
						padding-left: 6px;
						box-sizing: border-box;
						display: flex;
						.infoBotSub{
							&:not(:last-child)::after{
								content: '、';
								font-size: 10px;
							}
						}
					}
				}
			}
		}
	}
</style>
