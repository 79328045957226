<template>
	<div class="content" v-loading="loadingData">
		<div class="top u-flex u-row-between">
			<div class="topTit">
				<span>{{categoryName}}</span>
				<span>在知识的海洋中穿梭</span>
			</div>
			<div class="topRight u-flex">
				<div :class="[!changeType?'activeType':'']" @click="changeType = 0">推荐问答</div>
				<div class="line"></div>
				<div :class="[changeType?'activeType':'']" @click="changeType = 1">悬赏专区</div>
			</div>
		</div>

		<div class="qaList">
			<div class="qaOne u-flex u-col-top u-row-between" v-for="(item,index) in dataList" :key="index"
				@click="goInfo(item)">
				<el-avatar style="flex: 0 0 36px;" :size="36" :src="item.user_avatar"></el-avatar>
				<div class="u-m-l-10 u-flex-1">
					<div class="u-flex">
						<div class="topInfo u-flex u-m-t-6 u-flex-1">
							<div class="">{{item.user_name}}</div>
							<div class="">{{item.create_date.split(' ')[0].split('-').join('.')}}发布问题</div>
						</div>
						<div class="more u-flex u-row-center" @click.stop="item.showMore = !item.showMore">
							<i class="el-icon-arrow-up" style="transition: all 0.6s;"
								:class="[item.showMore?'':'rotate']"></i>
						</div>
					</div>

					<div class="qaBox">
						<div class="qaTop u-flex u-row-left">
							<div class="u-line-1 u-flex u-flex-1" style="width: 0;">
								<i class="el-icon-chat-line-round u-m-r-4"></i>
								<div class="u-line-1">{{item.title}}</div>
								<div class="reward u-flex" v-if="item.reward_money>0">
									<iconpark-icon name="financing-one"></iconpark-icon>
									<div class="u-m-l-4">悬赏{{item.reward_money}}元</div>
								</div>
							</div>

							<div class="category u-flex u-m-l-6" style="margin: 0;" v-if="!item.showMore">
								#{{ item.category_names[0] }}
							</div>
						</div>
						<div class="qaCon u-line-3" v-if="item.showMore">
							{{item.content}}
						</div>

						<div class="qaBot u-flex u-m-t-10 u-col-top" v-if="item.showMore">
							<div class="u-flex" style="flex-wrap: wrap;flex: 1;">
								<div class="category u-flex u-m-r-6 pointer" v-for="(v,i) in item.category_names"
									:key="i" @click.stop="goClass(item,v,i)">
									#{{ v }}
								</div>
							</div>
							<div class="u-flex u-m-l-8" style="color: #717083;font-size: 12px;line-height: 22px;">
								<div class="">
									<i class="el-icon-view u-m-r-4"></i>
									<span>共{{item.read_num}}次查看</span>
								</div>
								<div class="line u-m-l-6 u-m-r-6"
									style="width: 1px;height: 10px;background-color: #717083;"></div>
								<div class="">当前{{item.answer_num+item.comment_num}}人参与</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<el-empty description="暂无信息" v-if="!dataList.length"></el-empty>
			<!-- 分页 -->
			<div style="width: 100%;overflow: auto;">
				<el-pagination style="text-align: center;margin-top: 20px;width: 100%;" @size-change="handleSizeChange"
					:pager-count="5" @current-change="handleCurrentChange" :current-page.sync="queryData.page"
					:page-size="9" :page-sizes="[5,10,20,30]" layout="total, sizes, prev, pager, next, jumper"
					:total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		queryQuestionsList,
		queryQuestionsCategoryList
	} from "@/api/api.js";
	export default {
		data() {
			return {
				categoryName: '全部问答',
				changeType: 0,
				dataList: [],
				total: 0,
				queryData: {
					page: 1,
					perpage: 5,
					category_ids: '',
					// recommend: 1,
					reward: 0
				},
				loadingData: true,
			}
		},
		computed: {
			classArr() {
				return this.$store.state.classArr
			}
		},
		watch: {
			changeType(val) {
				if (val) {
					this.queryData.reward = 1
					this.queryData.recommend = 0
				} else {
					this.queryData.reward = 0
					this.queryData.recommend = 1
				}
				this.queryData.page = 1
				this.getData();
			}
		},
		mounted() {
			this.getData();
			this.getClass();
			//监听切换分类
			this.$EventBus.$on('changeIds', (data, name) => {
				this.categoryName = name
				this.queryData.category_ids = data
				this.queryData.page = 1
				this.getData()
			})
		},
		destroyed() {
			this.$EventBus.$off("changeIds")
		},
		methods: {
			goClass(item, val, index) {
				console.log(item, val, index)
			},
			goInfo(item) {
				let arr = [{
						name: "求助问答",
						url: "/QaHome"
					},
					{
						name: item.title,
						url: ""
					},
				];
				// this.$store.commit("iscollection", item.is_collect);
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "QaInfo",
					query: {
						id: item.id
					}
				});
			},
			handleSizeChange(val) {
				this.queryData.perpage = val;
				this.queryData.page = 1
				this.getData();
			},
			handleCurrentChange(val) {
				this.queryData.page = val;
				this.getData();
			},
			getData() {
				this.loadingData = true
				let data = this.queryData
				queryQuestionsList(data).then(res => {
					res.data.list.forEach(val => {
						val.showMore = true
					})
					this.dataList = res.data.list
					this.total = res.data.total
					this.loadingData = false
				})
			},
			getClass() {
				queryQuestionsCategoryList({
					user_type: 1
				}).then(res => {
					res.data.list.unshift({
						name: '全部问答',
						id: ''
					})
					this.$store.commit('SET_CLASS_ARR', res.data.list)
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		width: calc(100% - 390px);
		min-width: calc(100% - 390px);
		background-color: #fff;
		padding: 30px;
		border-radius: 6px;
		box-sizing: border-box;

		.top {
			border-bottom: 1px solid #eeeeee;
			padding-bottom: 12px;

			.topTit {
				:first-child {
					font-size: 26px;
					color: #4B4B65;
					line-height: 38px;
				}

				:last-child {
					margin-left: 20px;
					font-size: 14px;
					color: #717083;
					line-height: 19px;
				}
			}

			.topRight {
				font-size: 16px;
				color: #4B4B65;
				line-height: 22px;

				.activeType {
					color: #5473E8;
				}

				div {
					cursor: pointer;
				}

				.line {
					cursor: default;
					width: 1px;
					height: 16px;
					background-color: #eeeeee;
					margin: 0 10px;
				}
			}
		}

		.qaList {
			.qaOne {
				padding: 20px 0;

				.topInfo {
					font-size: 16px;
					font-weight: 600;
					color: #4B4B65;
					line-height: 22px;

					:last-child {
						margin-left: 6px;
						font-size: 14px;
						font-weight: 400;
						color: #C0C4CC;
						line-height: 20px;
					}
				}

				.more {
					cursor: pointer;
					font-size: 12px;
					width: 14px;
					height: 14px;
					border: 1px solid #c0c4cc;
					color: #c0c4cc;
				}

				.rotate {
					transform: rotate(180deg);
				}

				.category {
					background-color: #EEEEEE;
					padding: 4px 12px;
					border-radius: 17px;
					font-size: 12px;
					color: #4B4B65;
					line-height: 14px;
					margin-bottom: 10px;
					box-sizing: content-box;
				}

				.qaBox {
					background-color: #FAFAFA;
					width: 100%;
					padding: 20px;
					box-sizing: border-box;
					margin-top: 20px;
					border-radius: 3px;

					.qaTop {
						font-size: 16px;
						font-weight: 600;
						color: #252631;
						line-height: 25px;

						.reward {
							padding: 2px 6px;
							background: rgba(236, 151, 122, 0.1);
							border-radius: 1px 8px 1px 8px;
							border: 1px solid #EC977A;
							margin-left: 10px;
							font-size: 12px;
							font-weight: 400;
							color: #EC977A;
							line-height: 17px;
							white-space: nowrap;
						}
					}

					.qaCon {
						margin-top: 10px;
						font-size: 12px;
						font-weight: 400;
						color: #908F9D;
						line-height: 22px;
					}

					.qaBot {}
				}
			}
		}
	}
</style>