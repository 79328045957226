<template>
	<div class="content11 display">
		<div class="logo">
			<div class="LogoTop">
				<img class="middle" style="width: 121px; height: 40px" src="../../assets/image/bottomLogo.png" />
				<!-- <span class="middle" style="margin-left:10px;line-height:40px;font-size:36px;font-weight:blod;color:#ffffff;">慢慢</span> -->
			</div>
			<div class="LogoBottom">每个人都值得被听见</div>
			<!-- <div class="LogoBottom">慢慢陪伴你</div> -->
		</div>
		<div class="customer">
			<div class="botFont">服务热线：（周一至周日 8:00 - 18:00）</div>
			<div class="tipFont">010-67793819</div>
			<!-- <div class="cusBtn display">
        <div class="">
          <i class="el-icon-headset"></i>
          在线客服1
        </div>
        <div class="">
          <i class="el-icon-headset"></i>
          在线客服2
        </div>
      </div> -->
			<div class="botFont" style="margin-top: 30px">
				问题反馈：（8小时内急速响应）
			</div>
			<div class="tipFont">contact@manmanxinli.com</div>

			<!-- <el-button style="margin-top: 10px" type="primary"
        ><i class="el-icon-edit"></i
        ><span style="margin-left: 5px">线上反馈</span></el-button
      > -->
		</div>
		<div class="aboutus">
			<div class="tit">关于</div>
			<div class="aboutList" v-for="(item, index) in list" :key="index"
				:style="{ opacity: index > 2 ? '0' : '1' }" @click="goPage(item.url)">
				{{ item.name }}
			</div>
		</div>
		<div class="followus display">
			<div class="followOne" style="margin-right: 50px">
				<div class="followTit">关注我们</div>
				<div class="qrcode">
					<img src="../../assets/image/wechatCode.png" alt="" />
				</div>
				<div class="qrTit">
					<div class="" style="font-size: 16px; line-height: 22px; margin-top: 10px">
						公众号
					</div>
					<div class="" style="font-size: 12px; line-height: 17px; margin-top: 5px">
						免费咨询，随时接待
					</div>
				</div>
				<div class="goodTip">
					<img style="width: 36px; height: 22px" src="../../assets/image/bottom3.png" alt="" />
					<span>7天无忧退款</span>
				</div>
			</div>

			<div class="followOne" style="margin-right: 50px">
				<div class="followTit" style="opacity: 0">关注我们</div>
				<div class="qrcode">
					<img src="../../assets/image/miniWechat.png" alt="" />
				</div>
				<div class="qrTit">
					<div class="" style="font-size: 16px; line-height: 22px; margin-top: 10px">
						小程序
					</div>
					<div class="" style="font-size: 12px; line-height: 17px; margin-top: 5px">
						合作渠道，强强联合
					</div>
				</div>
				<div class="goodTip">
					<img style="width: 39px; height: 22px" src="../../assets/image/bottom2.png" alt="" />
					<span>品质保障</span>
				</div>
			</div>

			<div class="followOne">
				<div class="followTit">APP下载</div>
				<div class="qrcode">
					<img src="../../assets/image/appDown.png" alt="" />
				</div>
				<div class="qrTit">
					<div class="" style="font-size: 16px; line-height: 22px; margin-top: 10px">
						扫码下载APK
					</div>
					<div class="" style="font-size: 12px; line-height: 17px; margin-top: 5px">
						一键查看移动效果
					</div>
				</div>
				<div class="goodTip">
					<img style="width: 32px; height: 22px" src="../../assets/image/bottom1.png" alt="" />
					<span>新客免费咨询</span>
				</div>
			</div>
		</div>

		<div class="fixed">
			<a target="_blank" href="http://beian.miit.gov.cn" style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        ">
				<p style="
            float: left;
            height: 26px;
            line-height: 26px;
            margin: 0px 0px 0px 5px;
            color: #939393;
          ">
					<img src="../../assets/image/gh.png" alt="">
					京公网安备
					11010502047884号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;京ICP备2021033378号
				</p>
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: "NavBar",
		props: {
			activeId: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				list: [{
						name: "关于我们",
						url: "AboutPage",
					},
					{
						name: "帮助中心",
						url: "HelpCenter",
					},
					{
						name: "咨询师web端",
						url: "ZxsWeb"
					},
					// {
					//   name: "商务合作",
					//   url: "",
					// },
					// {
					//   name: "开放平台",
					//   url: "",
					// },
					// {
					//   name: "推荐搜索",
					//   url: "",
					// },
					// {
					//   name: "友情链接",
					//   url: "",
					// },
				],
			};
		},
		methods: {
			goPage(url) {
				if (url == 'ZxsWeb') {
					window.open('https://teacher.manmanxinli.com/zxsIframe', '_blank');
					return
				}
				if (url != "") {
					let arr = [{
							name: "首页",
							url: "/AllHome"
						},
						{
							name: "关于我们",
							url: ""
						},
					];
					localStorage.setItem("route", JSON.stringify(arr));
					this.$store.commit("newRoute", arr);
					this.$router.push({
						name: url
					});
				}
				if (url == "HelpCenter") {
					if (this.$store.state.token) {
						let arr = [{
								name: "首页",
								url: "/AllHome"
							},
							{
								name: "个人中心",
								url: ""
							},
						];
						localStorage.setItem("route", JSON.stringify(arr));
						this.$store.commit("newRoute", arr);
						this.$router.push({
							name: url,
							query: {
								title: "帮助中心"
							}
						});
					} else {
						this.$router.push({
							name: "LoginPage"
						});
					}
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.content11 {
		margin-top: 100px;
		background-color: #101319;
		width: 100%;
		// min-width: 1390px;
		// min-width: 1500px;
		// height: 438px;
		justify-content: center;
		align-items: flex-start;
		position: relative;
		z-index: 4;
		flex-wrap: wrap;
		padding: 60px 0;

		.logo {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			.LogoBottom {
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
				line-height: 20px;
				margin-top: 10px;
			}
		}

		.customer {
			margin-left: 103px;

			.cusBtn {
				font-size: 14px;
				margin-top: 10px;

				div {
					background: #ededed;
					padding: 8px 10px;
					border-radius: 6px;
					margin-right: 20px;
				}
			}
		}

		.aboutus {
			margin-left: 95px;

			.tit {
				font-size: 16px;
				font-weight: 600;
				color: #ffffff;
				line-height: 22px;
			}

			.aboutList {
				font-size: 16px;
				font-weight: 400;
				color: #7f8287;
				line-height: 22px;
				margin-top: 22px;
				cursor: pointer;
			}
		}

		.followus {
			margin-left: 96px;

			.followOne {
				.followTit {
					font-size: 16px;
					font-weight: 600;
					color: #ffffff;
					line-height: 22px;
				}

				.qrcode {
					margin-top: 20px;
					width: 110px;
					height: 110px;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.qrTit {
					width: 110px;
					text-align: center;
					color: #7f8287;
					text-align: center;
				}

				.goodTip {
					margin-top: 50px;
					font-size: 16px;
					font-weight: bold;
					color: #c6d6e8;
					line-height: 24px;

					img {
						width: 20px;
						height: 20px;
					}
				}
			}
		}
	}

	.botFont {
		color: #7f8287;
		font-size: 16px;
		line-height: 22px;
		font-weight: 400;
	}

	.tipFont {
		font-size: 30px;
		font-weight: 600;
		color: #ffffff;
		line-height: 50px;
		margin-top: 10px;
	}

	::v-deep .el-button--primary {
		background-color: #5473e8;
		border-color: #5473e8;
	}

	.fixed {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 15px;
		font-size: 16px;
		font-weight: 400;
		color: #3d3d40;
		line-height: 22px;
		width: 100%;
		text-align: center;
	}
</style>
