<template>
	<div id="app" v-loading="loading">
		<router-view></router-view>
		<!-- <el-backtop></el-backtop> -->
		<!-- 回到顶部 -->
		<el-backtop :bottom="200">
			<div class="backTop">
				<i class="el-icon-download" style="transform: rotate(180deg);"></i>
			</div>
		</el-backtop>
		<!-- APP下载 -->
		<el-backtop :bottom="150">
			<div class="backTop" @click.stop="downLoad">
				<i class="el-icon-mobile-phone"></i>
			</div>
		</el-backtop>
		<!-- 聊天列表 -->
		<el-backtop :bottom="100">
			<div class="backTop" @click.stop="goChat(false)">
				<el-badge :is-dot="messageInfo?messageInfo.noread_sum>0:false" style="width: 90%;height: 100%;">
					<i class="el-icon-chat-dot-round"></i>
				</el-badge>
			</div>
		</el-backtop>
		<!-- 客服 -->
		<div class="backTop backTop1 u-flex u-row-center pointer" @click="goCus">
			<el-image style="width: 18px;height: 18px;" :src="require('@/assets/image/kefu.png')"></el-image>
		</div>

		<el-dialog title="提示" :visible.sync="downLoadApp" width="30%">
			<div>
				<div style="text-align: center;margin-bottom: 20px;font-size: 18px;font-weight: 700;">
					请扫描二维码下载APP
				</div>
				<div style="display: flex;align-items: center;justify-content: center;">
					<img style="width: 100%;" :src="require('@/assets/image/downLoadPop.png')" alt="">
				</div>
			</div>
		</el-dialog>

		<!-- 聊天列表 -->
		<div v-if="chatShow" v-dialogDrag>
			<div class="el-dialog diaBox" style="width: 450px;">
				<div class="el-dialog__header u-flex u-row-between">
					<div>{{`沟通列表 (${messageInfo?messageInfo.list.length:0})`}}</div>
					<div style="cursor: pointer;"><i class="el-icon-close" @click.self="chatShow = false"></i></div>
				</div>
				<div class="">
					<chat-list v-if="messageInfo" :messageInfo="messageInfo" @openChat="openChat"></chat-list>
				</div>
			</div>
		</div>

		<!-- 聊天窗口 -->
		<div v-if="chatBoxShow" v-dialogDrag>
			<div class="el-dialog diaBox" style="padding: 0;width: 800px;">
				<div class="el-dialog__header u-flex u-row-between">
					<div class="pointer" @click="closeWindow">
						<i class="el-icon-arrow-left"></i>
						<span>沟通列表</span>
					</div>
					<div style="cursor: pointer;"><i class="el-icon-close" @click.self="chatBoxShow = false"></i></div>
				</div>
				<div class="" v-if="activeInfo">
					<chat-window :activeInfo="activeInfo" @closeWindow="chatBoxShow = false"></chat-window>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		GetUserinfo,
		GetTokenState,
		chatListNew,
		presaleChatInfoNew
	} from "@/api/api.js";
	import ChatList from '@/components/ChatList/ChatList.vue'
	import ChatWindow from '@/components/ChatWindow/ChatWindow.vue'

	import {
		createSocket,
		sendWSPush,
		closeSocket
	} from '@/utils/websocket'

	let audio = new Audio(); //提示音
	audio.src = require('@/assets/mp3/y682.mp3');

	export default {
		name: "App",
		data() {
			return {
				downLoadApp: false, //下载弹窗显示控制
				chatShow: false, //聊天列表显示
				messageInfo: null, //聊天列表信息
				chatBoxShow: false, //聊天窗口显示

				activeInfo: null, //聊天对方信息

				loading: false,
				receiverid:'',
			}
		},
		components: {
			ChatList,
			ChatWindow
		},
		watch: {
			chatBoxShow(val) {
				console.log('chatBoxShow', val)
			},
			chatShow(val) {
				console.log('chatShow', val)
			},
		},
		mounted() {
			//设置cookie期限
			this.$cookies.config("30d");
			//获取面包屑路由
			let arr = localStorage.getItem("route");
			this.$store.commit("newRoute", JSON.parse(arr));
			//检查token，并获取用户信息
			let token = this.$store.state.token;
			if (token) {
				GetTokenState({
					token
				}).then((res) => {
					if (res.code == 1) {
						GetUserinfo({
							token
						}).then((res) => {
							this.$store.commit("setuserInfo", res.data.list);
						});
						this.goChat(true)
						//连接websocket
						createSocket();
						//监听websocket相关
						this.$EventBus.$on('onMessage', () => {
							window.removeEventListener('onmessageWS', this.getsocketData);
							window.addEventListener('onmessageWS', this.getsocketData);

							this.$EventBus.$once('closeSocket', () => {
								closeSocket()
								this.chatShow = false
								this.chatBoxShow = false
							})
						})
						this.$EventBus.$emit('onMessage')
					} else {
						this.$store.commit('logout')
					}
				});
			} else {
				this.$EventBus.$on('onMessage', () => {
					window.removeEventListener('onmessageWS', this.getsocketData);
					window.addEventListener('onmessageWS', this.getsocketData);

					this.$EventBus.$once('closeSocket', () => {
						closeSocket()
						this.chatShow = false
						this.chatBoxShow = false
					})
				})
			}
			
			this.$EventBus.$on('openWindow',this.openChat)
		},
		methods: {
			goCus(){
				if(this.$route.name == 'LoginPage'){
					this.$message.warning('请登录后操作!')
					return
				}
				if (!this.$store.state.token) {
					this.$router.push({
						name: "LoginPage",
						query: {
							back: true
						}
					});
				}else{
					this.openChat({
						chatid:this.receiverid,
						type:3
					})
				}
			},
			getCustomerInfo(){
				presaleChatInfoNew({chatid:this.$store.state.chatid}).then(res=>{
					console.log('助手信息',res)
					this.receiverid = res.data.receiverid
				})
			},
			closeWindow() {
				this.chatBoxShow = false
				this.goChat(false)
			},
			getsocketData(e) {
				const data = e && JSON.parse(e.detail.data)
				switch (data.type) {
					case 'init': //触发初始化
						let message = {
							type: 'bind',
							senderid: this.$store.state.chatid,
							source: 'WEB'
						}
						// 发送chatid
						sendWSPush(message)
						this.getCustomerInfo()
						break;
					case 'send': //触发收到消息
						audio.play(); //收到消息播放声音
						if (!this.chatBoxShow && !this.chatShow) { //打开列表或窗口不弹窗了
							let flag = this.isHTML(data.data.content)
							this.$notify({
								title: '收到消息',
								dangerouslyUseHTMLString: true,
								message: flag?data.data.content:'[消息信息]',
								duration: 3000
							});
						}
						if (this.chatShow) {
							this.goChat(true)
						}
						this.$EventBus.$emit('sensitive', data.data)
						if(this.messageInfo){
							this.messageInfo.noread_sum ++ ;
						}
						break;
					case 'refresh': // 刷新
						// this.getPrivateChatIndex(this.itemIndex, false, true);
						// this.$EventBus.$emit('refresh',data)
						break;
					case 'chat_refresh': // 刷新
						// this.getPrivateChatIndex(this.itemIndex, false, true);
						this.$EventBus.$emit('refresh',data)
						break;
				}
			},
			isHTML(str) {
			  var doc = new DOMParser().parseFromString(str, "text/html");
			  return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
			},
			openChat(item) {
				//打开 切换聊天窗口
				this.activeInfo = item
				this.chatBoxShow = true
				this.chatShow = false
			},
			downLoad() {
				//打开下载二维码
				this.downLoadApp = true
			},
			goChat(type) {
				if (!this.$store.state.token) {
					this.$router.push({
						name: "LoginPage",
						query: {
							back: true
						}
					});
					return
				}
				if (!type) {
					this.loading = true
				}
				//获取聊天列表
				chatListNew({
					type: 1
				}).then(res => {
					this.messageInfo = res.data
					if (!type) {
						this.chatShow = true
					}
					this.loading = false
				})
			},
		}
	};
</script>
<style lang="scss">
	.backTop {
		height: 100%;
		width: 100%;
		background-color: #f2f5f6;
		box-shadow: 0 0 6px rgba(0, 0, 0, .12);
		text-align: center;
		line-height: 40px;
		color: #4B4B65;
		border-radius: 10px;
	}

	.backTop1 {
		width: 40px;
		height: 40px;
		position: fixed;
		bottom: 50px;
		right: 40px;
		z-index: 99;
	}

	.diaBox {
		border-radius: 8px !important;
		// overflow: hidden;
		// width: 375px !important;
		position: fixed !important;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 99;
		padding: 10px 20px;
		// transform: translate(-50%, -50%);
	}
</style>
<style lang="scss">
	$font-color: #4b4b65;
	$font-size: 16px;
	$line-height: 24px;
	$tips-color: #c0c4cc;
	$background-color: #f7f7f7;

	#app {
		min-width: 900px;
	}

	@import "./assets/font/font.css";

	* {
		margin: 0;
		padding: 0;
	}

	body {
		background-color: $background-color;
		color: $font-color;
		cursor: default;
	}

	div {
		box-sizing: border-box;
	}

	.pointer {
		cursor: pointer;
	}

	.defaultFont {
		color: $font-color;
		font-size: $font-size;
		line-height: $line-height;
	}

	.display {
		display: flex;
		align-items: center;
	}

	.middle {
		vertical-align: middle;
	}

	.overflow {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}


	/* start--文本行数限制--start */
	.u-line-1 {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.u-line-2 {
		-webkit-line-clamp: 2;
	}

	.u-line-3 {
		-webkit-line-clamp: 3;
	}

	.u-line-4 {
		-webkit-line-clamp: 4;
	}

	.u-line-5 {
		-webkit-line-clamp: 5;
	}

	.u-line-2,
	.u-line-3,
	.u-line-4,
	.u-line-5 {
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box; // 弹性伸缩盒
		-webkit-box-orient: vertical; // 设置伸缩盒子元素排列方式
	}

	/* end--文本行数限制--end */


	.u-flex {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.u-flex-wrap {
		flex-wrap: wrap;
	}

	.u-flex-nowrap {
		flex-wrap: nowrap;
	}

	.u-col-center {
		align-items: center;
	}

	.u-col-top {
		align-items: flex-start;
	}

	.u-col-bottom {
		align-items: flex-end;
	}

	.u-row-center {
		justify-content: center;
	}

	.u-row-left {
		justify-content: flex-start;
	}

	.u-row-right {
		justify-content: flex-end;
	}

	.u-row-between {
		justify-content: space-between;
	}

	.u-row-around {
		justify-content: space-around;
	}

	.u-text-left {
		text-align: left;
	}

	.u-text-center {
		text-align: center;
	}

	.u-text-right {
		text-align: right;
	}

	.u-flex-col {
		/* #ifndef APP-NVUE */
		display: flex;
		/* #endif */
		flex-direction: column;
	}

	// 定义flex等分
	@for $i from 0 through 12 {
		.u-flex-#{$i} {
			flex: $i;
		}
	}

	// 定义内外边距，历遍1-80
	@for $i from 0 through 80 {

		// 只要双数和能被5除尽的数
		@if $i % 2==0 or $i % 5==0 {

			// 得出：u-margin-30或者u-m-30
			.u-margin-#{$i},
			.u-m-#{$i} {
				margin: $i + px !important;
			}

			// 得出：u-padding-30或者u-p-30
			.u-padding-#{$i},
			.u-p-#{$i} {
				padding: $i + px !important;
			}

			@each $short,
			$long in l left,
			t top,
			r right,
			b bottom {

				// 缩写版，结果如： u-m-l-30
				// 定义外边距
				.u-m-#{$short}-#{$i} {
					margin-#{$long}: $i + px !important;
				}

				// 定义内边距
				.u-p-#{$short}-#{$i} {
					padding-#{$long}: $i + px !important;
				}

				// 完整版，结果如：u-margin-left-30
				// 定义外边距
				.u-margin-#{$long}-#{$i} {
					margin-#{$long}: $i + px !important;
				}

				// 定义内边距
				.u-padding-#{$long}-#{$i} {
					padding-#{$long}: $i + px !important;
				}
			}
		}
	}
</style>