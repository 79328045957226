<template>
	<div class="content">
		<nav-bar :activeId="0" type="1" :iscollection="false"></nav-bar>
		<div class="mainApp display" v-if="pageInfo.consultant">
			<div class="zxsLeft">
				<div class="zxsInfo">
					<div class="infoPic"><el-image style="width: 100px;height: 100px;border-radius: 50%;"
							:src="pageInfo.consultant.thumbnail" fit="cover"
							:preview-src-list="[pageInfo.consultant.thumbnail]" alt="" /></el-image></div>
					<div class="info1">
						<iconpark-icon style="font-size: 16px;" name="protect"></iconpark-icon>
						<span>{{pageInfo.consultant.nickname}}</span>
						<span class="subTit">从业<span class="subTits">{{pageInfo.consultant.employed_age}}</span>年</span>
					</div>
					<div class="info2">
						<i class="el-icon-location"></i>
						<span style="margin-left: 5px;margin-right: 20px;">{{pageInfo.consultant.city}}</span>
						<span>{{pageInfo.consultant.qualification}}</span>
					</div>

					<div class="tags">
						<div class="" v-for="(item,index) in pageInfo.consultant.good_field" v-if="index<3">#{{item}}
						</div>
					</div>

					<div class="serveNum">
						<div class="serveOne">
							<div class="serveTop">
								{{pageInfo.consultant.favorable_rate}}%
							</div>
							<div class="serveBot">
								好评率
							</div>
						</div>

						<div class="serveOne">
							<div class="serveTop">
								{{pageInfo.consultant.inquiries || 0}}
							</div>
							<div class="serveBot">
								咨询量
							</div>
						</div>

						<div class="serveOne">
							<div class="serveTop">
								{{pageInfo.consultant.service_time>9999?'9999+':pageInfo.consultant.service_time}}H
							</div>
							<div class="serveBot">
								服务时长
							</div>
						</div>
					</div>

					<div class="u-flex u-row-between">
						<div class="likeBtn likeBtn1 pointer" @click="goChat">
							联系Ta
						</div>

						<div class="likeBtn pointer" @click="subject_handle">
							<iconpark-icon v-if="!follow" style="margin-right: 6px;font-weight: bolder;"
								name="star-530cc8fj"></iconpark-icon>
							<span v-if="!follow">关注</span>
							<span v-else>取消关注</span>
						</div>
					</div>

					<div class="complaint pointer" @click="complaint">投诉咨询师</div>
				</div>

				<div class="zxsImmediately" v-if="immedList.length">
					<div class="immeTit">立即咨询</div>

					<div class="immedList">
						<div class="immedOne" v-for="(item,index) in immedList" v-if="index<4" :key="index">
							<div class="immedL">
								<img style="width: 32px;height: 32px;" :src="item.image" alt="">
							</div>
							<div class="immedM">
								{{item.name}}
							</div>
							<div class="immedR">
								<span>￥</span>
								{{item.price}}
								<span>/{{item.consultation_time}}分钟</span>
							</div>
						</div>
					</div>

					<div class="immedBtn" @click="downLoadApp = true">立即咨询</div>

					<div class="immedTips">
						<div class="immedTip">
							<i class="el-icon-circle-check"></i>
							<span>保护安全隐私</span>
						</div>
						<div class="immedTip">
							<i class="el-icon-circle-check"></i>
							<span>支持退款</span>
						</div>
						<div class="immedTip">
							<i class="el-icon-circle-check"></i>
							<span>资料真实</span>
						</div>
						<div class="immedTip">
							<i class="el-icon-circle-check"></i>
							<span>严格审查</span>
						</div>
					</div>
				</div>

				<div class="zxsNotice">
					<div class="noticeTit">预约需知</div>

					<div class="noticeCon">
						<div class="subLeft">
							<img style="width: 14px;height: 14px;margin-top: 5px;" :src="picUrl+'book1.png'" alt="">
						</div>
						<div class="subRight">
							<div class="subRightT">预约时间</div>
							<div class="subRightB">
								咨询分为两种咨询服务，立即咨询：您可选择咨询师可约时间段预约下单，咨询时长由咨询师定义。订单支付成功之后，需咨询师确认是否接受咨询，确定接受咨询，您将收到“确认咨询”的信息通知，即可咨询(立即咨询音视频从您与咨询师通话的一刻起24小时后系统会自动结束订单，面对面咨询从咨询师接单以后24小时为咨询系统自动结束咨询)；预约咨询：您可选择咨询师可约时间段预约下单，咨询时长由咨询师定义。订单支付成功之后，需咨询师确认是否接受咨询，确定接受咨询，您将收到“确认咨询”的信息通知，需在预约时段进行咨询(预约咨询全部咨询方式在咨询时段失效以后系统会自动结束咨询)；
							</div>
						</div>
					</div>

					<div class="noticeCon">
						<div class="subLeft">
							<img style="width: 14px;height: 14px;margin-top: 5px;" :src="picUrl+'book2.png'" alt="">
						</div>
						<div class="subRight">
							<div class="subRightT">咨询方式</div>
							<div class="subRightB">
								平台咨询方式分为文字、电话、视频、面对面四种形式，具体订单的咨询形式依据咨询师展示的可选方式选择。
							</div>
						</div>
					</div>

					<div class="noticeCon">
						<div class="subLeft">
							<img style="width: 14px;height: 14px;margin-top: 5px;" :src="picUrl+'book3.png'" alt="">
						</div>
						<div class="subRight">
							<div class="subRightT">变更预约</div>
							<div class="subRightB">
								若因不可抗力因素，导致需要变更或取消已协商好的咨询预约，请提前24小时告知咨询师更改服务时间以免耽误后续预约时间。
							</div>
						</div>
					</div>
				</div>

				<div class="zxsInteraction" v-if="articleList.length || qaList.length">
					<div class="hdTit">Ta的互动</div>
					<div class="article" v-if="articleList.length">
						<div class="subTit">文章</div>
						<div class="articleList">
							<div class="articleOne poniter" v-for="(item,index) in articleList" :key="index"
								@click="goArticle(item)">
								<div class="artL">
									<el-image style="width: 100px; height: 100px;border-radius: 8px;" :src="item.image"
										fit="fill">
									</el-image>
								</div>
								<div class="artR">
									<div class="artInfo1">{{item.title}}</div>
									<div class="artInfo2">{{item.describe}}</div>
									<div class="artInfo3"><span v-for="(it,idx) in item.category_names" v-if="idx<3"
											:key="idx">#{{it}}<span
												v-if="idx!=item.category_names.length-1">、</span></span></div>
								</div>
							</div>
						</div>
					</div>
					<div class="question" v-if="qaList.length">
						<div class="subTit">咨询问答</div>
						<div class="qaList">
							<div class="qaOne pointer" :class="[index?'':'']" v-for="(item,index) in qaList"
								:key="index" @click="goQa(item)">
								<div class="qaT">
									<i class="el-icon-chat-dot-round"></i>
									{{item.title}}
								</div>
								<div class="qaM">
									{{item.content}}
								</div>
								<div class="qaB">
									<div class="qaBL">
										#{{item.category_names[0]}}
									</div>
									<div class="qaBR">
										{{item.create_date}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="zxsRight">
				<div class="tabsList">
					<div class="tabsOne" :class="[index==activeTab?'activeTab':'']" v-for="(item,index) in tabsList"
						v-if="index!=1 || evaluation.length" :key="index" @click="changeActive(index)">
						<span>{{item.title}}</span>
						<div class="evanum" v-if="index==1&&pageInfo.evaluation_count>0">{{pageInfo.evaluation_count}}
						</div>
					</div>
				</div>
				<div class="tabmain">
					<!-- <div class="tabTit">{{tabsList[activeTab].title}}</div> -->
					<div class="tabTit">
						基本信息
						<div id="tab0" style="position: absolute;top: -80px;"></div>
					</div>
					<!-- 基本信息 -->
					<div class="mainCon">
						<div class="line"></div>
						<div class="conFont">
							<img style="vertical-align: middle;display: inline-block;height: 20px;"
								:src="picUrl+'qualifications.png'" mode="heightFix" />
							<span style="vertical-align: middle;margin-left: 10px;">从业资质
							</span>
							<span style="font-size: 14px;color: #C0C4CC;">
								从业
								<span style="color:#EC977A;">{{pageInfo.consultant.employed_age}}</span>
								年
							</span>
							<div
								style="margin-left: 30px;font-size: 14px;color:#838A96 ;font-weight: 400;margin-top: 10px;">
								{{pageInfo.consultant.qualification}}
							</div>
						</div>

						<div class="conFont">
							<img style="vertical-align: middle;display: inline-block;height: 20px;"
								:src="picUrl+'field.png'" mode="heightFix" />
							<span style="vertical-align: middle;margin-left: 10px;">擅长领域
							</span>
							<div class="good_field_arr" style="margin-left: 30px;">
								<div class="good_field_arrOne"
									v-for="(item,index) in pageInfo.consultant.good_field_arr" :key="index">
									<div class="leftPic">
										<img style="width: 22px;height: 22px;" :src="item.img" alt="">
									</div>
									<div class="rightInfo">
										<div class="goodTit">
											{{item.name}}
										</div>
										<div class="goodInfo">
											{{item.details}}
										</div>
									</div>
								</div>
							</div>
						</div>


						<div class="conFont">
							<img style="vertical-align: middle;display: inline-block;height: 20px;"
								:src="picUrl+'personal.png'" mode="heightFix" />
							<span style="vertical-align: middle;margin-left: 10px;">咨询人群
							</span>
							<div class="consulting_crowd" style="margin-left: 30px;">
								<div class="consulting_crowdOne"
									v-for="(item,index) in pageInfo.consultant.consulting_crowd" :key="index">
									{{item}}
								</div>
							</div>
						</div>
					</div>
					<!-- 用户评价 -->
					<div class="tabTit" v-if="evaluation.length">
						用户评价
						<div id="tab1" style="position: absolute;top: -80px;"></div>
					</div>
					<div class="mainCon" v-if="evaluation.length">
						<div class="evaList">
							<div class="evaOne" v-for="(item,index) in evaluation" :key="index">
								<div class="evaPic">
									<el-avatar :size="50" :src="item.avatar"></el-avatar>
								</div>
								<div class="evaInfo">
									<div class="top">
										<span class="">{{item.username}}</span>
										<span class="subtop">{{item.createtime}}</span>
									</div>
									<div class="bottom">
										{{item.evaluation_content}}
									</div>
								</div>
							</div>
						</div>
						<div class="loadMore" @click="loadMore" v-if="!noMore">
							<span>加载更多</span>
							<div class="iconMore"><i class="el-icon-arrow-down"></i></div>
						</div>
					</div>
					<!-- 个人简介 -->
					<div class="tabTit">
						个人简介
						<div id="tab2" style="position: absolute;top: -80px;"></div>
					</div>
					<div class="mainCon">
						<div class="line"></div>
						<div class="conFont">
							<img style="vertical-align: middle;display: inline-block;height: 20px;"
								:src="picUrl+'message.png'" mode="heightFix" />
							<span style="vertical-align: middle;margin-left: 10px;">咨询寄语
							</span>
							<div
								style="margin-left: 30px;font-size: 14px;color:#838A96 ;font-weight: 400;margin-top: 10px;">
								{{pageInfo.consultant.self_introduction}}
							</div>
						</div>

						<div class="conFont">
							<img style="vertical-align: middle;display: inline-block;height: 20px;"
								:src="picUrl+'education.png'" mode="heightFix" />
							<span style="vertical-align: middle;margin-left: 10px;">教育背景
							</span>
							<div
								style="margin-left: 30px;font-size: 14px;color:#838A96 ;font-weight: 400;margin-top: 10px;">
								{{ pageInfo.consultant.education_school }} |
								{{ pageInfo.consultant.education_backgroud }}
							</div>
						</div>


						<div class="conFont">
							<img style="vertical-align: middle;display: inline-block;height: 20px;"
								:src="picUrl+'therapy.png'" mode="heightFix" />
							<span style="vertical-align: middle;margin-left: 10px;">擅长疗法
							</span>
							<div
								style="margin-left: 30px;font-size: 14px;color:#838A96 ;font-weight: 400;margin-top: 10px;">
								{{ pageInfo.consultant.good_therapy }}
							</div>
						</div>


						<div class="conFont">
							<img style="vertical-align: middle;display: inline-block;height: 20px;"
								:src="picUrl+'work.png'" mode="heightFix" />
							<span style="vertical-align: middle;margin-left: 10px;">工作经验
							</span>
							<div class="" style="margin-left: 30px;margin-top: 10px;">
								<div class="" v-for="(item,index) in pageInfo.consultant.workExperience" :key="index"
									style="font-size: 16px;font-weight: 400;margin-bottom: 10px;">
									<span class=""
										style="color: #C0C4CC;margin-right: 4px;">{{item.time_quantum}}</span>
									<span class="" style="color: #252631;">{{item.introduced}}</span>
								</div>
							</div>
						</div>

						<div class="conFont">
							<img style="vertical-align: middle;display: inline-block;height: 20px;"
								:src="picUrl+'train.png'" mode="heightFix" />
							<span style="vertical-align: middle;margin-left: 10px;">培训经历
							</span>
							<div class="" style="margin-left: 30px;margin-top: 10px;">
								<div class="" v-for="(item,index) in pageInfo.consultant.trainExperience" :key="index"
									style="color: #4B4B65;font-size: 16px;font-weight: 400;margin-bottom: 10px;">
									<div>{{item.time_quantum}}</div>
									<div
										style="border-radius: 6px;margin-top:10px;font-size: 14px;color: #838A96;background-color: #fafafa;padding: 11px 10px;">
										{{item.introduced}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<tab-bar></tab-bar>

		<el-dialog title="提示" :visible.sync="downLoadApp" width="30%">
			<div>
				<div style="text-align: center;margin-bottom: 20px;font-size: 18px;font-weight: 700;">请下载App，在APP内查看
				</div>
				<div style="display: flex;align-items: center;justify-content: center;">
					<img style="width: 100%;" src="../../assets/image/downLoadPop.png" alt="">
				</div>
			</div>
		</el-dialog>

		<!-- 举报弹窗 -->
		<el-dialog :visible.sync="reportDia" @open="onOpen" @close="onClose" title="咨询师举报">
			<el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
				<el-form-item label="反馈类型" prop="type_id">
					<el-radio-group v-model="formData.type_id" size="medium">
						<el-radio class="u-m-b-10" v-for="(item, index) in type_idOptions" :key="index" :label="item.id"
							:disabled="item.disabled">{{item.name}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="反馈内容" prop="inform_content">
					<el-input v-model="formData.inform_content" type="textarea" placeholder="请描述您要反馈的问题，我们会尽快回复哦~"
						:maxlength="200" show-word-limit :autosize="{minRows: 4, maxRows: 4}" :style="{width: '100%'}">
					</el-input>
				</el-form-item>
				<el-form-item label="上传图片" prop="inform_images">
					<el-checkbox-group v-model="formData.inform_images" v-show="false"></el-checkbox-group>
					<el-upload ref="inform_images" :file-list="inform_imagesfileList"
						:action="baseUrl+'/api/common/upload'" multiple :before-upload="inform_imagesBeforeUpload"
						:on-change="changeList" list-type="picture-card" accept="image/*"
						:headers="{token: $store.state.token}" :limit="3">
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="联系方式" prop="contact">
					<el-input v-model="formData.contact" placeholder="请输入联系方式" clearable :style="{width: '100%'}">
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer">
				<el-button @click="reportDia = false">取消</el-button>
				<el-button type="primary" @click="handleConfirm">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import NavBar from '@/components/NavBar/NavBar.vue'
	import TabBar from '@/components/TabBar/TabBar.vue'
	import {
		consultantDetails,
		consultantEvaluation,
		follow,
		immed,
		queryArticleListById,
		queryQuestionsListById,

		inform_type,
		informSubmit
	} from '@/api/api.js'
	export default {
		name: 'SecondPage',
		data() {
			return {
				baseUrl: process.env.VUE_APP_BASE_API,
				picUrl: 'https://qiniu.manmanxinli.com/wechat/user/order/product/',
				activeName: 'second',
				tabsList: [{
					title: '基本信息'
				}, {
					title: '用户评价'
				}, {
					title: '个人简介'
				}],
				activeTab: 0,
				pageInfo: {},
				evaluation: [],
				page: 1,
				flag: false,
				noMore: false,
				immedList: [],
				articleList: [],
				qaList: [],
				follow: 0,
				ids: '',
				downLoadApp: false,

				reportDia: false,
				formData: {
					type_id: '',
					inform_content: '',
					inform_images: [],
					contact: '',
				},
				rules: {
					type_id: [{
						required: true,
						message: '反馈类型不能为空',
						trigger: 'change'
					}],
					inform_content: [{
						required: true,
						message: '反馈内容不能为空',
					}],
					inform_images: [{
						required: true,
						message: '请上传图片',
					}],
					contact: [],
				},
				inform_imagesfileList: [],
				type_idOptions: [],
			}
		},
		components: {
			NavBar,
			TabBar,
		},
		mounted() {
			this.initData()
		},
		destroyed() {

		},
		watch: {
			'$route'() {
				//路由参数变化重新调用接口
				this.initData()
			}
		},
		methods: {
			goChat() {
				if(this.$store.state.token){
					this.$EventBus.$emit('openWindow', {
						chatid: 'C' + this.ids,
						type: 1
					})
				}else{
					this.goLogin()
				}
				
			},
			initData() {
				this.ids = this.$route.query.id
				this.getInfo()
				this.getEva()
				this.getImmed()
				this.getArticle()
				this.getQA()
				this.getinform_type()

				if (this.$route.query.report) {
					this.complaint()
				}
			},
			changeList(file, fileList) {
				this.formData.inform_images = fileList
			},
			getinform_type() {
				inform_type().then(res => {
					this.type_idOptions = res.data.list
				})
			},
			onOpen() {},
			onClose() {
				this.inform_imagesfileList = []
				this.$refs['elForm'].resetFields()
			},
			close() {
				let inform_images = []
				console.log(this.formData.inform_images)
				if (this.formData.inform_images.length) {
					this.formData.inform_images.forEach(val => {
						inform_images.push(val.response.data.url)
					})
				}
				let data = {
					type_id: this.formData.type_id,
					inform_content: this.formData.inform_content,
					inform_images: inform_images.join(','),
					receiverid: 'C' + this.ids,
					senderid: this.$store.state.chatid,
					contact: this.formData.contact
				}
				informSubmit(data).then(res => {
					console.log(res)
					if (res.code == 1) {
						this.$message.success('提交成功！');
						this.reportDia = false;
					}
				})
			},
			handleConfirm() {
				this.$refs['elForm'].validate(valid => {
					if (!valid) return
					this.close()
				})
			},
			inform_imagesBeforeUpload(file) {
				let isRightSize = file.size / 1024 / 1024 < 5
				if (!isRightSize) {
					this.$message.error('文件大小超过 5MB')
				}
				let isAccept = new RegExp('image/*').test(file.type)
				if (!isAccept) {
					this.$message.error('应该选择image/*类型的文件')
				}
				return isRightSize && isAccept
			},
			goQa(item) {
				let arr = [{
						name: "求助问答",
						url: "/QaHome"
					},
					{
						name: item.title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "QaInfo",
					query: {
						id: item.id
					}
				});
			},
			goArticle(item) {
				let arr = [{
						name: "文章阅读",
						url: "/ArticleHome"
					},
					{
						name: item.title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "ArticleInfo",
					query: {
						id: item.id
					}
				});
			},
			goLogin() {
				this.$router.push({
					name: "LoginPage",
					query: {
						back: true
					}
				});
			},
			subject_handle() {
				if (this.$store.state.token) {
					const ids = this.ids;
					follow({
						ids
					}).then(res => {
						this.follow = res.data
						this.$message.success(res.msg)
					})
				} else {
					this.goLogin()
				}
			},
			loadMore() {
				if (this.flag) return
				this.page++
				this.getEva()
			},
			changeActive(index) {
				this.$el.querySelector("#tab" + index).scrollIntoView({
					block: "start",
					behavior: "smooth"
				});
				this.activeTab = index
			},
			getEva() {
				this.flag = true
				consultantEvaluation({
					ids: this.ids,
					page: this.page
				}).then(res => {
					if (this.page == 1) {
						this.evaluation = res.data.evaluation
					} else {
						this.evaluation = this.evaluation.concat(res.data.evaluation)
					}
					if (res.data.evaluation.length) {
						this.flag = false
					} else {
						if (this.page != 1) {
							this.$message('暂无更多评论');
						}
						this.noMore = true
					}

				})
			},
			complaint() {
				if (this.$store.state.token) {
					this.reportDia = true
					// this.$message.success('您的投诉提交成功');
					if (this.$route.query.report) {
						this.$router.push({
							query: {
								id: this.$route.query.id
							}
						})
					}
				} else {
					this.goLogin()
				}
			},
			getImmed() {
				immed({
					ids: this.ids
				}).then(res => {
					this.immedList = res.data.immed
				})
			},
			getInfo() {
				consultantDetails({
					ids: this.ids
				}).then(res => {
					this.pageInfo = res.data
					this.follow = res.data.follow
				})
			},
			getArticle() {
				queryArticleListById({
					consultant_id: this.ids,
					page: 1,
					perpage: 2
				}).then(res => {
					this.articleList = res.data.list
				})
			},
			getQA() {
				queryQuestionsListById({
					consultant_id: this.ids,
					page: 1,
					perpage: 3
				}).then(res => {
					this.qaList = res.data.list
				})
			}
		},

		created() {},
	}
</script>

<style scoped lang="scss">
	a {
		text-decoration: none;
		/* 去除默认的下划线 */
		color: #717083;
		/* 去除默认的颜色和点击后变化的颜色 */
	}

	.mainApp {
		justify-content: center;
		align-items: flex-start;
		margin: 0 auto;
		margin-top: 30px;
		// min-width: 1390px;
		max-width: 1390px;

		.zxsLeft {
			width: 410px;
			margin-right: 20px;

			.zxsInfo {
				width: 100%;
				background-color: #fff;
				border-radius: 6px;
				padding: 60px 30px 30px;
				box-sizing: border-box;
				text-align: center;

				.infoPic {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				.info1 {
					font-size: 20px;
					font-weight: 600;
					color: #4B4B65;
					line-height: 28px;
					margin-top: 10px;

					.subTit {
						font-size: 14px;
						font-weight: 400;
						color: #C0C4CC;
						line-height: 20px;

						.subTits {
							color: #EC977A;
						}
					}
				}

				.info2 {
					margin-top: 20px;
					font-size: 16px;
					font-weight: 400;
					color: #C0C4CC;
					line-height: 22px;
				}

				.tags {
					margin-top: 10px;
					display: flex;
					align-items: center;
					justify-content: center;

					div {
						padding: 2px 6px 1px;
						font-size: 12px;
						font-weight: 400;
						color: #EC977A;
						line-height: 17px;
						background: rgba(236, 151, 122, 0.1);
						margin: 0 5px;
					}
				}

				.serveNum {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 30px;

					.serveOne {
						.serveTop {
							font-size: 24px;
							color: #4B4B65;
							line-height: 29px;
							font-weight: bolder;
						}

						.serveBot {
							font-size: 12px;
							font-weight: 400;
							color: #C0C4CC;
							line-height: 17px;
						}
					}
				}

				.likeBtn {
					margin-top: 30px;
					// width: 350px;
					flex: 1;
					height: 42px;
					background: #FBFBFB;
					border: 1px solid #F4F4F4;
					text-align: center;
					font-size: 14px;
					font-weight: 600;
					color: #717083;
					line-height: 42px;

					display: flex;
					align-items: center;
					justify-content: center;
				}

				.likeBtn1 {
					background-color: #4263E3;
					border-radius: 20px;
					color: #ffffff;
					margin-right: 20px;
				}

				.complaint {
					margin-top: 10px;
					font-size: 12px;
					font-weight: 300;
					color: #DCDFE6;
					line-height: 17px;
					text-decoration: underline;
				}
			}

			.zxsImmediately {
				margin-top: 30px;
				width: 100%;
				background-color: #fff;
				border-radius: 6px;
				padding: 30px;
				box-sizing: border-box;
				text-align: left;

				.immeTit {
					text-align: left;
					font-size: 26px;
					font-weight: 800;
					color: #4B4B65;
					line-height: 39px;
				}

				.immedList {
					.immedOne {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-top: 30px;

						.immedL {}

						.immedM {
							flex: 1;
							margin-left: 10px;

							font-size: 16px;
							font-weight: 400;
							color: #4B4B65;
							line-height: 22px;
						}

						.immedR {
							font-size: 21px;
							color: #F04352;
							line-height: 15px;
							font-family: 'engFont';
							font-weight: 500;

							span {
								font-size: 12px;
							}
						}
					}
				}

				.immedBtn {
					width: 350px;
					height: 42px;
					background: #FBFBFB;
					border: 1px solid #F4F4F4;
					text-align: center;
					line-height: 42px;

					font-size: 14px;
					font-weight: 600;
					color: #717083;

					margin-top: 30px;
				}

				.immedTips {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 30px;

					.immedTip {
						font-size: 12px;
						font-weight: 400;
						color: #C0C4CC;
						line-height: 17px;

						i {
							margin-right: 5px;
						}
					}
				}
			}

			.zxsNotice {
				margin-top: 30px;
				width: 100%;
				background-color: #fff;
				border-radius: 6px;
				padding: 30px;
				box-sizing: border-box;
				text-align: left;

				.noticeTit {
					text-align: left;
					font-size: 26px;
					font-weight: 800;
					color: #4B4B65;
					line-height: 39px;
				}

				.noticeCon {
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					margin-top: 30px;

					.subRight {
						margin-left: 10px;

						.subRightT {
							font-size: 16px;
							font-weight: 800;
							color: #4B4B65;
							line-height: 24px;
						}

						.subRightB {
							margin-top: 10px;
							font-size: 14px;
							font-weight: 400;
							color: #C0C4CC;
							line-height: 20px;
						}
					}
				}
			}

			.zxsInteraction {
				margin-top: 30px;
				width: 100%;
				background-color: #fff;
				border-radius: 6px;
				padding: 30px;
				box-sizing: border-box;
				text-align: left;

				.hdTit {
					text-align: left;
					font-size: 26px;
					font-weight: 800;
					color: #4B4B65;
					line-height: 39px;
				}

				.article {
					margin-top: 30px;
					font-size: 18px;
					font-weight: bold;
					color: #C0C4CC;
					line-height: 27px;

					.articleList {
						.articleOne {
							display: flex;
							align-items: center;
							justify-content: space-between;
							width: 100%;
							margin-top: 30px;

							.artL {
								display: flex;
								align-items: center;
								justify-content: center;
							}

							.artR {
								margin-left: 20px;
								width: 0;
								flex: 1;

								.artInfo1 {
									font-size: 16px;
									font-weight: bold;
									color: #717083;
									line-height: 24px;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
									-o-text-overflow: ellipsis;
								}

								.artInfo2 {
									margin: 10px 0;
									font-size: 14px;
									font-weight: 400;
									color: #B6B5C5;
									line-height: 18px;
									text-overflow: -o-ellipsis-lastline;
									overflow: hidden; //溢出内容隐藏
									text-overflow: ellipsis; //文本溢出部分用省略号表示
									display: -webkit-box; //特别显示模式
									-webkit-line-clamp: 2; //行数
									line-clamp: 2;
									-webkit-box-orient: vertical; //盒子中内容竖直排列
								}

								.artInfo3 {
									font-size: 14px;
									font-weight: 400;
									color: #717083;
									line-height: 21px;
								}
							}
						}
					}
				}

				.question {
					margin-top: 30px;
					font-size: 18px;
					font-weight: bold;
					color: #C0C4CC;
					line-height: 27px;

					.qaList {
						.qaOne {
							padding: 30px 0;

							&:not(:last-child) {
								border-bottom: 1px solid #f4f4f4;
							}

							.qaT {
								font-size: 18px;
								font-weight: 600;
								color: #4B4B65;
								line-height: 25px;
								overflow: hidden;
								white-space: nowrap;
								text-overflow: ellipsis;
								-o-text-overflow: ellipsis;
							}

							.qaM {
								font-size: 14px;
								font-weight: 400;
								color: #C0C4CC;
								line-height: 20px;
								margin: 10px 0 20px;
								text-overflow: -o-ellipsis-lastline;
								overflow: hidden; //溢出内容隐藏
								text-overflow: ellipsis; //文本溢出部分用省略号表示
								display: -webkit-box; //特别显示模式
								-webkit-line-clamp: 2; //行数
								line-clamp: 2;
								-webkit-box-orient: vertical; //盒子中内容竖直排列
							}

							.qaB {
								display: flex;
								align-items: center;
								justify-content: space-between;

								.qaBL {
									font-size: 14px;
									font-weight: 600;
									color: #51516A;
									line-height: 20px;
								}

								.qaBR {
									font-size: 12px;
									font-weight: 400;
									color: #717083;
									line-height: 17px;
								}
							}
						}

						.activeClass {
							.qaT {
								color: #B6B5C5;
							}

							.qaB {
								.qaBL {
									color: #C0C4CC;
								}

								.qaBR {
									color: #B6B5C5;
								}
							}
						}
					}
				}
			}
		}

		.zxsRight {
			width: 960px;
			background-color: #fff;
			border-radius: 6px;
			box-sizing: border-box;
			padding-bottom: 40px;

			.tabsList {
				// width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				padding: 0 60px;
				border-bottom: 1px solid #f4f4f4;

				.tabsOne {
					padding: 30px 0;
					margin-right: 60px;
					position: relative;
					font-size: 16px;
					font-weight: 400;
					color: #717083;
					line-height: 22px;

					.evanum {
						position: absolute;
						top: 20px;
						right: -20px;
						background-color: #F04352;
						color: #ffffff;
						padding: 0px 8px;
						border-radius: 16px;
						font-size: 10px;
					}
				}

				.activeTab {
					font-size: 18px;
					font-weight: 600;
					color: #4B4B65;
					line-height: 25px;

					&::after {
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
						content: ' ';
						width: 15px;
						height: 3px;
						background: #4B4B65;
					}
				}
			}

			.tabmain {
				padding: 0 60px;
				font-size: 26px;
				font-weight: 800;
				color: #4B4B65;
				line-height: 39px;

				.tabTit {
					margin-top: 40px;
					position: relative;
				}

				.mainCon {
					position: relative;

					.line {
						position: absolute;
						left: 9px;
						width: 0px;
						top: 10px;
						height: calc(100% - 10px);
						border-left: 2px dashed #f4f4f4;
						z-index: 1;
					}

					.conFont {
						position: relative;
						z-index: 2;
						font-size: 18px;
						font-weight: 600;
						line-height: 22px;
						color: #4B4B65;
						margin-top: 20px;

						.good_field_arr {
							margin-top: 10px;
							padding: 30px;
							background-color: #F7F7F7;
							border-radius: 8px;

							> :last-child {
								margin-bottom: 0 !important;
							}

							.good_field_arrOne {
								display: flex;
								align-items: flex-start;
								justify-content: flex-start;
								margin-bottom: 30px;

								.rightInfo {
									margin-left: 10px;

									.goodInfo {
										font-size: 14px;
										font-weight: 400;
										color: #838A96;
										margin-top: 10px;
										line-height: 20px;
									}

									.goodTit {
										font-size: 16px;
										font-weight: 600;
										color: #717083;
										line-height: 22px;
									}
								}
							}
						}

						.consulting_crowd {
							display: flex;
							align-items: center;
							justify-content: flex-start;
							flex-wrap: wrap;
							margin-top: 10px;

							.consulting_crowdOne {
								font-size: 14px;
								font-weight: 400;
								color: #4B4B65;
								line-height: 20px;
								padding: 3px 13px;
								background-color: #F7F7F7;
								margin-right: 20px;
								margin-bottom: 10px;
							}
						}
					}
				}

				.evaList {
					.evaOne {
						display: flex;
						align-items: flex-start;
						justify-content: flex-start;
						padding: 30px 0;

						&:not(:last-child) {
							border-bottom: 2px dashed #f4f4f4;
						}

						.evaInfo {
							margin-left: 10px;

							.top {
								margin-top: 13px;
								font-size: 18px;
								font-weight: 600;
								color: #4B4B65;
								line-height: 25px;

								.subtop {
									margin-left: 10px;
									font-size: 14px;
									font-family: PingFangSC-Regular, PingFang SC;
									font-weight: 400;
									color: #B6B5C5;
									line-height: 20px;
								}
							}

							.bottom {
								margin-top: 22px;
								font-size: 16px;
								font-weight: 400;
								color: #717083;
								line-height: 22px;
							}
						}
					}
				}

				.loadMore {
					margin-top: 20px;
					text-align: center;
					font-size: 14px;
					font-weight: 400;
					color: #B6B5C5;
					line-height: 20px;
					display: flex;
					align-items: center;
					justify-content: center;

					.iconMore {
						// margin-left: 10px;
						width: 24px;
						height: 24px;
						background-color: #f4f4f4;
						border-radius: 50%;
						display: flex;
						align-items: center;
						justify-content: center;
						transform: scale(0.5);
					}
				}
			}
		}
	}
</style>