<template>
  <div class="content">
    <div class="title">
      <div class="lineBox">
        <span class="font">{{ title }}</span>
        <div class="line"></div>
      </div>
    </div>
    <div class="qrCode">
      <img
        style="width: 300px; height: 300px"
        src="../../../../../src/assets/image/downLoadUser.png"
        alt=""
      />
    </div>
    <div class="subTit">扫码下载慢慢心理APP,查看{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "GoApp",
  data() {
    return {
      title: "个人中心",
    };
  },
  props: {},
  watch: {
    $route() {
      this.title = this.$route.query.title || "个人中心";
    },
  },
  created() {
    this.title = this.$route.query.title || "个人中心";
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #fff;
  border-radius: 6px;
  padding: 30px;
  // width: 1010px;
  // min-width: 1010px;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  .title {
    padding-bottom: 30px;
    border-bottom: 1px solid #f7f7f7;
    font-weight: 800;
    font-size: 32px;
    color: #4b4b65;
    line-height: 48px;
    width: 100%;
    .lineBox {
      position: relative;
      display: inline-block;
      .font {
        position: relative;
        z-index: 3;
      }
      .line {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        height: 8px;
        background-color: rgb(255, 157, 0);
        z-index: 2;
        opacity: 0.1;
        border-radius: 6px;
      }
    }
  }
  .qrCode {
    margin: 60px auto 20px;
    display: flex;
    justify-content: center;
  }
  .subTit {
    font-size: 20px;
    font-weight: 400;
    color: #717083;
    line-height: 30px;
    text-align: center;
  }
}
</style>