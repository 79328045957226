let emojiList = [
	[{
			lable: '[爱你]',
			value: 'aini'
		},
		{
			lable: '[奥特曼]',
			value: 'aoteman'
		},
		{
			lable: '[拜拜]',
			value: 'baibai'
		},
		{
			lable: '[抱抱]',
			value: 'baobao'
		},
		{
			lable: '[悲伤]',
			value: 'beishang'
		},
		{
			lable: '[并不简单]',
			value: 'bingbujiandan'
		},
		{
			lable: '[鄙视]',
			value: 'bishi'
		},
		{
			lable: '[闭嘴]',
			value: 'bizui'
		},
		{
			lable: '[馋嘴]',
			value: 'chanzui'
		},
		{
			lable: '[吃惊]',
			value: 'chijing'
		},
		{
			lable: '[打哈欠]',
			value: 'dahaqi'
		},
		{
			lable: '[打脸]',
			value: 'dalian'
		},
		{
			lable: '[敲头]',
			value: 'ding'
		},
		{
			lable: '[狗头]',
			value: 'doge'
		},
		{
			lable: '[二哈]',
			value: 'erha'
		},
		{
			lable: '[费解]',
			value: 'feijie'
		},
		{
			lable: '[肥皂]',
			value: 'feizao'
		},
		{
			lable: '[感冒]',
			value: 'ganmao'
		},
		{
			lable: '[鼓掌]',
			value: 'guzhang'
		},
		{
			lable: '[哈哈]',
			value: 'haha'
		},
		{
			lable: '[害羞]',
			value: 'haixiu'
		},
		{
			lable: '[汗]',
			value: 'han'
		},
		{
			lable: '[呵呵]',
			value: 'hehe'
		},
		{
			lable: '[嘿嘿嘿]',
			value: 'heiheihei'
		}
	],
	[{
			lable: '[黑线]',
			value: 'heixian'
		},
		{
			lable: '[哼]',
			value: 'heng'
		},
		{
			lable: '[坏笑]',
			value: 'huaixiao'
		},
		{
			lable: '[花心]',
			value: 'huaxin'
		},
		{
			lable: '[急眼]',
			value: 'jiyan'
		},
		{
			lable: '[可爱]',
			value: 'keai'
		},
		{
			lable: '[可怜]',
			value: 'kelian'
		},
		{
			lable: '[酷]',
			value: 'ku'
		},
		{
			lable: '[骷髅]',
			value: 'kulou'
		},
		{
			lable: '[困]',
			value: 'kun'
		},
		{
			lable: '[懒得理你]',
			value: 'landelini'
		},
		{
			lable: '[泪]',
			value: 'lei'
		},
		{
			lable: '[怒]',
			value: 'nu'
		},
		{
			lable: '[怒骂]',
			value: 'numa'
		},
		{
			lable: '[钱]',
			value: 'qian'
		},
		{
			lable: '[亲亲]',
			value: 'qinqin'
		},
		{
			lable: '[傻眼]',
			value: 'shayan'
		},
		{
			lable: '[生病]',
			value: 'shengbing'
		},
		{
			lable: '[失望]',
			value: 'shiwang'
		},
		{
			lable: '[衰]',
			value: 'shuai'
		},
		{
			lable: '[睡觉]',
			value: 'shuijiao'
		},
		{
			lable: '[思考]',
			value: 'sikao'
		},
		{
			lable: '[太开心]',
			value: 'taikaixin'
		},
		{
			lable: '[摊手]',
			value: 'tanshou'
		}
	],
	[{
			lable: '[舔]',
			value: 'tian'
		},
		{
			lable: '[偷笑]',
			value: 'touxiao'
		},
		{
			lable: '[吐]',
			value: 'tu'
		},
		{
			lable: '[挖鼻孔]',
			value: 'wabishi'
		},
		{
			lable: '[委屈]',
			value: 'weiqu'
		},
		{
			lable: '[污]',
			value: 'wu'
		},
		{
			lable: '[笑哭]',
			value: 'xiaoku'
		},
		{
			lable: '[星星眼]',
			value: 'xingxingyan'
		},
		{
			lable: '[嘻嘻]',
			value: 'xixi'
		},
		{
			lable: '[嘘]',
			value: 'xu'
		},
		{
			lable: '[阴险]',
			value: 'yinxian'
		},
		{
			lable: '[疑问]',
			value: 'yiwen'
		},
		{
			lable: '[右哼哼]',
			value: 'youhengheng'
		},
		{
			lable: '[晕]',
			value: 'yun'
		},
		{
			lable: '[允悲]',
			value: 'yunbei'
		},
		{
			lable: '[抓狂]',
			value: 'zhuakuang'
		}
	]
]

let onlineEmoji = {
	爱你: 'aini',
	奥特曼: 'aoteman',
	拜拜: 'baibai',
	抱抱: 'baobao',
	悲伤: 'beishang',
	并不简单: 'bingbujiandan',
	鄙视: 'bishi',
	闭嘴: 'bizui',
	馋嘴: 'chanzui',
	吃惊: 'chijing',
	打哈欠: 'dahaqi',
	打脸: 'dalian',
	敲头: 'ding',
	狗头: 'doge',
	二哈: 'erha',
	费解: 'feijie',
	肥皂: 'feizao',
	感冒: 'ganmao',
	鼓掌: 'guzhang',
	哈哈: 'haha',
	害羞: 'haixiu',
	汗: 'han',
	呵呵: 'hehe',
	嘿嘿嘿: 'heiheihei',
	黑线: 'heixian',
	哼: 'heng',
	坏笑: 'huaixiao',
	花心: 'huaxin',
	急眼: 'jiyan',
	可爱: 'keai',
	可怜: 'kelian',
	酷: 'ku',
	骷髅: 'kulou',
	困: 'kun',
	懒得理你: 'landelini',
	泪: 'lei',
	怒: 'nu',
	怒骂: 'numa',
	钱: 'qian',
	亲亲: 'qinqin',
	傻眼: 'shayan',
	生病: 'shengbing',
	失望: 'shiwang',
	衰: 'shuai',
	睡觉: 'shuijiao',
	思考: 'sikao',
	太开心: 'taikaixin',
	摊手: 'tanshou',
	舔: 'tian',
	偷笑: 'touxiao',
	吐: 'tu',
	挖鼻孔: 'wabishi',
	委屈: 'weiqu',
	污: 'wu',
	笑哭: 'xiaoku',
	星星眼: 'xingxingyan',
	嘻嘻: 'xixi',
	嘘: 'xu',
	阴险: 'yinxian',
	疑问: 'yiwen',
	右哼哼: 'youhengheng',
	晕: 'yun',
	允悲: 'yunbei',
	抓狂: 'zhuakuang'
}


export default {
	emojiList,
	onlineEmoji
}
