<template>
  <div class="content">
    <nav-bar :activeId="0" type="1"></nav-bar>
    <div class="mainApp">
      <div class="testInfo display">
        <div class="infoleft">
          <div class="display" style="justify-content: space-between">
            <div class="">
              {{ detailInfo.title }}
            </div>
            <div :class="['price', detailInfo.price ? 'redcolor' : '']">
              {{ detailInfo.price ? "¥" + detailInfo.price : "当前免费" }}
            </div>
          </div>
          <div class="time display">
            <div class="">
              <span>{{ (detailInfo.createtime * 1000) | dateformat }}发布</span>
              <span style="margin-left: 10px; color: #717083"
                >#{{ detailInfo.category_name }} /
                {{ detailInfo.title_num }}道题目</span
              >
            </div>
            <div
              class="numberList display"
              style="flex: 1; justify-content: flex-end"
            >
              <div class="display">
                <iconpark-icon name="preview-open"></iconpark-icon>
                <div style="margin-left: 10px">
                  {{ detailInfo.test_num || "0" }}
                </div>
              </div>
              <div class="display" style="margin-left: 30px">
                <iconpark-icon name="message"></iconpark-icon>
                <div style="margin-left: 10px">{{ eva_count || "0" }}</div>
              </div>
              <div class="display" style="margin-left: 30px">
                <iconpark-icon name="star"></iconpark-icon>
                <div style="margin-left: 10px">
                  {{ detailInfo.collection_num || "0" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="inforight display">
          <div class="goTest" @click="goBack">退出测试</div>
          <div class="customer">
            <iconpark-icon
              class="middle"
              name="headset-one-52b5a2fl"
            ></iconpark-icon>
            <span class="middle" style="margin-left: 5px">客服</span>

            <div class="serve">
            <img src="../../assets/image/serveQr.jpg" alt="">
          </div>
          </div>
        </div>
      </div>
      <div class="testMain" v-if="msgList.length" v-loading="loading">
        <div class="progress">
          <el-slider
            v-model="value1"
            :show-tooltip="false"
            :min="0"
            :max="toatal"
            :disabled="true"
            height="20"
          ></el-slider>
        </div>

        <div class="subject" v-if="msgList.length">
          <div class="title">
            {{ msgList[msgList.length - 1].now_question }}.{{
              msgList[msgList.length - 1].title
            }}
          </div>

          <div
            v-for="(item, index) in msgList[msgList.length - 1].question"
            :key="index"
            :class="['option', activeId === index ? 'active' : '']"
            @click="answer(item, msgList[msgList.length - 1], index)"
          >
            <span>{{ item.option }}.</span>
            <span>{{ item.intro }}</span>

            <div class="select" v-if="activeId === index">
              <iconpark-icon
                style="font-size: 20px"
                name="check-one"
              ></iconpark-icon>
            </div>
          </div>

          <div
            v-if="log_idSuccess == ''"
            style="
              margin-top: 30px;
              text-align: center;
              font-size: 16px;
              color: #bbbbca;
              text-decoration: underline;
            "
            @click="goBack"
          >
            退出测试
          </div>

          <div
            v-else
            style="
              margin-top: 30px;
              text-align: center;
              font-size: 16px;
              color: #bbbbca;
              text-decoration: underline;
            "
            @click="successTest"
          >
            完成测试查看报告
          </div>

          <div
            style="
              margin-top: 30px;
              text-align: center;
              font-size: 32px;
              color: #b3b3b3;
            "
          >
            <span style="font-size:48px;color:;#717083">{{
              msgList.length
            }}</span
            >/{{ toatal }}当前
          </div>
        </div>
      </div>
    </div>
    <tab-bar></tab-bar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar/NavBar.vue";
import TabBar from "@/components/TabBar/TabBar.vue";
import { GetTestDetails, GetTestsubject, AnswerQuestions } from "@/api/api.js";
export default {
  name: "TestAnswer",
  data() {
    return {
      detailInfo: {},
      eva_count: 0,
      value1: 0,
      msgList: [],
      toatal: "",
      loading: true,
      activeId: "",
      answerLock: false,
      log_idSuccess: "",
    };
  },
  components: {
    NavBar,
    TabBar,
  },
  filters: {
    dateformat(time) {
      const date = new Date(time);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1 + "").padStart(2, "0");
      const day = (date.getDay() + "").padStart(2, "0");
      //   const hour = (date.getHours() + "").padStart(2, "0");
      //   const minute = (date.getMinutes() + "").padStart(2, "0");
      //   const second = (date.getSeconds() + "").padStart(2, "0");
      //   return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      return `${year}/${month}/${day}`;
    },
  },
  watch: {
    msgList(val) {
      this.value1 = val.length - 1;
      console.log(val);
      if (val[val.length - 1].type == 3) {
        console.log("结果有了");
      }
    },
  },
  methods: {
    successTest() {
      this.$router.push({ name: "TestInfo", query: { id:this.detailInfo.id , successId:this.log_idSuccess  } });
    },
    goBack() {
      this.$router.back();
    },
    answer(item, x, index) {
      if (this.answerLock) {
        return;
      }
      this.answerLock = true;
      this.activeId = index;
      setTimeout(() => {
        AnswerQuestions({
          log_id: x.log_id,
          question_id: x.question_id,
          intro: item.intro,
        }).then((res) => {
          console.log(res);
          if (res.data.list[0].type != 3) {
            this.activeId = "";
            this.msgList.push(res.data.list[0]);
            this.answerLock = false;
          } else {
            this.value1 = this.msgList.length;
            this.log_idSuccess = res.data.list[0].log_id;
            console.log("结果有了");
          }
        });
      }, 1000);
    },
    getList() {
      GetTestDetails({ test_id: this.$route.query.id, page: 1 }).then((res) => {
        this.detailInfo = res.data.list;
        console.log(res.data.list);
        this.eva_count = res.data.eva_count;
      });
    },
  },
  created() {
    this.getList();

    GetTestsubject({
      test_id: this.$route.query.id,
      log_id: this.$route.query.log_id,
    }).then((res) => {
      console.log(res);
      let list = res.data.list;
      let newList = [];
      list.forEach((item) => {
        if (item.type !== 2) {
          newList.push(item);
        }
      });
      this.msgList = newList;
      this.toatal = res.data.question_num;
      this.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-slider__runway.disabled .el-slider__bar {
  background-color: #409eff;
}
::v-deep .el-slider__runway.disabled .el-slider__button {
  border-color: #409eff;
}
.content {
  .mainApp {
    width: 100%;
    background-color: #fff;
    .testInfo {
      border-top: 1px solid #f4f4f4;
      border-bottom: 1px solid #f4f4f4;
      .infoleft {
        flex: 1;
        padding: 30px 120px;
        border-right: 1px solid #f4f4f4;
        font-size: 32px;
        font-weight: 800;
        color: #4b4b65;
        line-height: 48px;
        .time {
          font-size: 16px;
          font-weight: 400;
          color: #c0c4cc;
          line-height: 22px;
          .numberList {
            font-size: 14px;
            color: #c0c4cc;
          }
        }
        .price {
          font-size: 16px;
          font-weight: 800;
          color: #5473e8;
          line-height: 24px;
        }
        .redcolor {
          color: #f04352;
        }
      }
      .inforight {
        flex: 0 0 421px;
        padding: 0 120px;
        justify-content: center;
        box-sizing: border-box;
        .goTest {
          background: #4b4b65;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
          padding: 8px 14px;
          border-radius: 6px;
        }
        .customer {
          background: #f4f4f4;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #51516a;
          line-height: 20px;
          border-radius: 6px;
          padding: 8px 10px;
          margin-left: 20px;
          position: relative;
          .serve{
          position: absolute;
          top: 40px;
          width: 182.5px;
          height: 209px;
          display: none;
          img{
            width: 100%;
            height: 100%;
          }
          left: -100px;
        }
        &:hover .serve{
          display: block;
        }
        }
      }
    }
    .testMain {
      width: 900px;
      padding: 60px 0;
      margin: 0 auto;
      .progress {
        margin-bottom: 60px;
      }
    }
    .subject {
      .title {
        font-size: 24px;
        font-weight: 600;
        color: #252b39;
        line-height: 33px;
      }
      .option {
        width: 900px;
        padding: 20px 80px 20px 60px;
        box-sizing: border-box;
        border: 1px solid #dcdfe6;
        margin-top: 30px;
        border-radius: 40px;
        position: relative;
        .select {
          position: absolute;
          top: 50%;
          right: 60px;
          transform: translateY(-50%);
          color: #5473e8;
        }
      }
      .active {
        background: #edf1fc;
        color: #5473e8;
      }
    }
  }
}
</style>