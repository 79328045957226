<template>
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible1"
      class="dialogClass"
      width="680px"
      :show-close="true"
      :before-close="handleClose"
    >
      <div v-if="detailInfo">
        <div class="diaBox">
          <div class="topTitle" style="padding-bottom: 20px">
            <div
              class=""
              style="
                font-size: 26px;
                font-weight: 600;
                color: #4b4b65;
                line-height: 37px;
              "
            >
              确认支付订单
            </div>
            <div
              class=""
              style="
                font-size: 16px;
                color: #717083;
                line-height: 22px;
                margin-top: 5px;
              "
            >
              *请支付前确认或修改当前订单
            </div>
          </div>
        </div>
        <div class="">
          <div style="font-size: 18px; font-weight: 600">购买测试题</div>
          <div
            style="
              display: flex;
              align-items: flex-start;
              justify-content: flex-start;
              font: 14px;
              margin-top: 10px;
            "
          >
            <el-image
              style="
                width: 54px;
                height: 54px;
                border-radius: 6px;
                flex: 0 0 54px;
              "
              :src="detailInfo.test_cover_image"
              fit="cover"
            ></el-image>
            <div style="margin: 0 60px 0 10px">
              <div class="" style="font-size: 16px; font-weight: 600">
                {{ detailInfo.title }}
              </div>
              <div class="">{{ detailInfo.content }}</div>
            </div>
          </div>
        </div>
        <div
          style="
            margin-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px dashed #a6a7b0; ;
          "
        >
          <div style="font-size: 18px; font-weight: 600">付款方式</div>
          <div
            class="display"
            style="justify-content: flex-start; margin-top: 10px"
          >
            <!-- <div
              class="payType display"
              :class="[type == 1 ? 'active' : '']"
              @click="changeType(1)"
            >
              <img src="../../assets/image/pay/wechat.png" />
              <span style="margin-left: 10px">微信</span>
            </div>
            <div
              class="payType display"
              style="margin: 0 40px"
              :class="[type == 2 ? 'active' : '']"
              @click="changeType(2)"
            >
              <img src="../../assets/image/pay/ali.png" />
              <span style="margin-left: 10px">支付宝</span>
            </div> -->
            <div
              class="payType display"
              :class="[type == 3 ? 'active' : '']"
              @click="changeType(3)"
            >
              <img src="../../assets/image/pay/wallet.png" />
              <span style="margin-left: 10px">钱包</span>
            </div>
          </div>
        </div>

        <div class="" style="margin-top: 20px" v-if="type == 3 && wallet">
          <div style="font-size: 18px; font-weight: 600">钱包余额</div>
          <div class="" style="margin-top: 10px">
            <span>余额:</span>
            <span>{{ wallet.balance_amount || "0" }}</span>
            <span style="margin-left: 10px;color:#6783e3;" @click="recharge">去充值</span>
          </div>
        </div>

        <div style="margin-top: 20px">
          <div style="font-size: 18px; font-weight: 600">总计</div>
          <div style="font-weight: 600; font-size: 36px; line-height: 50px">
            {{ detailInfo.price }}元
          </div>
        </div>

        <div
          style="
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          "
        >
          <div
            @click="goPay()"
            style="
              cursor: default;
              vertical-align: middle;
              width: 96px;
              padding: 6px 0;
              background-color: #5473e8;
              text-align: center;
              font-size: 14px;
              color: #ffffff;
            "
          >
            立即支付
          </div>
          <div
            class=""
            style="
              vertical-align: middle;
              font-size: 12px;
              line-height: 17px;
              color: #c0c4cc;
              margin-left: 10px;
            "
          >
            <!-- <el-checkbox v-model="checked"></el-checkbox>
            <span
              >同意<span style="color: #5473e8; cursor: pointer"
                ><a
                  target="_blank"
                  href="https://ht.manmanxinli.com/agreement/view/ids/9"
                  >《慢慢心理付费服务协议》</a
                ></span
              ></span
            > -->
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { GetUserWallet, BuyTestWallet } from "@/api/api.js";
export default {
  data() {
    return {
      dialogFormVisible1: false,
      form: {
        name: "",
        phone: "",
        region: "",
        region1: "",
      },
      formLabelWidth: "80px",
      type: 3,
      checked: true,
      avatar: "",
      nickName: "",
      work: "",
      flag: false,
      wallet: null,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    detailInfo: {
      type: Object,
    },
  },
  watch: {
    show(a) {
      console.log(123123, a);
      this.dialogFormVisible1 = a;
      if (a) {
        this.changeType(3);
      }
    },
  },
  methods: {
    recharge() {
      //去充值啊
      let arr = [
        { name: "心理测试", url: "/TestHome" },
        { name: "个人中心", url: "" },
      ];
      localStorage.setItem("route", JSON.stringify(arr));
      this.$store.commit("newRoute", arr);
      this.$router.push({ name: "MyBalance" , query:{title:'我的余额'}});
    },
    changeType(type) {
      this.type = type;
      if (type == 3 && !this.wallet) {
        GetUserWallet().then((res) => {
          console.log(res);
          this.wallet = res.data.wallet;
        });
      }
    },
    handleClose() {
      this.$emit("close");
    },
    GetRequest() {
      var url = location.search; //获取url中"?"符后的字串
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        let strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }

      let newUrl = location.href.split("?")[0];
      history.replaceState(1, null, newUrl);
      return theRequest;
    },
    goPay() {
      if (this.flag) {
        return;
      }
      this.flag = true;
      var url = window.location.href;
      console.log(url);
      if (this.checked) {
        if (this.type == 3) {
          //余额购买
          BuyTestWallet({ test_id: this.detailInfo.id ,charging_channels:'web' }).then((res) => {
            console.log(res);
            if(res.code == 1){
              this.$emit("buySuccess");
            }else{
              this.$message.error(res.msg);
            }
            this.flag = false;
          });
        }
        return;
      } else {
        this.$message({
          message: "请先阅读《慢慢心理付费服务协议》",
          type: "warning",
        });
        this.flag = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payType {
  padding: 8px 18px;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
}
.active {
  border: 1px solid #6783e3;
  background-color: #f6f8fe;
}
</style>