// 封装请求
import axios from 'axios'
import store from '../src/store/index.js'

import router from '../src/router/route.js'

import { Message } from 'element-ui';
// 请求
const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 10000
})
// 请求拦截
http.interceptors.request.use(config => {
    //请求头设置
    // let token = localStorage.getItem('token') || ''
    config.headers.token = store.state.token
    return config
}, err => {
    console.log(err);
})
// 响应拦截
http.interceptors.response.use(arr => {
    // 对响应码的处理
    // switch (arr.data.meta.status) {
    //     case 200:
           
    //         break;
    //     case 201:
           
    //         break;
    //     case 204:
            
    //         break;
    //     case 400:
            
    //         break;
    //     case 401:
           
    //         break;
    //     case 403:
            
    //         break;
    //     case 404:
           
    //         break;
    //     case 422:
            
    //         break;
    //     case 500:
           
    //         break;
    // }
    return arr.data
}, err => {
	Message.closeAll()
	try{
		if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1){
			Message.error('链接超时，请刷新后重试！')
			return err
		}
	}catch{
	}
    if(err.response.data.code==401){
        store.dispatch('logoutAction').then(res => {
        	Message.error('登录已过期，请重新登录！')
        	router.push({
        		name: "AllHome"
        	});
        })
    }
    return err.response.data
})
// 返出
export default http