<template>
  <div class="content">
    <div class="title">慢慢心理帮助中心</div>

    <div class="mainList display">
      <div class="question" v-for="(item, index) in helpList" :key="index">
        <div class="qTit">
          <iconpark-icon class="middle" name="help"></iconpark-icon>
          <span class="middle" style="margin-left: 3px">{{ item.name }}</span>
        </div>
        <div class="qCon">
          <div
            class="qList"
            v-for="(x, i) in item.question"
            :key="i"
            @click="chooseQ(x)"
          >
            <div>{{ x.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="title" v-if="false">慢慢心理反馈中心</div>
    <div class="subTit" v-if="false">
      如果这里没有您想找的问题，请通过“意见反馈”给我们留言。
    </div>
    <div class="feedback display" v-if="false">
      <div class="left">
        <el-input
          type="textarea"
          :rows="6"
          placeholder="请输入内容"
          v-model="textarea"
          resize="none"
          class="textareaIpt"
          maxlength="300"
        ></el-input>

        <el-upload
          :action="baseUrl+'api/common/upload?token='+$store.state.token"
          list-type="picture-card"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove"
          :limit="3"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div class="right"></div>
    </div>

    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div v-html="content"></div>
    </el-dialog>
  </div>
</template>

<script>
import { GetQuestionList } from "@/api/api.js";
import URL from "../../../../../utils/config.js";
export default {
  name: "GoApp",
  data() {
    return {
      baseUrl: URL.baseUrl,
      helpList: [],
      centerDialogVisible: false,
      content: "",
      title: "",
      textarea: "",
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    chooseQ(item) {
      console.log(item);
      this.title = item.title;
      this.content = item.content;
      this.centerDialogVisible = true;
    },
  },
  created() {
    GetQuestionList().then((res) => {
      this.helpList = res.data.list;
      console.log(res);
    });
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #fff;
  border-radius: 6px;
  padding: 30px;
  // width: 1010px;
  max-width: 800px;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    color: #4b4b65;
    line-height: 48px;
  }
  .subTit {
    font-size: 14px;
    font-weight: 600;
    color: #4b4b65;
    line-height: 20px;
    text-align: center;
    margin-top: 10px;
  }
  .mainList {
    margin-top: 30px;
    margin-bottom: 60px;
    align-items: flex-start;
    .question {
      width: 33%;
      text-align: center;
      margin-right: 30px;
      .qTit {
        width: 100%;
        height: 65px;
        line-height: 65px;
        background: #ffffff;
        border: 1px solid #f4f4f4;
        font-size: 18px;
        font-weight: 600;
        color: #252631;
        box-sizing: border-box;
      }
      .qCon {
        width: 100%;
        border: 1px solid #f4f4f4;
        border-top: none;
        padding: 1px 20px;
        font-weight: 400;
        color: #4b4b65;
        line-height: 20px;
        text-align: left;
        box-sizing: border-box;
        .qList {
          margin: 20px 0;
        }
      }
    }
  }
  .feedback {
    margin-top: 30px;
    justify-content: space-between;
    .left {
      width: 640px;
      height: 200px;
      border: 1px solid #c0c4cc;
      border-radius: 6px;
    }
    .right {
    }
  }
}
::v-deep .textareaIpt .el-textarea__inner {
  border: none;
}
::v-deep .el-upload--picture-card {
  transform: scale(0.5);
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-actions{
    transform: scale(0.5);
}
</style>