<template>
	<div class="content">
		<div style="
        width: 100%;
        height: 80px;
        background: #ffffff;
        position: fixed;
        z-index: 10;
      " v-if="type == 0">
			<div class="fixed display" style="
          width: 100%;
          left: 50%;
          transform: translateX(-50%);
          justify-content: space-between;
        ">
				<div class="logo display">
					<el-image style="width:154px;" :src="require('@/assets/image/topLogo.png')"></el-image>
					<!-- <div>
            <div class="LogoTop">慢慢心理</div>
            <div class="LogoBottom">专业心理咨询平台</div>
          </div> -->
				</div>
				<div class="nav display pointer">
					<div v-for="(item, index) in list" :key="index"
						:class="['NavList', activeId == index ? 'active' : '']" @click="navTo(item,index)">
						<span>{{ item.name }}</span>
					</div>
				</div>
				<div class="logininfo display">
					<!-- <i class="el-icon-search"></i> -->
					<!-- <el-badge :value="35" class="item"> -->
					<i class="el-icon-bell" style="margin: 0 20px" @click="goPersonalInfo"></i>
					<!-- </el-badge> -->
					<!-- <el-badge is-dot class="item" v-if="$store.state.token"> -->
					<el-dropdown v-if="$store.state.token">
						<span class="el-dropdown-link">
							<el-avatar :size="36" :src="userInfo.avatar">
							</el-avatar>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="goPersonal">个人中心</el-dropdown-item>
							<el-dropdown-item @click.native="goLoginout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>

					<!-- </el-badge> -->
					<div class="noLogin" @click="goLogin" v-else>登录/注册</div>
				</div>
			</div>
		</div>
		<div class="fixed display" style="
        height: 80px;
        justify-content: space-between;
        padding: 0 30px;
        box-sizing: border-box;
      " v-else>
			<div class="leftRoute display">
				<img style="width: 29px; height: 18px; margin-right: 20px" src="../../assets/logo.png" alt="" />
				<el-breadcrumb separator="/" style="margin-right: 10px" v-if="$store.state.route.length">
					<el-breadcrumb-item :to="{ path: '/AllHome' }"
						v-if="$store.state.route[0].name!='首页'">首页</el-breadcrumb-item>
					<el-breadcrumb-item v-for="(item, index) in $store.state.route" :key="index"
						:to="{ path: item.url }" v-if="item.url">{{ item.name }}</el-breadcrumb-item>
					<el-breadcrumb-item v-for="(item, index) in $store.state.route" :key="index" v-if="!item.url">
						{{ item.name }}
					</el-breadcrumb-item>
				</el-breadcrumb>
				<el-breadcrumb separator="/" style="margin-right: 10px" v-else>
					<el-breadcrumb-item :to="{ path: '/AllHome' }" >首页</el-breadcrumb-item>
				</el-breadcrumb>
				<iconpark-icon name="star-530cc8fj" v-if="$store.state.collection == 1 && iscollection"
					@click="collection" style="color: #ffaa00"></iconpark-icon>
				<iconpark-icon name="star-530cl4i5" v-if="$store.state.collection == 0 && iscollection"
					@click="collection"></iconpark-icon>
			</div>
			<div class="rightInfo" style="flex: 1">
				<div class="logininfo display" style="justify-content: flex-end">
					<!-- <i class="el-icon-search"></i> -->
					<!-- <el-badge  :value="35" class="item"> -->
					<i class="el-icon-bell" style="margin: 0 50px" @click="goPersonalInfo"></i>
					<!-- </el-badge> -->
					<!-- <el-badge
						is-dot
						class="item"
						v-if="$store.state.token"
						@click.native="goPersonal"
					  > -->
					<el-dropdown v-if="$store.state.token">
						<span class="el-dropdown-link">
							<el-avatar :size="36" :src="userInfo.avatar">
							</el-avatar>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="goPersonal">个人中心</el-dropdown-item>
							<el-dropdown-item @click.native="goLoginout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<!-- </el-badge> -->
					<div class="noLogin" @click="goLogin" v-else>登录/注册</div>
				</div>
			</div>
		</div>
		<div style="width: 100%; height: 80px"></div>
	</div>
</template>

<script>
	import {
		CollectionTest,

		cancelCollectRecord,
		addCollectRecord
	} from "@/api/api.js";
	export default {
		name: "NavBar",
		props: {
			type: {
				type: String,
				default: "0",
			},
			iscollection: {
				type: Boolean,
				default: false,
			},
			isQa: {
				type: Boolean,
				default: false,
			},
			isAr: {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				list: [{
						name: "首页",
						url: "AllHome"
					},

					{
						name: "心理测试",
						url: "TestHome"
					},
					{
						name: "心理咨询",
						url: "ZxsHome"
					},
					// {
					//   name: "即时诉求",
					// },
					{
						name: "文章阅读",
						url: "ArticleHome"
					},
					{
						name: "求助问答",
						url: "QaHome"
					},
				],
			};
		},
		computed: {
			activeId: {
				get() {
					return this.$store.state.navbarId
				}
			},
			userInfo: {
				get() {
					return this.$store.state.userInfo;
				},
				set() {},
			},
			route: {
				get() {
					return this.$store.state.route;
				},
				set() {},
			},
		},
		watch: {
			userInfo: {
				handler: function(val) {
					this.userInfo = val;
				},
				immediate: true,
			},
			route: {
				handler: function(val) {

				},
				immediate: true,
			},
		},
		methods: {
			goLoginout() {
				this.$confirm('您是否要退出登录?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$store.dispatch('logoutAction').then(res => {
						this.$message({
							type: 'success',
							message: '账号已退出'
						});
						this.$router.push({
							name: "AllHome"
						});
					})
				})
			},
			navTo(item, index) {
				// this.$store.commit('changeId', index)
				this.$router.push({
					name: item.url
				});
			},
			goPersonalInfo() {
				if (!this.$store.state.token) {
					this.goLogin();
				} else {
					let arr = []
					// if (this.activeId == 0) {
					// 	arr = [{
					// 			name: "心理测试",
					// 			url: "/TestHome"
					// 		},
					// 		{
					// 			name: "个人中心",
					// 			url: ""
					// 		},
					// 	];
					// } else {
					// 	arr = [{
					// 			name: "心理咨询",
					// 			url: "/ZxsHome"
					// 		},
					// 		{
					// 			name: "个人中心",
					// 			url: ""
					// 		},
					// 	];
					// }
					arr = [{
							name: "首页",
							url: "/AllHome"
						},
						{
							name: "个人中心",
							url: ""
						},
					];
					localStorage.setItem("route", JSON.stringify(arr));
					this.$store.commit("newRoute", arr);
					this.$router.push({
						name: "GoApp",
						query: {
							title: '消息通知'
						}
					});
				}
			},
			goPersonal() {
				let arr = [{
						name: this.list[this.activeId].name,
						url: '/' + this.list[this.activeId].url
					},
					{
						name: "个人中心",
						url: ""
					}
				]
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "PersonalCenter"
				});
			},
			goLogin() {
				this.$router.push({
					name: "LoginPage",
					query: {
						back: true
					}
				});
			},
			collection() {
				if (!this.$store.state.token) {
					this.goLogin();
				} else {
					if (this.isQa) {
						this.setCollectionQa()
					} else if (this.isAr) {
						this.setCollectionAr()
					} else {
						this.setCollectionTest()
					}
				}
			},
			setCollectionAr() {
				if (this.$store.state.collection == 1) {
					cancelCollectRecord({
						type: 1,
						operate_id: 2,
						key_id: this.$route.query.id,
						user_type: 1
					}).then(res => {
						this.$message.success('取消收藏')
						this.$store.commit("iscollection", 0);
					})
				} else {
					addCollectRecord({
						type: 1,
						operate_id: 2,
						key_id: this.$route.query.id,
						user_type: 1
					}).then(res => {
						this.$message.success('收藏成功')
						this.$store.commit("iscollection", 1);
					})
				}
			},
			setCollectionQa() {
				if (this.$store.state.collection == 1) {
					cancelCollectRecord({
						type: 2,
						operate_id: 2,
						key_id: this.$route.query.id,
						user_type: 1
					}).then(res => {
						this.$message.success('取消收藏')
						this.$store.commit("iscollection", 0);
					})
				} else {
					addCollectRecord({
						type: 2,
						operate_id: 2,
						key_id: this.$route.query.id,
						user_type: 1
					}).then(res => {
						this.$message.success('收藏成功')
						this.$store.commit("iscollection", 1);
					})
				}
			},
			setCollectionTest() {
				CollectionTest({
					test_id: this.$route.query.id
				}).then((res) => {
					if (res.code == 1) {
						if (res.msg == "收藏成功") {
							this.$store.commit("iscollection", 1);
						} else if (res.msg == "取消收藏成功") {
							this.$store.commit("iscollection", 0);
						}
						this.$message.success(res.msg);
					}
				});
			}
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		// margin-bottom: 30px;
		background-color: #ffffff;
		width: 100%;
		border-bottom: 1px solid #eeeeee;

		.fixed {
			padding: 0 20px;
			box-sizing: border-box;
			position: fixed;
			top: 0;
			left: 0;
			background-color: #ffffff;
			width: 100%;
			z-index: 10;
			// min-width: 1200px;
			border-bottom: 1px solid #eeeeee;
			justify-content: center;
		}

		.logo {
			padding: 16px 0;

			img {
				width: 48px;
				height: 48px;
			}

			.LogoTop {
				width: 73px;
				height: 18px;
				font-size: 18px;
				font-weight: 800;
				color: #51516a;
				line-height: 27px;
				margin-left: 10px;
			}

			.LogoBottom {
				width: 96px;
				height: 12px;
				font-size: 12px;
				font-weight: 400;
				color: #c0c4cc;
				line-height: 18px;
				margin-top: 8px;
			}
		}

		.nav {
			font-size: 16px;
			font-weight: 400;
			color: #717083;
			line-height: 24px;
			height: 80px;
			// line-height: 80px;
			flex: 1;

			.NavList {
				padding: 0 16px;
				margin: 0 10px;
				height: 100%;
				transition: all 0.4s;
				display: flex;
				align-items: center;
				justify-content: center;
				white-space: nowrap;

				&:hover {
					color: #ffffff;
					background-color: #5473e8;
				}
			}

			.active {
				color: #5473e8;
				// color: #ffffff;
				// background-color: #5473e8;
			}
		}

		.logininfo {
			font-size: 18px;
			font-weight: bold;
		}

		.leftRoute {}

		.rightInfo {}
	}
</style>