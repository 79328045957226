<template>
	<div class="content" :style="{width:personal?'100%':'380px'}">
		<div class="fixed">
			<div class="hotTest" v-if="personal">
				<div class="tit u-flex u-row-between u-col-bottom">
					<span>推荐咨询师</span>
					<div class="pointer" style="color: #C0C4CC;font-size: 14px;">
						<span style="text-decoration: underline;">更多</span>
						<i class="el-icon-arrow-right"></i>
					</div>
				</div>
			
				<div class="testList u-flex pointer" v-for="(item, index) in zxsList" :key="index" @click="goZxs(item)">
					<el-avatar style="flex: 0 0 90px;" shape="square" :size="90" :src="item.avatar"></el-avatar>
					<div class="u-m-l-10" style="flex: 1;width: 0;">
						<div class="u-line-1" style="font-size: 16px;color: #4B4B65;">
							<span class="u-m-r-6">{{item.nickname}}</span>
							<span class="" style="color: #C0C4CC;font-size: 12px;">{{item.qualification}}</span>
						</div>
						<div class="u-flex u-line-1 u-m-t-6 u-m-b-6" style="padding: 2px 4px;font-size: 12px;border: 1px solid #F4F4F4;color: #717083;">
							<span>擅长：</span>
							<div class="u-line-1">
								<span class="infoBotSub" v-for="(item,index) in item.good_field" v-if="index<3"
									:key="index">
									{{item}}
								</span>
							</div>
						</div>
						<div class="u-flex u-row-between" style="line-height: 17px;">
							<div class="" style="font-size: 12px;color: #F04352;">
								¥
								<span style="font-size: 14px;">{{parseInt(item.confide_price)}}</span>
								起
							</div>
							<div class="" style="font-size: 12px;">
								<iconpark-icon class="u-m-r-4" name="thumbs-up"></iconpark-icon>
								<span>好评率</span>
								<span>{{item.favorable_rate}}%</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div >
				<img style="width: 100%;background-color: #ffffff;border-radius:6px;"
					src="../../assets/image/rightListWechat.png" alt="" />
			</div>
			<div class="hotTest" v-if="activeId == 1 || personal">
				<div class="tit">热门测试</div>
				<div class="testList display pointer" v-for="(item, index) in testList" :key="index"
					@click="goInfo(item.id, item.title)">
					<div style="font-style: italic; color: #c0c4cc; font-weight: bold">
						{{ index + 1 }}
					</div>
					<div class="overflow" style="margin-left: 12px; flex: 1">
						{{ item.title }}
					</div>
				</div>
			</div>

			<div class="hotTest" v-if="activeId == 2">
				<div class="tit">平台介绍 <span class="subTit">承诺信息真是有效</span></div>

				<div class="introduce">
					<div class="introduceOne">
						<div class="intL">
							<i class="el-icon-user-solid"></i>
						</div>
						<div class="intR">
							<div class="intR1">中科院专家顾问</div>
							<div class="intR2">中科院专心理研究所石教授、心理咨询教授全力参与推动。</div>
						</div>
					</div>
					<div class="introduceOne">
						<div class="intL">
							<i class="el-icon-s-flag"></i>
						</div>
						<div class="intR">
							<div class="intR1">国家认证咨询师</div>
							<div class="intR2">所有入驻咨询都具备国家颁发的专业资质证书并经过认证。</div>
						</div>
					</div>
					<div class="introduceOne">
						<div class="intL">
							<i class="el-icon-s-check"></i>
						</div>
						<div class="intR">
							<div class="intR1">权威合作机构背景</div>
							<div class="intR2">平台与地方妇联、高校、咨询机构等深入合作，提供多渠道服务方式</div>
						</div>
					</div>
					<div class="introduceOne">
						<div class="intL">
							<i class="el-icon-s-finance"></i>
						</div>
						<div class="intR">
							<div class="intR1">不满意全额退款</div>
							<div class="intR2">如专家咨询最终并未帮助您解决问题，一经核实准确，立即全额退款！</div>
						</div>
					</div>
				</div>
			</div>

			<div class="setIn" v-if="activeId == 2">
				<div class="setTop">
					<div class="setSub">
						<i class="el-icon-warning-outline" style="margin-right: 5px;"></i>
						入驻要求
					</div>
					<a class="webEntrance" href="https://teacher.manmanxinli.com/zxsIframe" target="_blank">
						慢慢心理咨询师WEB端
					</a>
				</div>
				<div class="bgimage">
					<img src="../../assets/image/setIn.png" alt="">
				</div>

				<div class="setting">
					<div class="logo">
						<img src="../../assets/image/logo.png" alt="">
					</div>
					<div class="settip">
						<div class="settip1">心理咨询师申请入驻</div>
						<div class="settip2">不限领域风格、时间自由选择</div>
					</div>
					<div class="goApp" @click="downLoadApp1 = true">
						去申请
					</div>
				</div>
			</div>

			<div class="hotTest" v-if="activeId == 2" >
				<div class="tit">咨询师文章</div>

				<div class="articleList">
					<div class="articleOne poniter" v-for="(item,index) in articleList" :key="index" @click="goArticle(item)">
						<div class="articleLeft">
							<el-image style="width: 100px; height: 100px;border-radius: 8px;" :src="item.image"
								fit="cover">
							</el-image>
						</div>
						<div class="articleRight">
							<div class="artInfo1">{{item.title}}</div>
							<div class="artInfo2">{{item.describe}}</div>
							<div class="artInfo3">
								<span v-for="(it,idx) in item.category_names" v-if="idx<1" :key="idx">
									#{{it}}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div class="appDownload" style="height: 140px">
				<img style="width: 100%;background-color: #ffffff;border-radius:6px;"
					src="../../assets/image/rightListDown.png" alt="" />
			</div>


			<div style="height: 120px" v-if="activeId == 2">
				<img style="width: 100%;border-radius:6px;" src="../../assets/image/concatUs.png" alt="" />
			</div>
		</div>
		<div class=""></div>




		<el-dialog title="提示" :visible.sync="downLoadApp" width="30%">
			<div>
				<div style="text-align: center;margin-bottom: 20px;font-size: 18px;font-weight: 700;">请下载App，在APP内查看
				</div>
				<div style="display: flex;align-items: center;justify-content: center;">
					<img style="width: 100%;" src="../../assets/image/downLoadPop.png" alt="">
				</div>
			</div>
		</el-dialog>

		<el-dialog title="提示" :visible.sync="downLoadApp1" width="30%">
			<div>
				<div style="text-align: center;margin-bottom: 20px;font-size: 18px;font-weight: 700;">请下载App，在APP内申请
				</div>
				<div style="display: flex;align-items: center;justify-content: center;">
					<img style="width: 100%;" src="../../assets/image/downLoadPopZxs.png" alt="">
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		GetCestHandpick,
		queryArticleList,
		consultantIndexList,
	} from "@/api/api.js";
	export default {
		name: "RightList",
		data() {
			return {
				zxsList: [],
				testList: [], //热门测试列表
				articleList: [],
				downLoadApp: false,
				downLoadApp1: false
			};
		},
		props:{
			personal: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			activeId: {
				get() {
					return this.$store.state.navbarId
				}
			},
		},
		watch: {
			activeId(val) {
				this.getRightList()
			}
		},
		created() {
			this.getRightList()
		},
		methods: {
			goZxs(item){
				let arr = [{
						name: "心理咨询",
						url: "/ZxsHome"
					},
					{
						name: item.name,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "ZxsInfo",
					query: {
						id: item.id
					}
				});
			},
			goArticle(item){
				console.log(item)
				let arr = [{
						name: "文章阅读",
						url: "/ArticleHome"
					},
					{
						name:item.title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "ArticleInfo",
					query: {
						id:item.id,
						category_id:item.category_ids[0]
					}
				});
			},
			getZxsList(){
				consultantIndexList({
					page: 1,
					limit: 5
				}).then(res=>{
					console.log(res)
					this.zxsList = res.data.consultant
				})
			},
			getRightList() {
				if(this.personal){
					this.getZxsList()
				}
				if (this.activeId == 1 || this.personal) {
					GetCestHandpick().then((res) => {
						this.testList = res.data.list;
					});
				} else if (this.activeId == 2) {
					queryArticleList({
						user_type: 1,
						page: 1,
						recommend: 1,
						perpage: 3,
					}).then(res => {
						this.articleList = res.data.list
					})
				}
			},
			goInfo(id, title) {
				let arr = [{
						name: "心理测试",
						url: "/TestHome"
					},
					{
						name: title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "TestInfo",
					query: {
						id
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.content {
		width: 380px;

		.fixed>div {
			margin-bottom: 20px;
		}

		.fixed {
			// position: fixed;
			// top: 80px;
		}

		& :last-child {
			margin-bottom: 0;
		}

		.hotTest {
			background-color: #fff;
			border-radius: 6px;
			padding: 20px;

			.tit {
				font-size: 26px;
				font-weight: 800;
				color: #4b4b65;
				line-height: 39px;

				.subTit {
					font-size: 16px;
					font-weight: 400;
					color: #717083;
					line-height: 24px;
				}
			}

			.testList {
				margin-top: 30px;
				font-size: 16px;
				font-weight: 500;
				color: #4b4b65;
				line-height: 24px;
			}

			.introduce {
				.introduceOne {
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					margin-top: 30px;

					.intL {}

					.intR {
						margin-left: 10px;

						.intR1 {
							font-size: 16px;
							font-weight: bold;
							color: #4B4B65;
							line-height: 24px;
						}

						.intR2 {
							margin-top: 10px;
							font-size: 16px;
							font-weight: 400;
							color: #C0C4CC;
							line-height: 22px;
						}
					}
				}
			}

			.articleList {
				.articleOne {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-top: 30px;

					.articleLeft {
						display: flex;
						align-items: center;
						justify-content: center;
					}

					.articleRight {
						margin-left: 20px;
						width: 0;
						flex: 1;

						.artInfo1 {
							font-size: 16px;
							font-weight: bold;
							color: #717083;
							line-height: 24px;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
							-o-text-overflow: ellipsis;
						}

						.artInfo2 {
							margin: 10px 0;
							font-size: 14px;
							font-weight: 400;
							color: #B6B5C5;
							line-height: 18px;
							text-overflow: -o-ellipsis-lastline;
							overflow: hidden; //溢出内容隐藏
							text-overflow: ellipsis; //文本溢出部分用省略号表示
							display: -webkit-box; //特别显示模式
							-webkit-line-clamp: 2; //行数
							line-clamp: 2;
							-webkit-box-orient: vertical; //盒子中内容竖直排列
						}

						.artInfo3 {
							font-size: 14px;
							font-weight: 400;
							color: #717083;
							line-height: 21px;
						}
					}
				}
			}
		}

		.setIn {
			width: 100%;
			height: 140px;
			background: #FFFBFA;
			border-radius: 6px;
			background: #FFFBFA;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			.setTop {
				position: absolute;
				top: 0;
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.setSub {
					background: #FFF3EF;
					padding: 7px 10px;
					border-radius: 0 0 8px 0;

					font-size: 12px;
					font-family: SourceHanSansCN-Normal, SourceHanSansCN;
					font-weight: 400;
					color: #EC977A;
					line-height: 18px;
				}

				.webEntrance {
					padding-right: 15px;
					font-size: 12px;
					color: #EC977A;
					cursor: pointer;
				}
			}

			.bgimage {
				position: absolute;
				bottom: 0;
				right: 0;

				img {
					width: 102px;
					height: 64px;
				}
			}

			.setting {
				display: flex;
				align-items: center;
				justify-content: center;

				.logo {
					img {
						width: 50px;
						height: 50px;
						border-radius: 10px;
					}
				}

				.settip {
					margin: 0 40px 0 10px;

					.settip1 {
						font-size: 18px;
						font-weight: 800;
						color: #4B4B65;
						line-height: 27px;
					}

					.settip2 {
						margin-top: 10px;
						font-size: 14px;
						font-weight: 400;
						color: #C0C4CC;
						line-height: 20px;
					}
				}

				.goApp {
					text-align: center;
					line-height: 31px;
					width: 74px;
					height: 31px;
					background: #EC977A;
					box-shadow: 0px 2px 5px 0px #F6DDD7;
					font-size: 12px;
					font-weight: 600;
					color: #FFFFFF;
					// text-shadow: 0px 2px 5px #F6DDD7;
					border-radius: 30px;
					position: relative;
					z-index: 2;
				}
			}
		}
	}
</style>
