<template>
	<div class="contentBox">
		<nav-bar :activeId="0" type="1" :iscollection="true" :isAr="true"></nav-bar>
		<div class="main u-flex u-col-top">
			<div class="u-flex-1">
				<div class="u-flex">
					<div class="title u-flex-1">
						{{articleInfo.title}}
					</div>
					<div class="rightInfo">
						<div class="u-m-b-10">
							<span>专题：</span>
							<span class="u-m-r-6" v-for="(item,index) in articleInfo.category_names" :key="index">
								#{{item}}
							</span>
						</div>
						<div class="u-m-b-10">作者：{{articleInfo.user_name}}</div>
						<div class="u-m-b-10" v-if="articleInfo.create_date">
							发布时间：{{articleInfo.create_date.split(' ')[0].split('-').join('.')}}
						</div>
						<div class="operate u-flex">
							<div class="">
								<i class="el-icon-view u-m-r-4"></i>
								<span>{{articleInfo.read_num}}</span>
							</div>
							<div class="u-m-l-8">
								<i class="el-icon-chat-dot-round u-m-r-4"></i>
								<span>{{articleInfo.comment_num}}</span>
							</div>
							<div class="u-m-l-8">
								<iconpark-icon name="thumbs-up" style="u-m-r-4"></iconpark-icon>
								<span>{{articleInfo.like_num}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="u-m-t-20 u-flex">
					<div class="u-flex-1 contentHtml" style="max-width: 50%;margin: 0 auto;" v-html="articleInfo.content"></div>
					<div class="rightInfo"></div>
				</div>

				<div class="u-flex u-m-t-40">
					<div class="u-flex u-row-between u-flex-1 u-col-bottom">
						<div class="left pointer aroundAr" @click="goInfo(aroundArticle.pre.id,1)">
							<div class="u-m-b-10">上一篇</div>
							<div class="u-line-1" v-if="aroundArticle.pre">{{aroundArticle.pre.title}}</div>
							<div v-else>没有更多了。</div>
						</div>
						<div class="middle u-flex u-flex-1 u-row-center" style="flex-direction: column;">
							<iconpark-icon @click="likeClick" name="thumbs-up" class="pointer"
								:class="[is_like?'like':'dislike']" style="font-size: 28px;"></iconpark-icon>
							<span class="middle1 u-m-t-10 u-m-b-10">{{articleInfo.like_num}}人点赞</span>
							<span class="middle2 pointer" @click="dialogVisible = true">举报</span>
						</div>
						<div class="right pointer aroundAr" @click="goInfo(aroundArticle.next.id,2)">
							<div class="u-m-b-10" style="text-align: right;">下一篇</div>
							<div class="u-line-1" v-if="aroundArticle.next">{{aroundArticle.next.title}}</div>
							<div v-else>没有更多了。</div>
						</div>
					</div>
					<div class="rightInfo"></div>
				</div>

				<div style="width: calc(100% - 270px);">
					<div class="u-flex u-m-t-40" style="width: 100%;">
						<div class="reply u-p-b-20 u-flex u-flex-1" style="border-bottom: 1px solid #f4f4f4;">
							<i class="u-m-r-6 el-icon-chat-dot-round"></i>
							<div style="">回复评论</div>
						</div>
					</div>

					<div class="evaList">
						<div class="nodata" v-if="!commentList.length">
							<span>当前暂无评论~</span>
						</div>
						<div class="login" v-if="!userInfo.avatar">
							<span style="color: #5473e8; cursor: pointer" @click="goLogin">注册/登录</span>
							<span>进行评论</span>
						</div>
						<div class="eva">
							<div class="evaOne u-p-b-20" v-for="(item, index) in commentList" :key="index">
								<div class="evaTop display">
									<div class="avatar">
										<el-avatar :size="50" :src="item.user_avatar"></el-avatar>
									</div>
									<div class="nickname" style="margin: 0 10px">
										{{ item.user_name }}
									</div>
									<div class="time">
										{{ item.createtime*1000 |  dateformat}}
									</div>
								</div>
								<div class="evaBot display">
									<div style="height: 50px; width: 50px; margin-right: 10px;flex: 0 0 50px;"></div>
									<div v-html="item.content"></div>
								</div>


								<div class="comment-reply-box u-m-t-10 u-m-b-20" v-if="item.subset">
									<div class="comment-reply" v-for="(v, i) in item.subset.slice(0,2)" :key="v.id">
										<span class="user-name">
											{{ v.user_name }}：
										</span>
										<span class="reply-content" style="word-break: break-all;">
											{{ v.content }}
										</span>
									</div>
									<!-- 更多评论 -->
									<div class="comment-reply" v-for="(v, i) in item.subset.slice(2)" :key="v.id"
										v-if="item.showMore">
										<span class="user-name">
											{{ v.user_name }}：
										</span>
										<span class="reply-content" style="word-break: break-all;">
											{{ v.content }}
										</span>
									</div>

									<div class="more-reply pointer" v-if="item.subset.length>2 && !item.showMore"
										@click="item.showMore = true">
										更多{{ item.subset.slice(2).length }}条回复
										<span class="cuIcon-right"></span>
									</div>
								</div>

								<span class="u-m-l-60 pointer" style="color: #5473E8;font-size: 14px;"
									@click="openReply(item)">回复</span>
							</div>



							<div class="loadMore display" v-if="more" @click="addPage">
								<span>展开更多</span>
								<div class="circle">
									<iconpark-icon name="down"></iconpark-icon>
								</div>
							</div>

							<div v-if="$store.state.token" class="u-m-t-40">
								<div class="writeEva display">
									<div class="avatar">
										<el-avatar :size="50" :src="userInfo.avatar || ''"></el-avatar>
									</div>
									<div class="textarea" style="flex: 1; margin-left: 10px">
										<el-input rows="10" type="textarea" resize="none" v-model="value"></el-input>
									</div>
								</div>

								<div class="submit pointer" @click="submitEva">发表</div>
							</div>
						</div>
					</div>
				</div>

				<div class="u-p-t-20" style="width: calc(100% - 270px);border-top: 1px solid #f4f4f4;">
					<div class="interrelated u-m-b-20">推荐阅读</div>
					<div class="interrelatedList u-flex u-row-between">
						<div class="interrelatedOne pointer" v-for="(item,index) in interrelatedList" :key="index"
							@click="goInfo(item.id,3,item.title)">
							<div class="u-line-1">
								{{item.title}}
							</div>
							<div class="u-line-3">
								{{item.describe}}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<tab-bar></tab-bar>

		<!-- 回复 -->
		<el-dialog title="回复" :visible.sync="dialogReply" width="40%">
			<div>
				<el-input type="textarea" placeholder="请输入回复内容" v-model="replyVal"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogReply = false">取 消</el-button>
				<el-button type="primary" @click="submitCom">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="举报" :visible.sync="dialogVisible" width="30%">
			<div class="reportList">
				<el-radio-group v-model="radio">
					<el-radio :label="item" v-for="(item,index) in reportList" :key="index"
						class="u-m-b-10">{{item}}</el-radio>
				</el-radio-group>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitReport">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getArticle,
		cancelCollectRecord,
		addCollectRecord,
		getArticlePreAndNext,
		addCommunityReport,
		queryArticleCommentList,
		addArticleComment,
		queryArticleList,
	} from '@/api/api.js'
	import NavBar from '@/components/NavBar/NavBar.vue'
	import TabBar from '@/components/TabBar/TabBar.vue'
	export default {
		data() {
			return {
				articleInfo: {},
				is_like: 0,
				aroundArticle: {},
				reportList: [
					'垃圾广告',
					'有害信息',
					'涉嫌侵权',
					'低俗内容'
				],
				radio: '',
				dialogVisible: false,
				commentList: [],
				more: false,
				value: '',
				interrelatedList: [],

				dialogReply: false,
				replyVal: '',
				activeId: null
			}
		},
		components: {
			NavBar,
			TabBar
		},
		watch: {
			"$route"() {
				this.getData();
			}
		},
		filters: {
			dateformat(time) {
				const date = new Date(time);
				const year = date.getFullYear();
				const month = (date.getMonth() + 1 + "").padStart(2, "0");
				const day = (date.getDay() + "").padStart(2, "0");
				const hour = (date.getHours() + "").padStart(2, "0");
				const minute = (date.getMinutes() + "").padStart(2, "0");
				const second = (date.getSeconds() + "").padStart(2, "0");
				return `${year}.${month}.${day} ${hour}:${minute}:${second}`;
				// return `${year}.${month}.${day}`;
			},
		},
		computed: {
			userInfo: {
				get() {
					return this.$store.state.userInfo;
				},
				set() {},
			},
		},
		mounted() {
			this.getData();
		},
		methods: {
			submitCom() {
				addArticleComment({
					article_id: this.$route.query.id,
					comment_id: this.activeId,
					content: this.replyVal,
					user_type: 1,
				}).then(res => {
					this.dialogReply = false
					this.$message.success('回复成功')
					this.replyVal = ''
					this.getData();
				})
			},
			openReply(item) {
				this.dialogReply = true
				this.activeId = item.id
			},
			submitEva() {
				this.$confirm('是否确认发表当前回复内容?', '提示', {
					confirmButtonText: '确认回复',
					cancelButtonText: '再考虑一下',
					type: 'info'
				}).then(res => {
					addArticleComment({
						user_type: 1,
						article_id: this.$route.query.id,
						content: this.value
					}).then(res => {
						this.value = ''
						this.$forceUpdate();
						this.getData()
					})
				})
			},
			submitReport() {
				if (!this.radio.length) {
					this.$message.error('请选择举报原因！')
					return
				}
				addCommunityReport({
					type: 1,
					reason_cause: this.radio,
					key_id: this.$route.query.id,
					user_type: 1,
				}).then(res => {
					this.dialogVisible = false
					this.radio = ''
					this.$message.success('举报成功')
				})
			},
			goInfo(id, type, tit) {
				if (!id) {
					this.$message.warning('没有更多了！')
					return
				}
				let name = ''
				if (type == 1) {
					name = this.aroundArticle.pre.title
				} else if (type == 2) {
					name = this.aroundArticle.next.title
				} else if (type == 3) {
					name = tit
				}
				let arr = [{
						name: "文章阅读",
						url: "/ArticleHome"
					},
					{
						name,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "ArticleInfo",
					query: {
						id,
						category_id: this.$route.query.category_id
					}
				});
			},
			goLogin() {
				this.$router.push({
					name: "LoginPage",
					query: {
						back: true
					}
				});
			},
			likeClick() {
				if (!this.$store.state.token) {
					this.goLogin();
				} else {
					if (this.is_like) {
						cancelCollectRecord({
							type: 1,
							operate_id: 1,
							key_id: this.$route.query.id,
							user_type: 1
						}).then(res => {
							this.$message.success('取消点赞');
							this.is_like = false;
							this.articleInfo.like_num = this.articleInfo.like_num - 1;
							// this.getData()
						})
					} else {
						addCollectRecord({
							type: 1,
							operate_id: 1,
							key_id: this.$route.query.id,
							user_type: 1
						}).then(res => {
							this.$message.success('点赞成功');
							this.is_like = true;
							this.articleInfo.like_num = this.articleInfo.like_num + 1;
							// this.getData()
						})
					}
				}
			},
			getData() {
				getArticle({
					user_type: 1,
					article_id: this.$route.query.id
				}).then(res => {
					this.is_like = res.data.is_like
					this.$store.commit("iscollection", res.data.is_collect);
					res.data.content = res.data.content.replace(/\<img/gi,
						'<img style="max-width:100%;height:auto"')
					this.articleInfo = res.data;
				})
				getArticlePreAndNext({
					article_id: this.$route.query.id,
					category_id: this.$route.query.category_id || ''
				}).then(res => {
					this.aroundArticle = res.data
				})
				queryArticleList({
					user_type: 1,
					page: 1,
					perpage: 3,
					not_id: this.$route.query.id,
					correlation: this.$route.query.id,
				}).then(res => {
					console.log(1111, res.data.list)
					this.interrelatedList = res.data.list
				})
				this.getComment()
			},
			getComment() {
				queryArticleCommentList({
					user_type: 1,
					article_id: this.$route.query.id
				}).then(res => {
					console.log(res)
					res.data.list.forEach(item => {
						item.showMore = false
					})
					this.commentList = res.data.list
				})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.contentBox {
		width: 100%;
		background-color: #fff;

		// padding-top: 40px;
		.main {
			width: 90%;
			// padding: 0 60px;
			padding-top: 40px;
			margin: 0 auto;
			::v-deep .contentHtml{
				img{
					// max-width: 50% !important;
				}
			}
			.rightInfo {
				flex: 0 0 230px; 
				font-size: 14px;
				font-weight: 300;
				color: #717083;
				line-height: 20px;
				border-left: 1px solid #cfcfcf;
				padding-left: 30px;
				margin-left: 40px;
			}

			.title {
				font-size: 32px;
				font-weight: 600;
				color: #252631;
				line-height: 45px;
			}

			.left {
				font-size: 14px;
				font-weight: 300;
				color: #717083;
				line-height: 20px;

				>:last-child {
					font-size: 16px;
					font-weight: 600;
					color: #717083;
					line-height: 22px;
				}
			}

			.middle {
				.middle1 {
					font-size: 16px;
					font-weight: 400;
					color: #717083;
					line-height: 22px;
				}

				.middle2 {
					font-size: 14px;
					font-weight: 300;
					color: #B6B5C5;
					line-height: 20px;
				}

				.like {
					color: #EC977A;
				}

				.dislike {
					color: #bfbfbf;
				}
			}

			.aroundAr {
				width: 200px;
			}

			.right {
				font-size: 14px;
				font-weight: 300;
				color: #717083;
				line-height: 20px;
				text-align: right;

				>:last-child {
					font-size: 16px;
					font-weight: 600;
					color: #717083;
					line-height: 22px;
				}
			}
		}
	}

	.reply {
		font-size: 16px;
		font-weight: 400;
		color: #717083;
		line-height: 22px;
	}

	.evaList {
		margin-top: 40px;

		.nodata {
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #c0c4cc;
			line-height: 22px;
			margin-bottom: 60px;
		}

		.login {
			font-size: 20px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #c0c4cc;
			line-height: 80px;
			margin: 0 auto;
			// width: 900px;
			height: 80px;
			box-sizing: border-box;
			background: #f7f7f7;
			border: 1px solid #dcdfe6;
			text-align: center;
			margin-bottom: 60px;
		}

		.eva {

			.evaOne {
				&:not(:last-child) {
					border-bottom: 1px dashed #F4F4F4;
				}

				padding-top: 20px;

				.evaTop {
					.nickname {
						font-size: 18px;
						font-weight: 600;
						color: #4b4b65;
						line-height: 25px;
					}

					.time {
						font-size: 14px;
						font-weight: 400;
						color: #b6b5c5;
						line-height: 20px;
					}
				}

				.evaBot {
					// padding-bottom: 20px;
					// border-bottom: 2px dashed #f4f4f4;

					&:last-child {
						border-bottom: none;
					}
				}

				.comment-reply-box {
					background: #F8F8F8;
					border-radius: 12px;
					// margin-top: 20px;
					padding: 0 20px 10px;
					margin-left: 60px;

					.comment-reply {
						padding-top: 10px;
						font-size: 14px;
						line-height: 20px;

						.user-name {
							font-weight: 700;
							color: #252631;
						}

						.reply-content {
							color: #5A5B60;
						}
					}

					.more-reply {
						margin-top: 10px;
						font-size: 14px;
						font-weight: 400;
						color: #5473E8;
						line-height: 20px;
					}
				}
			}

			.loadMore {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #b6b5c5;
				line-height: 20px;
				text-align: center;
				justify-content: center;

				.circle {
					width: 12px;
					height: 12px;
					line-height: 12px;
					font-size: 12px;
					border-radius: 50%;
					background-color: #f4f4f4;
					margin-left: 10px;
					color: #717083;
				}
			}
		}

		.writeEva {
			align-items: flex-start;
		}

		.submit {
			background: #f7f7f7;
			border: 1px solid #dcdfe6;
			text-align: center;
			font-size: 16px;
			font-weight: 400;
			color: #717083;
			line-height: 22px;
			padding: 10px 39px;
			display: inline-block;
			margin: 30px 0 60px 60px;
		}
	}

	.interrelated {
		font-size: 16px;
		font-weight: 400;
		color: #DCDFE6;
		line-height: 22px;
	}

	.interrelatedList {}

	.interrelatedOne {
		border-radius: 4px;
		border: 1px solid #DCDFE6;
		padding: 20px;
		flex: 0 0 30%;
		width: 30%;

		>:first-child {
			font-size: 18px;
			font-weight: 600;
			color: #4B4B65;
			line-height: 25px;
			margin-bottom: 16px;
		}

		>:last-child {
			font-size: 14px;
			font-weight: 400;
			color: #717083;
			line-height: 20px;
			height: 60px;
		}
	}
</style>