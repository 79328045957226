<template>
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible1"
      class="dialogClass"
      width="410px"
      :show-close="true"
      :before-close="handleClose"
    >
      <div>
        <div class="diaBox">
          <div class="topTitle" style="padding-bottom: 20px">
            <div
              class=""
              style="
                font-size: 26px;
                font-weight: 600;
                color: #4b4b65;
                line-height: 37px;
              "
            >
              咨询币充值
            </div>
            <div
              class=""
              style="
                font-size: 16px;
                color: #717083;
                line-height: 22px;
                margin-top: 5px;
              "
            >
              当前钱包咨询币余额:{{ $store.state.walletInfo.balance_amount }}币
            </div>
          </div>
        </div>
        <div
          style="
            margin-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px dashed #a6a7b0; ;
          "
        >
          <div style="font-size: 18px; font-weight: 600">充值方式</div>
          <div
            class="display"
            style="justify-content: flex-start; margin-top: 10px"
          >
            <div
              class="payType display"
              :class="[type == 1 ? 'active' : '']"
              @click="changeType(1)"
            >
              <img src="../../assets/image/pay/wechat.png" />
              <span style="margin-left: 10px">微信</span>
            </div>
            <div
              class="payType display"
              style="margin: 0 40px"
              :class="[type == 2 ? 'active' : '']"
              @click="changeType(2)"
            >
              <img src="../../assets/image/pay/ali.png" />
              <span style="margin-left: 10px">支付宝</span>
            </div>
          </div>

          <!-- 充值额度 -->
          <div
            style="
              font-size: 18px;
              font-weight: 600;
              margin-top: 30px;
              margin-bottom: 20px;
            "
          >
            充值额度
          </div>

          <el-row>
            <el-col
              :span="24"
              style="position: relative; border-radius: 6px"
              @click.native="active = -1"
            >
              <el-input
                v-model="value"
                :class="['ipt', active == -1 ? 'active1' : '']"
                placeholder="在此输入充值额度"
                maxlength="8"
                @input="
                  (v) =>
                    (value = v
                      .replace(/[^\d.]/g, '')
                      .replace(/\.{2,}/g, '.')
                      .replace(/^(\d+)\.(\d\d).*$/, '$1.$2'))
                "
              ></el-input>
              <div class="iptSub">最大充值限额为：50000/单笔</div>
            </el-col>
          </el-row>

          <el-row :gutter="28">
            <el-col
              :span="8"
              v-for="(item, index) in dataList"
              :key="index"
              @click.native="changeList(index)"
            >
              <div class="box" :class="[active == index ? 'active1' : '']">
                {{ item.denomination_title }}
              </div>
            </el-col>
          </el-row>
        </div>

        <div style="margin-top: 20px">
          <div style="font-size: 18px; font-weight: 600">总计</div>
          <div style="font-weight: 600; font-size: 36px; line-height: 50px">
            <span v-if="active == -1">{{ value || 0 }}元</span>
            <span v-else>{{ dataList[this.active].realpay_amount }}元</span>
          </div>
        </div>

        <div
          style="
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          "
        >
          <div
            style="
              cursor: default;
              vertical-align: middle;
              width: 96px;
              padding: 6px 0;
              background-color: #5473e8;
              text-align: center;
              font-size: 14px;
              color: #ffffff;
            "
            @click="paySubmit"
          >
            立即支付
          </div>
          <div
            class=""
            style="
              vertical-align: middle;
              font-size: 12px;
              line-height: 17px;
              color: #c0c4cc;
              margin-left: 10px;
            "
          >
            <!-- <el-checkbox v-model="checked"></el-checkbox>
            <span
              >同意<span style="color: #5473e8; cursor: pointer"
                ><a
                  target="_blank"
                  href="https://ht.manmanxinli.com/agreement/view/ids/9"
                  >《慢慢心理付费服务协议》</a
                ></span
              ></span
            > -->
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { GetDenomination, UserRecharge } from "@/api/api.js";

export default {
  data() {
    return {
      dialogFormVisible1: false,
      form: {
        name: "",
        phone: "",
        region: "",
        region1: "",
      },
      formLabelWidth: "80px",
      type: 1,
      checked: true,
      avatar: "",
      nickName: "",
      work: "",
      flag: false,
      wallet: null,
      dataList: [],
      value: "",
      active: -1,
      lockPay:false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(a) {
      console.log(123123, a);
      this.dialogFormVisible1 = a;
      if (a) {
        this.GetList();
        this.changeType(1);
      }
    },
  },
  methods: {
    changeList(index) {
      console.log("asdasd", index);
      this.active = index;
    },
    GetList() {
      GetDenomination().then((res) => {
        console.log("获取的充值列表", res);
        this.dataList = res.data.list;
      });
    },
    recharge() {
      //去充值啊
    },
    changeType(type) {
      this.type = type;
    },
    handleClose() {
      this.$emit("close");
    },
    GetRequest() {
      var url = location.search; //获取url中"?"符后的字串
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        let strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = unescape(strs[i].split("=")[1]);
        }
      }

      let newUrl = location.href.split("?")[0];
      history.replaceState(1, null, newUrl);
      return theRequest;
    },
    paySubmit() {
      let data = {
        type: this.type == 1 ? "wxpay" : "alipay",
        method: this.type == 1 ? "scan" : "web",
      };
      if (this.active == -1) {
        var isNum = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
        if (!isNum.test(this.value)) {
          this.$message.error("请输入正确的金额");
          return;
        }
        if (Number(this.value) < 0.01) {
          this.$message.error("请输入您要充值的金额");
          return;
        }
        if (Number(this.value) > 50000) {
          this.$message.error("最大充值金额为50000/单笔");
          return;
        }
        data.amount = Number(this.value);
      } else {
        data.denomination_id = this.dataList[this.active].id;
        console.log(data);
      }
      if (this.lockPay) {
        return;
      }
      this.lockPay = true
      UserRecharge(data).then((res) => {
        console.log("结果是啥啊", res);
        if (this.type == 1) {
          this.$emit("scanCode", res.data);
        } else {
          const div = document.createElement("div");
          div.innerHTML = res.data; // html code
          document.body.appendChild(div);
          document.forms[0].submit();
        }
        this.dialogFormVisible1 = false
        this.lockPay = false
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.payType {
  padding: 8px 18px;
  border-radius: 6px;
  border: 1px solid #e7e7e7;
}
.active {
  border: 1px solid #6783e3;
  background-color: #f6f8fe;
}
.iptSub {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);

  font-size: 10px;
  font-weight: 400;
  color: #ec977a;
  line-height: 14px;
}

.box {
  text-align: center;
  width: 98px;
  height: 36px;
  background: rgba(244, 244, 244, 0.1);
  border: 1px solid #f4f4f4;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #c0c4cc;
  line-height: 36px;
  margin-top: 20px;
}
.ipt {
  border: 1px solid #f4f4f4;
}
.active1 {
  background: rgba(84, 115, 232, 0.03);
  border: 1px solid #5473e8;
  color: #5473e8;
  border-radius: 6px;
}
</style>