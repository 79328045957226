<template>
	<div>
		<div class="chatBox" v-if="messageInfo.list">
			<div class="chatOne u-flex pointer" v-for="(item,index) in messageInfo.list" :key="index" @click="openChat(item)">
				<el-image style="width: 50px;height: 50px;border-radius: 50%;flex: 0 0 50px;" :src="item.avatar"
					fit="cover"></el-image>
				<div class="u-m-l-10 u-flex-1 u-flex u-col-top" style="flex-direction: column;">
					<div class="u-flex u-flex-1 u-row-between" style="width: 100%;">
						<div class="u-flex-1">
							<span style="font-size: 16px;font-weight: bold;" v-if="item.type!=3">{{item.name}}</span>
							<span style="font-size: 16px;font-weight: bold;" v-else>{{item.name}}</span>
							<span style="font-size: 12px;" v-if="item.type!=3">·{{item.qualification}}</span>
							<span style="font-size: 12px;" v-else>·咨询顾问</span>
							<span
								style="font-size: 12px;color: #EC977A;margin-left: 4px;">{{item.consulting_type}}</span>
						</div>
						<span style="font-size: 12px;color:#C0C4CC;">{{item.this_time}}</span>
					</div>
					<div class="u-m-t-6" style="width: 100%;height: 20px;overflow: hidden;">
						<div class="u-flex u-col-top">
							<div class="u-flex-1">
								<div v-if="item.msg_type == 1 || item.msg_type == 13 || item.msg_type == 17 || item.msg_type == 20 || item.msg_type == 21 || item.msg_type == 25 || item.msg_type == 26 || item.msg_type == 29"
									v-html="`<div>${item.last_content}</div>`"></div>
								<div v-else-if="item.msg_type == 2">[语音]</div>
								<div v-else-if="item.msg_type == 3">[图片]</div>
								<div v-else-if="item.msg_type == 4">[语音通话]</div>
								<div v-else-if="item.msg_type == 5">[视频通话]</div>
								<div v-else-if="item.msg_type == 6">[信息提示]</div>
								<div v-else-if="item.msg_type == 7">[咨询方向]</div>
								<div v-else-if="item.msg_type == 8">[新客户首次免费]</div>
								<div v-else-if="item.msg_type == 9">[选择咨询方向]</div>
								<div v-else-if="item.msg_type == 10">[选择问题描述]</div>
								<div v-else-if="item.msg_type == 11 || item.msg_type==23">[推荐咨询]</div>
								<div v-else-if="item.msg_type == 12 || item.msg_type==22">[推荐咨询师]</div>
								<div v-else-if="item.msg_type == 14">[立即订单]</div>
								<div v-else-if="item.msg_type == 15">[预约订单]</div>
								<div v-else-if="item.msg_type == 16">[修改订单]</div>
								<div v-else-if="item.msg_type == 18">[测试结果]</div>
								<div v-else-if="item.msg_type == 19">[测试推荐]</div>
								<div v-else-if="item.msg_type == 24">[预约推荐]</div>
								<div v-else-if="item.msg_type == 28 || item.msg_type == 27">[推荐套餐]</div>
								<div
									v-else-if="item.msg_type == 30 || item.msg_type == 31 || item.msg_type == 34 || item.msg_type == 35">
									[订单信息]</div>
								<div v-else-if="item.msg_type == 32">[咨询师已接单]</div>
								<div v-else-if="item.msg_type == 33">[咨询卡购买]</div>
								<div v-else>[当前版本暂不支持查看]请下载最新版本APP</div>
							</div>

							<div class="notifytext44" v-if="item.noread_count">{{item.noread_count}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		props: {
			messageInfo: {
				type: Object,
				default: {}
			}
		},
		methods: {
			openChat(item){
				this.$emit('openChat',item)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.chatBox::-webkit-scrollbar {
		width: 4px;
		border-radius: 10px;
	}

	.chatBox::-webkit-scrollbar-track {
		background-color: #eeeeee;
		border-radius: 10px;
	}

	.chatBox::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: #4263E3;
		background-image: -webkit-linear-gradient(45deg,
				rgba(255, 255, 255, 0.2) 25%,
				transparent 25%,
				transparent 50%,
				rgba(255, 255, 255, 0.2) 50%,
				rgba(255, 255, 255, 0.2) 75%,
				transparent 75%,
				transparent);
	}

	.chatBox {
		height: 500px;
		overflow-y: auto;

		.chatOne {
			padding: 20px 10px;
			border-radius: 4px;

			&:hover {
				background-color: #eeeeee;
			}
		}

		.notifytext44 {
			min-width: 20px;
			text-align: center;
			border-radius: 10px;
			background-color: #F21D2F;
			font-size: 12px;
			line-height: 20px;
			padding: 0 4px;
			color: #ffffff;
			font-weight: 500;
		}
	}
</style>