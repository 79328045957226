<template>
	<div class="content">
		<nav-bar></nav-bar>
		<div class="mainApp display" :class="[navbarId==0?'maxWidth':'']">
			<router-view></router-view>
			<right-list v-if="navbarId==1||navbarId==2" style="margin-left:20px;"></right-list>
			<qa-right v-if="navbarId==4" style="margin-left:20px;"></qa-right>
			<ar-right v-if="navbarId==3" style="margin-left:20px;"></ar-right>
		</div>
		<tab-bar></tab-bar>
	</div>
</template>

<script>
	import NavBar from '@/components/NavBar/NavBar.vue'
	import TabBar from '@/components/TabBar/TabBar.vue'
	import RightList from '@/components/RightList/RightList.vue'
	import QaRight from '@/components/QaRight/QaRight.vue'
	import ArRight from '@/components/ArRight/ArRight.vue'
	export default {
		name: 'HomePage',
		data() {
			return {

			}
		},
		computed: {
			navbarId() {
				return this.$store.state.navbarId
			}
		},
		components: {
			NavBar,
			TabBar,
			RightList,
			QaRight,
			ArRight
		},
		methods: {

		}
	}
</script>

<style scoped>
	.mainApp {
		justify-content: center;
		align-items: flex-start;
		/* min-width: 1390px; */
		max-width: 1390px;
		margin: 0 auto;
		margin-top: 30px;
	}
	.maxWidth{
		/* min-width: 1390px; */
		width: 100%;
		max-width: 100%;
	}
</style>
