import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import Recorder from 'recorder-core/recorder.mp3.min' //已包含recorder-core和mp3格式支持

//cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)


//element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

//router
import router from './router/route.js'

//vuex
import store from './store/index.js'

import {
	receiveCouponPlatform
} from '@/api/api.js'
//banner路由跳转
function bannerFunc(item) {
	console.log(item, ElementUI)
	let arr = []
	let name = ''
	switch (item.link_type) {
		case 1: //文章
			name = 'ArticleInfo'
			arr = [{
					name: "文章阅读",
					url: "/ArticleHome"
				},
				{
					name: item.link_text,
					url: ""
				},
			];
			break;
		case 2: //测试题
			name = 'TestInfo'
			arr = [{
					name: "心理测试",
					url: "/TestHome"
				},
				{
					name: item.link_text,
					url: ""
				},
			];
			break;
		case 3: //外链
			window.open(item.link, 'target')
			break;
		case 4: //问答
			name = 'QaInfo'
			arr = [{
					name: "求助问答",
					url: "/ZxsHome"
				},
				{
					name: item.link_text,
					url: ""
				},
			];
			break;
		case 5: //优惠券
			if (store.state.token) {
				console.log(store.state.token, item.link)
				ElementUI.MessageBox.confirm('点击确定将领取优惠券, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					receiveCouponPlatform({
						coupon_id: item.link
					}).then(res => {
						ElementUI.Message.info(res.msg)
					})
				})
			} else {
				ElementUI.MessageBox.confirm('请登录后再领取优惠券, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					router.push({
						name: "LoginPage",
						query: {
							back: true
						}
					});
				})
			}
			break;
		case 6: //咨询师
			name = 'ZxsInfo'
			arr = [{
					name: "心理咨询",
					url: "/QaHome"
				},
				{
					name: item.link_text,
					url: ""
				}
			];
			break;
	}
	if (!name) return;
	localStorage.setItem("route", JSON.stringify(arr));
	store.commit("newRoute", arr);
	router.push({
		name,
		query: {
			id: item.link
		}
	});
}

Vue.prototype.bannerFunc = bannerFunc


// v-dialogDrag: 弹窗拖拽
Vue.directive('dialogDrag', {
  bind(el, binding, vnode, oldVnode) {
    const dialogHeaderEl = el.querySelector('.el-dialog__header')
    const dragDom = el.querySelector('.el-dialog')
    dialogHeaderEl.style.cursor = 'move'
    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
    const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null)

    dialogHeaderEl.onmousedown = (e) => {
      // 鼠标按下，计算当前元素距离可视区的距离
      const disX = e.clientX - dialogHeaderEl.offsetLeft
      const disY = e.clientY - dialogHeaderEl.offsetTop

      // 获取到的值带px 正则匹配替换
      let styL, styT

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
      if (sty.left.includes('%')) {
        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100)
        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100)
      } else {
        styL = +sty.left.replace(/\px/g, '')
        styT = +sty.top.replace(/\px/g, '')
      }

      document.onmousemove = function(e) {
        // 通过事件委托，计算移动的距离
        const l = e.clientX - disX
        const t = e.clientY - disY

        // 移动当前元素
        dragDom.style.left = `${l + styL}px`
        dragDom.style.top = `${t + styT}px`

        // 将此时的位置传出去
        // binding.value({x:e.pageX,y:e.pageY})
      }

      document.onmouseup = function(e) {
        document.onmousemove = null
        document.onmouseup = null
      }
    }
  }
})



Vue.prototype.$EventBus = new Vue();
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')