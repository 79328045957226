<template>
	<div class="content">
		<nav-bar :activeId="0" type="1" :iscollection="true" :isQa="isQa"></nav-bar>
		<div class="mainApp display">
			<router-view></router-view>
			<right-list v-if="!isQa" style="margin-left:20px;"></right-list>
			<qa-right v-if="isQa" style="margin-left:20px;" :isInfo="true"></qa-right>
			<!-- <ar-right v-if="isAr" style="margin-left:20px;" :isInfo="true"></ar-right> -->
		</div>
		<tab-bar></tab-bar>
	</div>
</template>

<script>
	import NavBar from '@/components/NavBar/NavBar.vue'
	import TabBar from '@/components/TabBar/TabBar.vue'
	import RightList from '@/components/RightList/RightList.vue'
	import QaRight from '@/components/QaRight/QaRight.vue'
	import ArRight from '@/components/ArRight/ArRight.vue'
	export default {
		name: 'SecondPage',
		data() {
			return {
				isQa: false,
				// isAr:false
			}
		},
		components: {
			NavBar,
			TabBar,
			RightList,
			QaRight,
			ArRight
		},
		watch: {
			//监听路由携带的参数type,mold  配置地址:src/router/index.js
			'$route': {
				//执行 val:当前最新值 old:上一刻的值
				handler: function(val, old) {
					console.log(1123,val)
					if(val.name=='QaInfo'){
						this.isQa = true
						// this.isAr = false
					}else if(val.name=='ArticleInfo'){
						// this.isAr = true
						// this.isQa = false
					}else{
						// this.isAr = false
						this.isQa = false
					}
				},
				// 立即处理 进入页面就触发
				immediate: true,
				// 深度监听 属性的变化
				deep: true
			}
		},
		methods: {

		},
		created() {},
	}
</script>

<style scoped lang="scss">
	.mainApp {
		justify-content: center;
		align-items: flex-start;
		margin: 0 auto;
		margin-top: 30px;
		// min-width: 1390px;
		max-width: 1390px;
	}
</style>
