<template>
	<div class="content">
		<div class="title">
			<div class="lineBox">
				<span class="font">我的收藏</span>
				<div class="line"></div>
			</div>
		</div>
		<!-- 测试 -->
		<div class="tabsList">
			<div class="tabs" :class="[tabsActive ==0 ?'tabsActive':'']" @click="tabsActive = 0">测试</div>
			<div class="tabs u-m-l-20" :class="[tabsActive == 1?'tabsActive':'']" @click="tabsActive = 1">文章</div>
			<div class="tabs u-m-l-20" :class="[tabsActive == 2?'tabsActive':'']" @click="tabsActive = 2">问答</div>
		</div>

		<div v-loading="loading">

			<div class="testlist" v-if="tabsActive == 0">
				<div class="listOne display" v-for="(item, index) in testList" :key="index" @click="goInfo(item,1)">
					<div class="leftPic">
						<el-image style="width: 150px; height: 90px" :src="item.test_cover_image"></el-image>
					</div>
					<div class="rightInfo">
						<div class="topinfo display">
							<div class="u-flex-1 u-line-1" style="width: 0;">{{ item.title }}</div>
							<div class="testPrice" :class="[!item.price ? 'testFree' : '']">
								{{ item.price ? "¥" + item.price : "免费" }}
							</div>
						</div>
						<div class="bottominfo display">
							<div class="left">
								<span>{{ item.test_createtime | dateformat }}发布</span>
								<span style="margin-left: 10px; color: #717083">#{{ item.category_name }} /
									{{ item.title_num }}道题目</span>
							</div>
							<div class="numberList display" style="flex: 1; justify-content: flex-end">
								<div class="display">
									<iconpark-icon name="preview-open"></iconpark-icon>
									<div style="margin-left: 10px">
										{{ item.test_num || "0" }}
									</div>
								</div>
								<div class="display" style="margin-left: 30px">
									<iconpark-icon name="message"></iconpark-icon>
									<div style="margin-left: 10px">
										{{ item.evaluation_num || "0" }}
									</div>
								</div>
								<div class="display" style="margin-left: 30px">
									<iconpark-icon name="star"></iconpark-icon>
									<div style="margin-left: 10px">
										{{ item.collection_num || "0" }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<el-empty description="暂无收藏" v-if="!testList.length"></el-empty>
			</div>
			<!-- 文章 -->
			<div v-if="tabsActive == 1">
				<div class="listOne u-flex" v-for="(item, index) in articleList" :key="index" @click="goInfo(item,2)">
					<div class="leftPic">
						<el-image style="width: 240px; height: 160px;border-radius: 8px;" :src="item.image"
							fit="cover"></el-image>
					</div>
					<div class="rightInfo">
						<div class="topinfo display ">
							<div class="u-line-1 u-flex-1" style="width: 0;">{{ item.title }}</div>
						</div>
						<div class="describe u-line-3" style="height: 60px;">
							{{item.describe}}
						</div>
						<div class="bottominfo display">
							<div class="left">
								<span style="color: #717083">
									#{{ item.category_names[0] }}
								</span>
								<span class="u-m-l-8">{{item.create_date.split(' ')[0].split('-').join('.')}}</span>
							</div>
							<div class="numberList display" style="flex: 1; justify-content: flex-end">
								<div class="display">
									<iconpark-icon name="preview-open"></iconpark-icon>
									<div style="margin-left: 10px">
										{{ item.read_num || "0" }}
									</div>
								</div>
								<div class="display" style="margin-left: 30px">
									<iconpark-icon name="message"></iconpark-icon>
									<div style="margin-left: 10px">
										{{ item.comment_num || "0" }}
									</div>
								</div>
								<div class="display" style="margin-left: 30px">
									<iconpark-icon name="star"></iconpark-icon>
									<div style="margin-left: 10px">
										{{ item.collect_num || "0" }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<el-empty description="暂无收藏" v-if="!articleList.length"></el-empty>
			</div>
			<!-- 问答 -->
			<div v-if="tabsActive == 2">
				<div class="qaOne u-flex u-col-top u-row-between" v-for="(item,index) in qaList" :key="index" @click="goInfo(item,3)">
					<el-avatar style="flex: 0 0 36px;" :size="36" :src="item.user_avatar"></el-avatar>
					<div class="u-m-l-10 u-flex-1">
						<div class="u-flex">
							<div class="topInfo u-flex u-m-t-6 u-flex-1">
								<div class="">{{item.user_name}}</div>
								<div class="">{{item.expire_date.split(' ')[0].split('-').join('.')}}发布问题</div>
							</div>
							<div class="more u-flex u-row-center" @click.stop="item.showMore = !item.showMore">
								<i class="el-icon-arrow-up" style="transition: all 0.6s;"
									:class="[item.showMore?'':'rotate']"></i>
							</div>
						</div>
				
						<div class="qaBox">
							<div class="qaTop u-flex u-row-left">
								<div class="u-line-1 u-flex u-flex-1" style="width: 0;">
									<i class="el-icon-chat-line-round u-m-r-4"></i>
									<div class="u-line-1">{{item.title}}</div>
									<div class="reward u-flex" v-if="item.reward_money>0">
										<iconpark-icon name="financing-one"></iconpark-icon>
										<div class="u-m-l-4">悬赏{{item.reward_money}}元</div>
									</div>
								</div>
				
								<div class="category u-flex u-m-l-6" style="margin: 0;" v-if="!item.showMore">
									#{{ item.category_names[0] }}
								</div>
							</div>
							<div class="qaCon u-line-3" v-if="item.showMore">
								{{item.content}}
							</div>
				
							<div class="qaBot u-flex u-m-t-10 u-col-top" v-if="item.showMore">
								<div class="u-flex" style="flex-wrap: wrap;flex: 1;">
									<div class="category u-flex u-m-r-6" v-for="(v,i) in item.category_names" :key="i">
										#{{ v }}
									</div>
								</div>
								<div class="u-flex u-m-l-8" style="color: #717083;font-size: 12px;line-height: 22px;">
									<div class="">
										<i class="el-icon-view u-m-r-4"></i>
										<span>共{{item.read_num}}次查看</span>
									</div>
									<div class="line u-m-l-6 u-m-r-6"
										style="width: 1px;height: 10px;background-color: #717083;"></div>
									<div class="">当前{{item.answer_num+item.comment_num}}人参与</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<el-empty description="暂无收藏" v-if="!qaList.length"></el-empty>
			</div>

			<div class="u-flex u-row-center">
				<el-button round @click="pageAdd" v-if="more">
					加载更多
					<i class="el-icon-arrow-down"></i>
				</el-button>

				<!-- <el-button type="text" v-else>
				没有更多了~
			</el-button> -->
			</div>
		</div>
		<!-- <el-pagination style="text-align: center" @current-change="handleCurrentChange" :current-page.sync="page"
			:page-size="10" layout="total, prev, pager, next" :total="totalNum">
		</el-pagination> -->
	</div>
</template>

<script>
	import {
		GetUserCollection,
		queryFollowArticleList,
		queryFollowQuestionsList,
	} from "@/api/api.js";
	export default {
		name: "MyCollection",
		data() {
			return {
				testList: [], //数据列表
				articleList: [], //文章列表
				qaList: [], //问答列表
				page: 1, //页码
				totalNum: 0,
				tabsActive: 0,
				more: false,
				loading:true
			};
		},
		filters: {
			dateformat(time) {
				const date = new Date(time);
				const year = date.getFullYear();
				const month = (date.getMonth() + 1 + "").padStart(2, "0");
				const day = (date.getDay() + "").padStart(2, "0");
				//   const hour = (date.getHours() + "").padStart(2, "0");
				//   const minute = (date.getMinutes() + "").padStart(2, "0");
				//   const second = (date.getSeconds() + "").padStart(2, "0");
				//   return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
				return `${year}/${month}/${day}`;
			},
		},
		watch: {
			tabsActive(val) {
				this.page = 1;
				this.testList = [] //数据列表
				this.articleList = [] //文章列表
				this.qaList = [] //问答列表
				if (val == 0) {
					this.getList();
				} else if (val == 1) {
					this.getArticle()
				} else if (val == 2) {
					this.getData()
				}

			}
		},
		created() {
			this.getList();
		},
		methods: {
			pageAdd() {
				console.log(this.page)
				this.page++;
				let val = this.tabsActive
				if (val == 0) {
					this.getList();
				} else if (val == 1) {
					this.getArticle()
				} else if (val == 2) {
					this.getData()
				}
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			goInfo(item, type) {
				let arr = []
				let name = ''
				if (type == 1) {
					name = 'TestInfo'
					arr = [{
							name: "心理测试",
							url: "/TestHome"
						},
						{
							name: item.title,
							url: ""
						},
					];
				} else if (type == 2) {
					name = 'ArticleInfo'
					arr = [{
							name: "文章阅读",
							url: "/ArticleHome"
						},
						{
							name: item.title,
							url: ""
						},
					];
				} else if (type == 3) {
					name = 'QaInfo'
					arr = [{
							name: "求助问答",
							url: "/QaHome"
						},
						{
							name: item.title,
							url: ""
						},
					];
				}
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name,
					query: {
						id: item.id
					}
				});
			},
			getData() {
				this.loading = true
				queryFollowQuestionsList({
					operate_id:2,
					page: this.page,
					perpage: 10
				}).then(res => {
					console.log(res)
					res.data.list.forEach(val => {
						val.showMore = true
					})
					let list = res.data.list
					if (!list.length) {
						this.more = false;
						this.page--;
						this.$message.warning('没有更多了！')
					} else {
						this.more = true;
					}
					this.qaList = this.qaList.concat(list);
					this.loading = false
				})
			},
			getArticle() {
				this.loading = true
				queryFollowArticleList({
					operate_id: 2,
					page: this.page,
					perpage: 10
				}).then(res => {
					console.log(res)
					let list = res.data.list
					if (!list.length) {
						this.more = false;
						this.page--;
						this.$message.warning('没有更多了！')
					} else {
						this.more = true;
					}
					this.articleList = this.articleList.concat(list);
					this.loading = false
				})
			},
			getList() {
				this.loading = true
				GetUserCollection({
					page: this.page,
					sort: 1
				}).then((res) => {
					let list = [];
					res.data.list.forEach((item) => {
						list = list.concat(item.data);
					});
					if (!list.length) {
						this.more = false;
						this.page--;
						this.$message.warning('没有更多了！')
					} else {
						this.more = true;
					}
					this.totalNum = res.data.count;
					this.testList = this.testList.concat(list);
					this.loading = false
				});
			},
		},

	};
</script>

<style lang="scss" scoped>
	.content {
		background-color: #fff;
		border-radius: 6px;
		padding: 20px;
		width: 100%;
		max-width: 800px;
		box-sizing: border-box;

		.title {
			padding-bottom: 20px;
			border-bottom: 1px solid #f7f7f7;
			font-weight: 800;
			font-size: 32px;
			color: #4b4b65;
			line-height: 48px;
			width: 100%;

			.lineBox {
				position: relative;
				display: inline-block;

				.font {
					position: relative;
					z-index: 3;
				}

				.line {
					position: absolute;
					bottom: 10px;
					left: 0;
					width: 100%;
					height: 8px;
					background-color: rgb(255, 157, 0);
					z-index: 2;
					opacity: 0.1;
					border-radius: 6px;
				}
			}
		}

		.tabsList {
			margin-top: 20px;

			.tabs {
				background: #fbfbfb;
				border: 1px solid #f4f4f4;
				padding: 7px 20px;
				font-size: 16px;
				font-weight: 600;
				color: #C0C4CC;
				line-height: 22px;
				display: inline-block;
				border-radius: 40px;
				cursor: pointer;
			}

			.tabsActive {
				color: #252631;
			}
		}

		.listOne {
			padding: 30px 0;

			.rightInfo {
				margin-left: 20px;
				flex: 1;

				.topinfo {
					justify-content: space-between;

					font-size: 24px;
					font-weight: 800;
					color: #4b4b65;
					line-height: 36px;

					.testPrice {
						font-size: 16px;
						font-weight: 800;
						color: #f04352;
						line-height: 24px;
					}

					.testFree {
						color: #5473e8;
					}
				}

				.describe {
					font-size: 14px;
					font-weight: 300;
					color: #717083;
					line-height: 20px;
				}

				.bottominfo {
					font-size: 16px;
					font-weight: 400;
					color: #c0c4cc;
					line-height: 22px;
					margin-top: 24px;

					.numberList {
						font-size: 14px;
						color: #c0c4cc;
					}
				}
			}
		}
	}

	.loadMore {
		color: #ffffff;
		background-color: #8e9399;
		border-radius: 18px;
		display: inline-block;
		padding: 4px 8px;
	}
	
	.qaOne {
		padding: 20px 0;
	
		.topInfo {
			font-size: 16px;
			font-weight: 600;
			color: #4B4B65;
			line-height: 22px;
	
			:last-child {
				margin-left: 6px;
				font-size: 14px;
				font-weight: 400;
				color: #C0C4CC;
				line-height: 20px;
			}
		}
	
		.more {
			cursor: pointer;
			font-size: 12px;
			width: 14px;
			height: 14px;
			border: 1px solid #c0c4cc;
			color: #c0c4cc;
		}
	
		.rotate {
			transform: rotate(180deg);
		}
	
		.category {
			background-color: #EEEEEE;
			padding: 4px 12px;
			border-radius: 17px;
			font-size: 12px;
			color: #4B4B65;
			line-height: 14px;
			margin-bottom: 10px;
			box-sizing: content-box;
		}
	
		.qaBox {
			background-color: #FAFAFA;
			width: 100%;
			padding: 20px;
			box-sizing: border-box;
			margin-top: 20px;
			border-radius: 3px;
	
			.qaTop {
				font-size: 16px;
				font-weight: 600;
				color: #252631;
				line-height: 25px;
	
				.reward {
					padding: 2px 6px;
					background: rgba(236, 151, 122, 0.1);
					border-radius: 1px 8px 1px 8px;
					border: 1px solid #EC977A;
					margin-left: 10px;
					font-size: 12px;
					font-weight: 400;
					color: #EC977A;
					line-height: 17px;
					white-space: nowrap;
				}
			}
	
			.qaCon {
				margin-top: 10px;
				font-size: 12px;
				font-weight: 400;
				color: #908F9D;
				line-height: 22px;
			}
	
			.qaBot {}
		}
	}
</style>