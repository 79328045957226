<template>
	<div class="content">
		<div class="topInfo display">
			<div class="leftPic">
				<img src="../../../../assets/image/personal/logoBg.png" alt="" />
			</div>
			<div class="middleInfo">
				<div class="title">
					<span>当前余额</span>
					<span style="margin-left: 10px">¥</span>
					<span style="font-size: 36px">{{ walletInfo.balance_amount }}</span>
				</div>
				<div class="display" style="justify-content: space-between">
					<div class="display u-flex-1" style="
					  height: 54px;
					  flex-direction: column;
					  align-items: flex-start;
					  justify-content: space-between;
					">
						<div class="">
							1、充值后的余额不会过期，不可转赠他人。
						</div>
						<div class="">
							2、充值后的余额可直接用户付费内容消费。也可用于本站其他的服务消费。
						</div>
					</div>
					<div class="recharge" @click="showPay = true">立即充值</div>
				</div>
			</div>
		</div>
		<div class="line"></div>
		<div class="listTab">
			<div class="listTitle display">
				<div class="time">时间</div>
				<div class="info">明细记录</div>
				<div class="change">余额变动</div>
			</div>
			<div class="listMain">
				<div class="listOne display" v-for="(item, index) in dataList" :key="index">
					<!-- <div class="time">{{ (item.createtime * 1000) | dateformat }}</div> -->
					<div class="time">{{ item.createtime_txt }}</div>

					<div class="info">{{ item.memo }}</div>
					<div class="change">
						{{ item.money > 0 ? "+" + item.money : item.money }}币
					</div>
				</div>
				<el-empty description="暂无收藏" v-if="!dataList.length"></el-empty>
			</div>
			<div class="page"></div>
		</div>

		<recharge-popup :show.sync="showPay" @close="showPay = false" @buySuccess="buySuccess" @scanCode="scanCode">
		</recharge-popup>

		<el-dialog :visible.sync="showQrcode" width="30%" :before-close="handleClose" :close-on-press-escape="false"
			:close-on-click-modal="false">
			<div slot="title" style="text-align: center">扫码支付</div>
			<div v-loading="loading" element-loading-text="正在支付">
				<div class="qrcode" id="qrcode" ref="qrcode"></div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		GetUserWalletDetail,
		GetPayState,
		GetUserWallet
	} from "@/api/api.js";
	import RechargePopup from "@/components/RechargePopup/RechargePopup.vue";
	import QRCode from "qrcodejs2";
	let timer = null;
	export default {
		name: "MyBalance",
		data() {
			return {
				dataList: [],
				showPay: false,
				showQrcode: false,
				loading: false,
			};
		},
		filters: {
			dateformat(time) {
				const date = new Date(time);
				const year = date.getFullYear();
				const month = (date.getMonth() + 1 + "").padStart(2, "0");
				const day = (date.getDay() + "").padStart(2, "0");
				const hour = (date.getHours() + "").padStart(2, "0");
				const minute = (date.getMinutes() + "").padStart(2, "0");
				const second = (date.getSeconds() + "").padStart(2, "0");
				return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
			},
		},
		components: {
			RechargePopup,
		},
		computed: {
			walletInfo: {
				get() {
					return this.$store.state.walletInfo;
				},
				set() {},
			},
		},
		methods: {
			handleClose() {
				clearInterval(timer);
				this.showQrcode = false;
				this.$refs.qrcode.innerHTML = "";
			},
			buySuccess() {},
			scanCode(data) {
				this.showQrcode = true;
				this.$nextTick(() => {
					let qrcode = new QRCode("qrcode", {
						width: 200, // 宽
						height: 200, // 高
						text: data.response.code_url, // 二维码内容,也可以直接放接口获取的数据
						// render: 'canvas'     // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
						// background: '#f0f'   // 自定义颜色默认黑白
						// foreground: '#ff0'
					});
					console.log(qrcode);
				});
				this.showPay = false;

				timer = setInterval(() => {
					GetPayState({
						out_trade_no: data.out_trade_no
					}).then((res) => {
						console.log(res);
						if (res.data == "SUCCESS") {
							GetUserWallet().then((res) => {
								this.$store.commit("setwalletInfo", res.data.wallet);
							});
							this.getWalletList();
							this.$message.success("支付成功");
							this.handleClose();
						} else if (res.data == "USERPAYING") {
							this.loading = true;
						} else if (res.data == "PAYERROR") {
							this.$message.error("支付失败");
							this.handleClose();
						} else if (res.data == "REVOKED") {
							this.$message.error("已撤销");
							this.handleClose();
						} else if (res.data == "CLOSED") {
							this.$message.error("已关闭");
							this.handleClose();
						}
					});
				}, 1000);
			},
			getWalletList() {
				GetUserWalletDetail().then((res) => {
					let arr = res.data.list;
					arr.forEach((item, index) => {
						let newArr = item.memo.split("[");
						arr[index].subTitle = newArr[0];
						arr[index].subTitle1 = newArr[1] ? "[" + newArr[1] : null;
					});
					this.dataList = arr;
				});
			},
		},
		created() {
			this.getWalletList();
		},
	};
</script>

<style scoped lang="scss">
	.content {
		background-color: #fff;
		border-radius: 6px;
		//   padding: 30px;
		// width: 1010px;
		width: 100%;
		max-width: 800px;
		box-sizing: border-box;

		.topInfo {
			justify-content: flex-start;

			.leftPic {
				margin-right: 15px;
				display: block;
				width: 30%;
				// height: 199px;
				margin-bottom: 20px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.middleInfo {
				flex: 1;
				font-size: 16px;
				font-weight: 400;
				color: #717083;
				line-height: 18px;
				margin-top: 50px;

				.title {
					font-size: 18px;
					font-weight: 600;
					color: #252631;
					line-height: 25px;
					margin-bottom: 10px;
				}
			}

			.recharge {
				text-align: center;
				background: #4263e3;
				padding: 0 23px 0 24px;
				border-radius: 6px;
				font-size: 18px;
				font-weight: 600;
				color: #ffffff;
				line-height: 54px;
				margin-right: 20px;
				height: 54px;
				box-sizing: border-box;
			}
		}

		.line {
			// width: 950px;
			width: 100%;
			margin: 0 auto;
			height: 1px;
			background-color: #f7f7f7;
		}

		.listTab {
			padding: 0 30px;

			.listTitle {
				padding: 30px 0 20px;
				border-bottom: 1px solid #f7f7f7;
			}

			.listOne {
				padding: 20px 0;
				border-bottom: 1px solid #f7f7f7;
			}

			.time {
				flex: 0 0 300px;
			}

			.info {
				flex: 1;
			}

			.change {
				text-align: right;
				flex: 0 0 200px;
			}
		}
	}

	.qrcode {
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>