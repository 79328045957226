<template>
  <div class="content">
      <nav-bar :activeId="0" type="1" :iscollection="false"></nav-bar>
      <div class="mainApp display">
        <router-view></router-view>
        <!-- <right-list style="margin-left:20px;"></right-list> -->
      </div>
      <tab-bar></tab-bar>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar.vue'
import TabBar from '@/components/TabBar/TabBar.vue'
// import RightList from '@/components/RightList/RightList.vue'
export default {
    name:'SecondPage',
    data() {
        return {
            
        }
    },
    components:{
        NavBar,
        TabBar,
        // RightList
    },
    methods: {
        
    },
    created() {
    },
}
</script>

<style scoped lang="scss">
.mainApp{
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  min-width: 1390px;
}
</style>