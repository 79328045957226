<template>
	<div class="content">
		<div class="topPicList">
			<el-carousel style="border-radius: 6px" trigger="click" height="320px" :interval="5000">
				<el-carousel-item v-for="(item, index) in bannerList" :key="index">
					<el-image  @click="bannerFunc(item)" style="width: 100%; height: 100%" :src="item.image" fit="cover">
					</el-image>
				</el-carousel-item>
			</el-carousel>

			<div class="classification">
				<div class="btn left" @click="nextScroll(0)">
					<i class="el-icon-arrow-left"></i>
				</div>
				<div class="classList display">
					<div v-for="(item, index) in classList" :key="index"
						:class="['classOne', activeName == index ? 'active' : '']" @click="handleClick(index, item.id)">
						{{ item.name }}
					</div>
				</div>
				<div class="btn right" @click="nextScroll(1)">
					<i class="el-icon-arrow-right"></i>
				</div>
			</div>
		</div>

		<div class="mainCon" v-loading="loading">
			<div class="title" v-if="!loading">
				{{ classList[activeName].name }}分类
			</div>
			<div class="subtit">走过不要错过，你身上的秘密都在这里</div>

			<div class="btn display">
				<div class="btn1" @click="getfollowList">
					<i class="el-icon-star-off"></i>
					<div class="btnfont" style="">关注</div>
				</div>

				<el-dropdown @command="changeSort">
					<div class="btn1">
						<i class="el-icon-s-fold"></i>
						<div class="btnfont">
							{{ sortList[select - 1].name.slice(0, 2) }}
						</div>
					</div>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item v-for="(item, index) in sortList" :key="index" :command="item.val">
							{{ item.name }}</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>

			<div class="testList">
				<el-row :gutter="16">
					<el-col :span="8" v-for="(item, index) in testList" :key="index">
						<div class="testOne pointer" @click="goInfo(item.id, item.title)">
							<div style="width: 100%; height: 200px; overflow: hidden">
								<el-image style="width: 100%; height: 200px" :src="item.test_cover_image" fit="cover"
									class="imgBig" alt='图片'></el-image>
							</div>

							<div class="testinfo">
								<div class="infotop display">
									<div class="testName overflow">
										{{ item.title }}
									</div>
									<div v-if="item.buynum!=2" class="testPrice" style="white-space: nowrap;"
										:class="[!item.price ? 'testFree' : '']">
										{{ item.price ? "¥" + item.price : "免费" }}
									</div>
									<div v-else class="testPrice" :class="[!item.price ? 'testFree' : '']">
										已购买
									</div>
								</div>
								<div class="infomiddle">{{ item.category_name }}测试</div>
								<div class="infobottom display">
									<div style="white-space: nowrap;">
										<i class="el-icon-star-off"></i>
										{{ item.collection_num || "0" }}
									</div>
									<div style="white-space: nowrap;" class="u-m-l-6">
										<i class="el-icon-chat-dot-round"></i>
										{{ item.evaluation_num || "0" }}
									</div>
									<div class="time u-line-1 u-m-l-4" style="flex: 1; text-align: right;" v-if="item.test_createtime">
										{{ item.test_createtime | dateformat1 }}
									</div>
									<div v-else class="time u-line-1 u-m-l-4" style="flex: 1; text-align: right">
										<!-- {{ formatTime(item.createtime * 1000,'{y}.{m}.{d}')}} -->
										{{ (item.createtime * 1000) | dateformat }}
									</div>
								</div>
							</div>
						</div>
					</el-col>

					<el-empty description="暂无测试～" v-if="!testList.length"></el-empty>
				</el-row>

				<!-- 分页 -->
				<el-pagination v-if="!isFollow" style="text-align: center;width: 100%;overflow-x: auto;" @size-change="handleSizeChange"
					:pager-count="5" @current-change="handleCurrentChange" :current-page.sync="page" :page-size="9"
					:page-sizes="[9, 30, 60, 90]" layout="total, sizes, prev, pager, next, jumper" :total="totalNum">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		GetCategoryList,
		GetTestList,
		GetUserCollection,
		GetMental,
		GetSlideshow,
	} from "@/api/api.js";
	export default {
		name: "TestHome",
		data() {
			return {
				activeName: 0, //当前激活项
				classList: [], //分类列表
				testList: [], //题目列表
				loading: true, //加载
				page: 1, //当前页码
				bannerList: [], //banner列表
				totalNum: 0,
				limit: 9,
				sortList: [{
						name: "综合排序",
						val: 1,
					},
					{
						name: "最新上架",
						val: 2,
					},
					{
						name: "最热测试",
						val: 3,
					},
				],
				select: 1,
				isFollow: false,
			};
		},
		filters: {
			dateformat(time) {
				const date = new Date(time);
				const year = date.getFullYear();
				const month = (date.getMonth() + 1 + "").padStart(2, "0");
				const day = (date.getDay() + "").padStart(2, "0");
				//   const hour = (date.getHours() + "").padStart(2, "0");
				//   const minute = (date.getMinutes() + "").padStart(2, "0");
				//   const second = (date.getSeconds() + "").padStart(2, "0");
				//   return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
				return `${year}.${month}.${day}`;
			},
			dateformat1(time) {
				let arr = time.split(" ")[0].split("-");
				return arr[0] + "." + arr[1] + "." + arr[2];
			},
		},
		methods: {
			goUrl(item) {
				if (item.link_type == 2) {
					//测试题链接
					this.goInfo(item.link)
				} else if (item.link_type == 3) {
					window.open(item.link, '_blank')
				} else if (item.link_type == 1) {
					//文章链接
				}
			},
			getfollowList() {
				if (this.$store.state.token) {
					this.page = 1;
					this.activeName = 0;
					this.totalNum = this.classList[0].num;
					this.loading = true;
					GetUserCollection({
						page: this.page,
						token: this.$store.state.token,
						sort: 0,
					}).then((res) => {
						this.loading = false;
						this.isFollow = true
						let list = [];
						res.data.list.forEach((item) => {
							list = list.concat(item.data);
						});
						this.totalNum = res.data.count;
						this.testList = list;
					});
				} else {
					this.goLogin();
				}
			},
			goLogin() {
				this.$router.push({
					name: "LoginPage",
					query: {
						back: true
					}
				});
			},
			goInfo(id, title) {
				let arr = [{
						name: "心理测试",
						url: "/TestHome"
					},
					{
						name: title,
						url: ""
					},
				];
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "TestInfo",
					query: {
						id
					}
				});
			},
			changeSort(val) {
				this.select = val;
				this.gettestList();
			},
			handleSizeChange(val) {
				this.limit = val;
				this.gettestList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.gettestList();
			},
			handleClick(index) {
				this.page = 1;
				this.activeName = index;
				this.totalNum = this.classList[index].num;
				this.gettestList();
			},
			nextScroll(type) {
				let box = this.$el.querySelector(".classList");
				if (type) {
					box.scrollLeft += 200;
				} else {
					box.scrollLeft -= 200;
				}
			},
			//获取测试列表
			gettestList() {
				let data = {
					ids: this.classList[this.activeName].id,
					page: this.page,
					limit: this.limit,
					select: this.select,
				};
				GetTestList(data).then((res) => {
					this.isFollow = false
					this.testList = res.data.list;
				});
			},
		},
		created() {
			GetSlideshow({
				type: 3,
				place:4
			}).then((res) => {
				this.bannerList = res.data.list;
			});
			GetCategoryList().then((res) => {
				this.classList = res.data.list;
				this.totalNum = res.data.list[0].num;
				this.testList = res.data.test_list.slice(0, 9);
				this.loading = false;
			});
		},
	};
</script>

<style scoped lang="scss">
	.el-carousel__item h3 {
		color: #475669;
		font-size: 14px;
		opacity: 0.75;
		line-height: 150px;
		margin: 0;
	}

	.el-carousel__item:nth-child(2n) {
		background-color: #99a9bf;
	}

	.el-carousel__item:nth-child(2n + 1) {
		background-color: #d3dce6;
	}

	::v-deep .classList::-webkit-scrollbar {
		display: none;
	}

	.classList {
		scrollbar-width: none;
	}

	.content {
		// width: 960px;
		// min-width: 960px;
		width: calc(100% - 400px);
		min-width: calc(100% - 400px);

		.topPicList {
			background-color: #fff;
			border-radius: 6px;
			overflow: hidden;
			transition: all 1s;
		}

		.classification {
			margin-top: 20px;
			padding: 0 34px;
			width: 100%;
			box-sizing: border-box;
			position: relative;
			transition: all 1s;

			&:hover .btn {
				opacity: 1;
			}

			.btn {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;
				font-size: 24px;
				cursor: pointer;
				background-color: rgba(0, 0, 0, 0.4);
				color: #ffffff;
				border-radius: 6px;
			}

			.left {
				left: 10px;
			}

			.right {
				right: 10px;
			}

			.classList {
				width: 100%;
				overflow-x: auto;

				.classOne {
					white-space: nowrap;
					padding: 20px;
					margin: 0 20px;
					cursor: pointer;
				}

				.active {
					background-color: #f7f7f7;
					border-radius: 6px 6px 0 0;
					font-size: 18px;
					font-weight: 600;
					color: #4b4b65;
					line-height: 25px;
					position: relative;

					&::after {
						content: "";
						width: 15px;
						height: 3px;
						background-color: #4b4b65;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}
		}

		.mainCon {
			margin-top: 30px;
			background-color: #fff;
			border-radius: 6px;
			padding: 20px;

			.title {
				text-align: center;
				font-size: 24px;
				font-weight: 600;
				color: #4b4b65;
				line-height: 33px;
			}

			.subtit {
				text-align: center;
				font-size: 14px;
				font-weight: 400;
				color: #c0c4cc;
				line-height: 20px;
				margin-top: 10px;
			}

			.btn {
				text-align: center;
				justify-content: center;
				margin-bottom: 30px;

				.btn1 {
					width: 36px;
					height: 36px;
					line-height: 36px;
					background: #f7f7f7;
					border-radius: 6px;
					margin: 10px 15px 0;

					.btnfont {
						font-size: 12px;
						color: #c0c4cc;
						line-height: 17px;
						margin-top: 5px;
					}
				}
			}

			.testList {
				.testOne {
					// width: 280px;
					width: 100%;
					border-radius: 6px;
					overflow: hidden;
					border: 1px solid #eaeef3;
					margin-bottom: 16px;

					.imgBig:hover {
						transition: all 0.7s;
						// transform: scale(1.5,1.5);
					}
				}

				.testinfo {
					padding: 20px;

					.infotop {
						font-size: 16px;
						line-height: 22px;
						color: #4b4b65;
						justify-content: space-between;
						font-weight: 600;

						.testName {
							width: 190px;
						}

						.testPrice {
							font-size: 16px;
							font-family: SourceHanSansCN-Heavy, SourceHanSansCN;
							font-weight: 800;
							color: #f04352;
							line-height: 24px;
						}

						.testFree {
							color: #5473e8;
						}
					}

					.infomiddle {
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #b6b5c5;
						line-height: 17px;
						margin: 10px 0 23px;
					}

					.infobottom {
						color: #c0c4cc;
						font-size: 12px;
						line-height: 17px;
					}
				}
			}
		}
	}
</style>
