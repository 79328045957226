import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//页面框架 
import HomePage from '@/view/HomePage/HomePage.vue'
import SecondPage from '@/view/SecondPage/SecondPage.vue'
import PersonalCenter from '@/view/PersonalCenter/PersonalCenter.vue'
import AboutUs from '@/view/AboutUs/AboutUs.vue'
import LoginPage from '@/view/LoginPage/LoginPage.vue'

import TestAnswer from '@/view/TestAnswer/TestAnswer.vue'
//页面组建
import AllHome from '@/view/HomePage/components/AllHome/AllHome.vue'
import TestHome from '@/view/HomePage/components/TestHome/TestHome.vue'
import TestInfo from '@/view/SecondPage/components/TestInfo/TestInfo.vue'
import QaInfo from '@/view/SecondPage/components/QaInfo/QaInfo.vue'
import ArticleInfo from '@/view/ArticleInfo/ArticleInfo.vue'
import PersonalPage from '@/view/PersonalPage/PersonalPage.vue'
import GoApp from '@/view/PersonalCenter/components/GoApp/GoApp.vue'
import MyBalance from '@/view/PersonalCenter/components/MyBalance/MyBalance.vue'
import HelpCenter from '@/view/PersonalCenter/components/HelpCenter/HelpCenter.vue'
import MyCollection from '@/view/PersonalCenter/components/MyCollection/MyCollection.vue'
import AboutPage from '@/view/AboutUs/components/AboutPage.vue'


import ArticleHome from '@/view/HomePage/components/ArticleHome/ArticleHome.vue'
import QaHome from '@/view/HomePage/components/QaHome/QaHome.vue'
import ZxsHome from '@/view/HomePage/components/ZxsHome/ZxsHome.vue'
import ZxsInfo from '@/view/ZxsInfo/ZxsInfo.vue'


//这里进行路由路径的基本配置
let routes = [{
	path: '/LoginPage',
	name: 'LoginPage',
	component: LoginPage,
	meta: {
		title: '登录'
	}
}, {
	path: '/HomePage',
	name: 'HomePage',
	redirect: '/AllHome',
	component: HomePage,
	children: [{
		path: '/AllHome',
		name: 'AllHome',
		component: AllHome,
		meta: {
			title: '首页'
		}
	}, {
		path: '/TestHome',
		name: 'TestHome',
		component: TestHome,
		meta: {
			title: '心理测试'
		}
	}, {
		path: '/ZxsHome',
		name: 'ZxsHome',
		component: ZxsHome,
		meta: {
			title: '心理咨询'
		}
	}, {
		path: '/QaHome',
		name: 'QaHome',
		component: QaHome,
		meta: {
			title: '求助问答'
		}
	}, {
		path: '/ArticleHome',
		name: 'ArticleHome',
		component: ArticleHome,
		meta: {
			title: '文章阅读'
		}
	}]
}, 
{
	path: '/ArticleInfo',
	name: 'ArticleInfo',
	component: ArticleInfo
}, {
	path: '/PersonalPage',
	name: 'PersonalPage',
	component: PersonalPage
}, {
	path: '/SecondPage',
	name: 'SecondPage',
	redirect: '/TestInfo',
	component: SecondPage,
	children: [{
		path: '/TestInfo',
		name: 'TestInfo',
		component: TestInfo,
		meta: {
			title: '测试详情'
		}
	}, {
		path: '/QaInfo',
		name: 'QaInfo',
		component: QaInfo,
		meta: {
			title: '问答详情'
		}
	}]
}, {
	path: '/AboutUs',
	name: 'AboutUs',
	component: AboutUs,
	redirect: '/AboutPage',
	meta: {
		title: '关于我们'
	},
	children: [{
		path: '/AboutPage',
		name: 'AboutPage',
		component: AboutPage,
		meta: {
			title: '关于我们'
		}
	}]
}, {
	path: '/PersonalCenter',
	name: 'PersonalCenter',
	redirect: '/GoApp',
	component: PersonalCenter,
	children: [{
		path: '/GoApp',
		name: 'GoApp',
		component: GoApp,
		meta: {
			title: '个人中心'
		}
	}, {
		path: '/MyBalance',
		name: 'MyBalance',
		component: MyBalance,
		meta: {
			title: '我的余额'
		}
	}, {
		path: '/MyCollection',
		name: 'MyCollection',
		component: MyCollection,
		meta: {
			title: '我的收藏'
		}
	}, {
		path: '/HelpCenter',
		name: 'HelpCenter',
		component: HelpCenter,
		meta: {
			title: '帮助中心'
		}
	}]
}, {
	path: '/TestAnswer',
	name: 'TestAnswer',
	component: TestAnswer,
}, {
	path: '/ZxsInfo',
	name: 'ZxsInfo',
	component: ZxsInfo,
}, {
	path: '/',
	redirect: '/HomePage'
}, ];
const router = new VueRouter({
	routes,
	scrollBehavior() { // 解决vue页面之间跳转，页面不是在顶部的问题
		return {
			x: 0,
			y: 0
		}
	}
})


// // 导航守卫    //可以判断用户是否登陆    可以设置标题之类的

//解决重复点击路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

//解决重复点击路由报错
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch(err => err)
}

import store from '@/store'
router.beforeEach(async (to, from, next) => {
	console.log(to, from)
	//设置导航栏激活下标
	if (to.name == 'ZxsHome') {
		store.commit('changeId', 2)
	} else if (to.name == 'AllHome') {
		store.commit('changeId', 0)
	} else if (to.name == 'TestHome') {
		store.commit('changeId', 1)
	} else if (to.name == 'QaHome') {
		store.commit('changeId', 4)
	} else if (to.name == 'ArticleHome') {
		store.commit('changeId', 3)
	}
	next()
})



export default router;