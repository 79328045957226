<template>
	<div class="content">
		<nav-bar :activeId="0" type="1" :iscollection="true" :isAr="true"></nav-bar>
		<div style="width: 100%;">
			<el-row style="width: 100%;">
				<el-col :span="24" class="u-m-b-20">
					<div class="personalTop u-m-t-20">
						<div class="u-flex u-row-between u-col-bottom">
							<div class="avatarBox">
								<el-avatar class="avatar" :size="100" :src="userInfo.avatar"></el-avatar>
								<div class="gender u-flex u-row-center" v-if="userInfo.gender!=2">
									<i class="el-icon-male" style="color: #FF7695;" v-if="userInfo.gender==0"></i>
									<i class="el-icon-female" style="color: #5473E8;" v-if="userInfo.gender==1"></i>
								</div>
							</div>
							<div class="userName u-flex-1 u-m-l-20 u-m-b-4">
								<div class="u-m-b-14">{{userInfo.nickname}}</div>
								<div class="">{{userInfo.bio || '-'}}</div>
							</div>
							<div class="dim u-flex u-row-right u-m-b-26">
								<div class="u-flex u-m-r-20">
									<i class="el-icon-location"></i>
									<span>北京市</span>
								</div>
								<div class="">
									双子座
								</div>
							</div>
						</div>
						<div class="personInfoBox">
							<div class="personInfo u-m-t-30 u-flex u-row-around">
								<div class="personOne">
									<div class="">-</div>
									<div class="">参与问题</div>
								</div>
								<div class="personOne">
									<div class="">-</div>
									<div class="">关注Ta的</div>
								</div>
								<div class="personOne">
									<div class="">-</div>
									<div class="">Ta收藏的</div>
								</div>
							</div>
							<div class="filterBox">
								用户已隐藏信息
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="24" style="width: 100%;">
					<div style="width: 90%; margin: 0 auto;">
						<el-row :gutter="20">
							<el-col :span="8">
								<right-list :personal="true"></right-list>
							</el-col>
							<el-col :span="16">
								<div class="trends">
									<div class="nav">
										<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal"
											@select="handleSelect" active-text-color="#252631">
											<el-menu-item index="1">动态</el-menu-item>
											<el-menu-item index="2">Ta的文章</el-menu-item>
											<el-menu-item index="3">Ta的问答</el-menu-item>
											<el-menu-item index="4">收藏</el-menu-item>
											<el-menu-item index="5">关注</el-menu-item>
										</el-menu>
									</div>
									<div class="u-m-t-30">
										<el-empty description="用户已隐藏动态信息~"></el-empty>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</el-col>
			</el-row>
		</div>
		<tab-bar></tab-bar>
	</div>
</template>

<script>
	import NavBar from '@/components/NavBar/NavBar.vue'
	import TabBar from '@/components/TabBar/TabBar.vue'
	import RightList from '@/components/RightList/RightList.vue'
	import {
		getCommunityWebUserHomePage
	} from '@/api/api.js'
	export default {
		data() {
			return {
				userInfo: {},
				activeIndex:"1"
			}
		},
		components: {
			NavBar,
			TabBar,
			RightList
		},
		mounted() {
			this.getData()
		},
		methods: {
			handleSelect(e){
				console.log(e)
			},
			getData() {
				getCommunityWebUserHomePage({
					user_id: this.$route.query.id
				}).then(res => {
					console.log(res)
					this.userInfo = res.data.info
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		.personalTop {
			width: 90%;
			margin: 0 auto;
			padding: 30px 50px;
			background: #ffffff;
			border-radius: 8px;
			user-select: none;
			
			.avatarBox {
				position: relative;

				.avatar {
					border: 5px solid #F4F4F4;
				}

				.gender {
					position: absolute;
					top: 0;
					right: 0;
					width: 30px;
					height: 30px;
					background: #F7F7F7;
					font-size: 18px;
					border-radius: 50%;
				}
			}

			.userName {
				>:first-child {
					font-size: 20px;
					font-weight: 600;
					color: #252631;
					line-height: 28px;
				}

				>:last-child {
					font-size: 16px;
					font-weight: 400;
					color: #717083;
					line-height: 22px;
				}
			}

			.dim {
				filter: blur(3px);
				position: relative;

				>:first-child {
					font-size: 14px;
					font-weight: 400;
					color: #717083;
					line-height: 22px;
				}

				>:last-child {
					padding: 5px 14px;
					font-size: 14px;
					font-weight: 600;
					color: #30AD98;
					line-height: 22px;

					background: #FBFBFB;
					border-radius: 5px;
					border: 1px solid #F4F4F4;
				}
			}

			.personInfoBox {
				position: relative;

				.filterBox {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
			}

			.personInfo {
				width: 100%;
				padding: 20px;
				background: #F9F9F9;
				border-radius: 3px;
				filter: blur(3px);

				.personOne {
					text-align: center;

					>:first-child {
						font-size: 40px;
						font-weight: 600;
						color: #252631;
						line-height: 56px;
					}

					>:last-child {
						font-size: 14px;
						font-weight: 400;
						color: #252631;
						line-height: 20px;
					}
				}
			}
		}

		.trends {
			width: 100%;
			background-color: #fff;
			border-radius: 8px;
			overflow: hidden;
			.nav{
				padding: 20px;
				width: 100%;
			}
		}
	}
</style>