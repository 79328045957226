<template>
  <div class="content">
    <nav-bar :activeId="0" type="1"></nav-bar>
    <div class="mainApp display u-flex u-row-center">
      <personal-left></personal-left>
      <router-view style="margin-left:20px;"></router-view>
    </div>
    <tab-bar></tab-bar>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar/NavBar.vue";
import TabBar from "@/components/TabBar/TabBar.vue";
import PersonalLeft from "@/components/PersonalLeft/PersonalLeft.vue";
import { GetUserWallet } from '@/api/api.js'
export default {
  name: "PersonalCenter",
  data() {
    return {};
  },
  components: {
    NavBar,
    TabBar,
    PersonalLeft,
  },
  methods: {},
  created() {
      GetUserWallet().then(res=>{
          this.$store.commit('setwalletInfo',res.data.wallet)
      })
  },
};
</script>

<style scoped lang="scss">
.mainApp {
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  margin-top: 30px;
  // width: 100%;
  max-width: 1390px;
}
</style>