<template>
	<div class="content">
		<el-image v-if="detailInfo.test_cover_image && testType == 2"
			style="width: 100%;  border-radius: 6px; display: block" fit="cover"
			:src="detailInfo.test_cover_image"></el-image>

		<el-image v-if="detailInfo.results_cover_image && testType == 1"
			style="width: 100%;  border-radius: 6px; display: block" fit="cover"
			:src="detailInfo.results_cover_image"></el-image>

		<div class="testInfo display" style="width: 100%;flex-wrap: wrap;">
			<div class="infoleft" style="padding: 20px;">
				<div class="display" style="justify-content: space-between">
					<div class="">
						{{ detailInfo.title }}
					</div>
					<div v-if="pageInfo.buynum!=2" :class="['price', detailInfo.price ? 'redcolor' : '']">
						{{ detailInfo.price ? "¥" + detailInfo.price : "当前免费" }}
					</div>
					<div v-else :class="['price', detailInfo.price ? 'redcolor' : '']">已购买</div>
				</div>
				<div class="time display">
					<div class="">
						<span>{{ (detailInfo.createtime * 1000) | dateformat }}发布</span>
						<span style="margin-left: 10px; color: #717083">#{{ detailInfo.category_name }} /
							{{ detailInfo.title_num }}道题目</span>
					</div>
					<div class="numberList display" style="flex: 1; justify-content: flex-end">
						<div class="display">
							<iconpark-icon name="preview-open"></iconpark-icon>
							<div style="margin-left: 10px">
								{{ detailInfo.test_num || "0" }}
							</div>
						</div>
						<div class="display" style="margin-left: 30px">
							<iconpark-icon name="message"></iconpark-icon>
							<div style="margin-left: 10px">{{ eva_count || "0" }}</div>
						</div>
						<div class="display" style="margin-left: 30px">
							<iconpark-icon name="star"></iconpark-icon>
							<div style="margin-left: 10px">
								{{ detailInfo.collection_num || "0" }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="inforight u-flex u-row-around" style="flex-wrap: wrap;padding: 0 10px;" v-if="testType == 2">
				<div class="goTest u-m-8" @click="goTest">立即测试</div>
				<div class="customer u-m-8" style="margin: 0;">
					<iconpark-icon class="middle" name="headset-one-52b5a2fl"></iconpark-icon>
					<span class="middle" style="margin-left: 5px">客服</span>

					<div class="serve">
						<img src="../../../../assets/image/serveQr.jpg" alt="" />
					</div>
				</div>
			</div>

			<div class="inforight display" v-else>
				<div class="goTest goApp">
					<span>咨询专家</span>
					<div class="downLoad">
						<img style="width: 200px; height: 200px" src="../../../../assets/image/downLoadUser.png" />
					</div>
				</div>

				<div class="customer" @click="reTest">
					<iconpark-icon class="middle" name="redo"></iconpark-icon>
					<span class="middle" style="margin-left: 5px">重测</span>
				</div>
			</div>
		</div>

		<div class="introduce">
			<div v-html="detailInfo.introduce" v-if="testType == 2"></div>
			<div v-if="testType == 1">
				<div class="" style="
            font-size: 24px;
            font-weight: 600;
            color: #4b4b65;
            margin-bottom: 30px;
          ">
					测试结果分析
				</div>
				<div class="">{{ result_title }}</div>
				<div v-html="result_content"></div>
			</div>
		</div>

		<div class="func display">
			<div class="funcL">
				<iconpark-icon class="middle" name="message"></iconpark-icon>
				<span class="middle" style="margin: 0 3px 0 12px">回复评论</span>
				<span class="evaNum" v-if="eva_count">{{
          eva_count > 99 ? "99+" : eva_count
        }}</span>
			</div>
			<!-- <div class="funcR">
        <iconpark-icon class="middle" name="share"></iconpark-icon>
        <span class="middle" style="margin-left: 10px">分享</span>
      </div> -->
		</div>

		<div class="evaList">
			<div class="nodata" v-if="!eva_count">
				<span>当前暂无评论~</span>
			</div>
			<div class="login" v-if="!userInfo.avatar && testType == 1">
				<span style="color: #5473e8; cursor: pointer" @click="goLogin">注册/登录</span>
				<span>进行评论</span>
			</div>
			<div class="eva">
				<div class="evaOne" v-for="(item, index) in evaList" :key="index">
					<div class="evaTop display">
						<div class="avatar">
							<el-avatar :size="50" :src="item.avatar"></el-avatar>
						</div>
						<div class="nickname" style="margin: 0 10px">
							{{ item.anonymous }}
						</div>
						<div class="time">
							{{ item.createtime }}
						</div>
					</div>
					<div class="evaBot display">
						<div style="height: 50px; width: 50px; margin-right: 10px"></div>
						<div v-html="item.content"></div>
					</div>
				</div>

				<div class="loadMore display" v-if="more" @click="addPage">
					<span>展开更多</span>
					<div class="circle">
						<iconpark-icon name="down"></iconpark-icon>
					</div>
				</div>

				<div class="writeEva display" v-if="testType == 1">
					<div class="avatar">
						<el-avatar :size="50" :src="userInfo.avatar || ''"></el-avatar>
					</div>
					<div class="textarea" style="flex: 1; margin-left: 10px">
						<el-input rows="10" type="textarea" resize="none" v-model="value"></el-input>
					</div>
				</div>

				<div class="submit" v-if="testType == 1" @click="submitEva">发表</div>
			</div>
		</div>

		<pay-popup :show.sync="showPay" :detailInfo="detailInfo" @close="showPay = false" @buySuccess="buySuccess">
		</pay-popup>
	</div>
</template>

<script>
	import {
		GetTestDetails,
		TestEnter,
		GetTestResult,
		SubmitEva,
	} from "@/api/api.js";
	import PayPopup from "@/components/PayPopup/PayPopup.vue";
	export default {
		name: "TestInfo",
		data() {
			return {
				page: 1,
				detailInfo: {},
				eva_count: 0,
				evaList: [],
				more: true,
				value: "",
				showPay: false, //支付弹窗
				log_id: null, //做题记录
				testType: 2, //是否结果页面
				result_title: "", //结果标题
				result_content: "", //结果内容

				pageInfo: {}, //页面内容
			};
		},
		components: {
			PayPopup,
		},
		filters: {
			dateformat(time) {
				const date = new Date(time);
				const year = date.getFullYear();
				const month = (date.getMonth() + 1 + "").padStart(2, "0");
				const day = (date.getDay() + "").padStart(2, "0");
				//   const hour = (date.getHours() + "").padStart(2, "0");
				//   const minute = (date.getMinutes() + "").padStart(2, "0");
				//   const second = (date.getSeconds() + "").padStart(2, "0");
				//   return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
				return `${year}/${month}/${day}`;
			},
		},
		watch: {
			$route() {
				this.page = 1;
				this.getList();
			},
			userInfo: {
				handler: function(val) {
					this.userInfo = val;
				},
				immediate: true,
			},
		},
		mounted() {
			this.$store.commit('changeId', 1)
		},
		computed: {
			userInfo: {
				get() {
					return this.$store.state.userInfo;
				},
				set() {},
			},
		},
		methods: {
			reTest() {
				this.$router.push({
					name: "TestAnswer",
					query: {
						id: this.detailInfo.id
					},
				});
			},
			submitEva() {
				let evaluate = this.value;
				evaluate = evaluate.replace(/ +/g, "");
				evaluate = evaluate.replace(/[ ]/g, "");
				evaluate = evaluate.replace(/[\r\n]/g, "");
				console.log(111, evaluate);

				if (evaluate.length > 0) {
					SubmitEva({
						log_id: this.$route.query.successId,
						content: evaluate,
					}).then((res) => {
						console.log(res);
						this.value = "";
						if (res.code == 1) {
							this.$message.success("发布成功");
							this.page = 1;
							this.evaList = [];
							this.getresult();
						} else {
							this.$message.error(res.msg);
						}
					});
				} else {
					this.$message.error("请输入评论内容");
				}
			},
			buySuccess() {
				this.page = 1;
				this.getList(1);
				this.showPay = false;
			},
			goTest() {
				if (this.$store.state.token) {
					console.log(this.pageInfo);
					if (this.detailInfo.buycode == 0 || this.pageInfo.buynum == 0 || this.pageInfo.buynum == 3) {
						console.log("需要购买");
						this.showPay = true;
					} else {
						//直接去做题
						TestEnter({
							test_id: this.detailInfo.id
						}).then((res) => {
							console.log(res);
							if (res.data.log_id == "") {
								//没有记录直接跳转
								this.$router.push({
									name: "TestAnswer",
									query: {
										id: this.detailInfo.id
									},
								});
							} else {
								//有做题记录
								this.log_id = res.data.log_id;

								this.$confirm("您有未完成的测试，是否继续？", "提示", {
										confirmButtonText: "确定",
										cancelButtonText: "取消",
										type: "warning",
									})
									.then(() => {
										let log_id = this.log_id;
										//跳转页面携带logid
										this.$router.push({
											name: "TestAnswer",
											query: {
												id: this.detailInfo.id,
												log_id: log_id
											},
										});
									})
									.catch(() => {
										//直接跳转页面
										this.$router.push({
											name: "TestAnswer",
											query: {
												id: this.detailInfo.id
											},
										});
									});
							}
						});
					}
				} else {
					this.goLogin();
				}
			},
			goLogin() {
				this.$router.push({
					name: "LoginPage",
					query: {
						back: true
					}
				});
			},
			addPage() {
				this.page += 1;
				this.getList();
			},
			getList(type) {
				GetTestDetails({
					test_id: this.$route.query.id,
					page: this.page
				}).then(
					(res) => {
						this.pageInfo = res.data
						this.detailInfo = res.data.list;
						this.eva_count = res.data.eva_count;
						this.$store.commit("iscollection", res.data.collection);
						let arr = [{
								name: "心理测试",
								url: "/TestHome"
							},
							{
								name: res.data.list.title,
								url: ""
							},
						];
						localStorage.setItem("route", JSON.stringify(arr));
						this.$store.commit("newRoute", arr);
						if (res.data.eva_list.length == 20) {
							this.more = true;
						} else {
							this.more = false;
						}
						if (this.page > 1) {
							this.evaList = this.evaList.concat(res.data.eva_list);
						} else {
							this.evaList = res.data.eva_list;
						}

						if (type) {
							this.goTest()
						}
					}
				);
			},
			getresult() {
				GetTestResult({
					log_id: this.$route.query.successId,
					page: this.page,
				}).then((res) => {
					console.log(11111, res);
					this.detailInfo = res.data.test;
					this.eva_count = res.data.eva_count;
					this.result_content = res.data.result_content;
					this.result_title = res.data.result_title;
					if (res.data.eva_list.length == 20) {
						this.more = true;
					} else {
						this.more = false;
					}
					if (this.page > 1) {
						this.evaList = this.evaList.concat(res.data.eva_list);
					} else {
						this.evaList = res.data.eva_list;
					}
				});
			},
		},
		created() {
			if (this.$route.query.successId) {
				this.testType = 1;
				this.getresult();
			} else {
				this.type = 2;
				this.getList();
			}
		},
	};
</script>
<style lang="scss">
	// .introduce {
	//   img {
	//     width: 540px;
	//   }
	// }
</style>
<style scoped lang="scss">
	.content {
		background-color: #fff;
		border-radius: 6px;
		// min-width: 960px;
		// width: 960px;
		// width: calc(100% - 430px);
		max-width: calc(100% - 430px);
		// overflow: hidden;

		.testInfo {
			border-bottom: 1px solid #f4f4f4;

			.infoleft {
				flex: 1;
				padding: 30px;
				border-right: 1px solid #f4f4f4;
				font-size: 32px;
				font-weight: 800;
				color: #4b4b65;
				line-height: 48px;

				.time {
					font-size: 16px;
					font-weight: 400;
					color: #c0c4cc;
					line-height: 22px;

					.numberList {
						font-size: 14px;
						color: #c0c4cc;
					}
				}

				.price {
					font-size: 16px;
					font-weight: 800;
					color: #5473e8;
					line-height: 24px;
				}

				.redcolor {
					color: #f04352;
				}
			}

			.inforight {
				flex: 0 0 231px;
				padding: 0 30px;
				justify-content: center;
				box-sizing: border-box;

				.goTest {
					background: #4b4b65;
					text-align: center;
					font-size: 14px;
					font-weight: 400;
					color: #ffffff;
					line-height: 20px;
					padding: 8px 14px;
					border-radius: 6px;
				}

				.goApp {
					background: #ec977a;
					position: relative;

					.downLoad {
						position: absolute;
						left: -100px;
						top: 48px;
						display: none;
					}

					&:hover .downLoad {
						display: block;
					}
				}

				.customer {
					background: #f4f4f4;
					text-align: center;
					font-size: 14px;
					font-weight: 400;
					color: #51516a;
					line-height: 20px;
					border-radius: 6px;
					padding: 8px 10px;
					margin-left: 20px;
					position: relative;

					.serve {
						position: absolute;
						top: 40px;
						width: 182.5px;
						height: 209px;
						display: none;

						img {
							width: 100%;
							height: 100%;
						}

						left: -100px;
					}

					&:hover .serve {
						display: block;
					}
				}
			}
		}

		.introduce {
			padding: 60px 30px;
		}

		.func {
			margin: 0 30px;
			padding: 0 0 30px;
			justify-content: space-between;
			border-bottom: 1px solid #f4f4f4;

			font-size: 16px;
			font-weight: 400;
			color: #b6b5c5;
			line-height: 22px;

			.evaNum {
				display: inline-block;
				background-color: #f04352;
				text-align: center;
				font-size: 12px;
				font-weight: 400;
				color: #ffffff;
				line-height: 17px;
				padding: 2px 10px;
				border-radius: 20px;
			}
		}

		.evaList {
			margin-top: 60px;

			.nodata {
				text-align: center;
				font-size: 16px;
				font-weight: 400;
				color: #c0c4cc;
				line-height: 22px;
				margin-bottom: 60px;
			}

			.login {
				font-size: 20px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #c0c4cc;
				line-height: 80px;
				margin: 0 auto;
				width: 900px;
				height: 80px;
				box-sizing: border-box;
				background: #f7f7f7;
				border: 1px solid #dcdfe6;
				text-align: center;
				margin-bottom: 60px;
			}

			.eva {
				padding: 0 30px;

				.evaOne {
					.evaTop {
						.nickname {
							font-size: 18px;
							font-weight: 600;
							color: #4b4b65;
							line-height: 25px;
						}

						.time {
							font-size: 14px;
							font-weight: 400;
							color: #b6b5c5;
							line-height: 20px;
						}
					}

					.evaBot {
						padding-bottom: 40px;
						border-bottom: 2px dashed #f4f4f4;

						&:last-child {
							border-bottom: none;
						}
					}
				}

				.loadMore {
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #b6b5c5;
					line-height: 20px;
					text-align: center;
					justify-content: center;

					.circle {
						width: 12px;
						height: 12px;
						line-height: 12px;
						font-size: 12px;
						border-radius: 50%;
						background-color: #f4f4f4;
						margin-left: 10px;
						color: #717083;
					}
				}
			}

			.writeEva {
				align-items: flex-start;
			}

			.submit {
				background: #f7f7f7;
				border: 1px solid #dcdfe6;
				text-align: center;
				font-size: 16px;
				font-weight: 400;
				color: #717083;
				line-height: 22px;
				padding: 10px 39px;
				display: inline-block;
				margin: 30px 0 60px 60px;
			}
		}
	}
</style>
