<template>
	<div class="content">
		<div class="setIn">
			<div class="setTop">
				<div class="setSub">
					<i class="el-icon-warning-outline" style="margin-right: 5px;"></i>
					入驻要求
				</div>
				<a class="webEntrance" href="https://teacher.manmanxinli.com/zxsIframe" target="_blank">
					慢慢心理咨询师WEB端
				</a>
			</div>
			<div class="bgimage">
				<img src="../../assets/image/setIn.png" alt="">
			</div>

			<div class="setting">
				<div class="logo">
					<img src="../../assets/image/logo.png" alt="">
				</div>
				<div class="settip">
					<div class="settip1">心理咨询师申请入驻</div>
					<div class="settip2">不限领域风格、时间自由选择</div>
				</div>
				<div class="goApp" @click="downLoadApp1 = true">
					去申请
				</div>
			</div>
		</div>

		<div class="list">
			<div v-if="!isInfo" class="listTit">
				<span class="u-m-r-6">问答分类</span>
				<span>与千万用户一起问问题</span>
			</div>

			<div v-if="!isInfo" class="classBox u-flex">
				<div class="classOne u-m-r-8 u-m-t-8" :class="[activeClass==item.id?'activeClass':'']"
					v-for="(item,index) in $store.state.classArr" :key="index" @click="changeId(item)">
					{{item.name}}
				</div>
			</div>

			<div class="hotQa u-m-t-20">
				<div class="listTit">
					<span class="u-m-r-6">热门问答</span>
					<span>坚持每日一问</span>
				</div>

				<div class="hotBox">
					<div class="qaOne" :class="[hoverQa==idx?'qaHover':'']" v-for="(ite,idx) in hotList" :key="idx"
						@mouseover="hoverQa = idx" @click="goQa(ite)">
						<div class="qaTop u-line-1 u-flex u-row-between">
							<div class="u-line-1">
								<i class="el-icon-chat-line-round u-m-r-4"></i>
								<span style="font-style: oblique;margin-right: 4px;">{{idx+1}}.</span>
								<span>{{ite.title}}</span>
							</div>

						</div>
						<div class="qaMid u-line-2 u-m-t-6 u-m-b-6">
							{{ite.content}}
						</div>
						<div class="qaBot u-flex u-row-between">
							<div class="u-flex">
								<el-image class="gray" style="width: 32px;height: 32px;"
									v-if="objClass1[ite.category_names[0]]" :src="objClass1[ite.category_names[0]]">
								</el-image>
								<span class="u-m-l-4">#{{ite.category_names[0]}}</span>
							</div>

							<div class="u-flex">
								<div class="">
									{{ite.answer_info.create_date.split(' ')[0].split('-').join('.')}}
								</div>
								<div class="u-m-l-6 u-m-r-6" style="width: 2px;height: 12px;background-color: #eeeeee;">
								</div>
								<div class="">
									共{{ite.answer_num+ite.comment_num}}人参与
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		
		<div class="appDownload u-m-t-20" style="height: 140px">
			<img style="width: 100%;background-color: #ffffff;border-radius:6px;"
				src="../../assets/image/rightListDown.png" alt="" />
		</div>
		
		<el-dialog title="提示" :visible.sync="downLoadApp1" width="30%">
			<div>
				<div style="text-align: center;margin-bottom: 20px;font-size: 18px;font-weight: 700;">请下载App，在APP内申请
				</div>
				<div style="display: flex;align-items: center;justify-content: center;">
					<img style="width: 100%;" src="../../assets/image/downLoadPopZxs.png" alt="">
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		queryQuestionsList,
		queryQuestionsCategoryList
	} from '@/api/api.js'
	export default {
		data() {
			return {
				downLoadApp1: false,
				activeClass: '',
				hotList: [],
				objClass1: {},
				articleActive: 0,
				hoverQa: 0,
			}
		},
		props:{
			isInfo: {
				type: Boolean,
				default: false,
			},
		},
		watch: {
			"$store.state.classArr":function(res){
				let obj = {}
				res.forEach(val => {
					obj[val.name] = val.image
				})
				this.objClass1 = obj
			}
		},
		mounted() {
			this.getHotList()
		},
		methods: {
			goQa(item){
				let arr = [{
						name: "求助问答",
						url: "/QaHome"
					},
					{
						name: item.title,
						url: ""
					},
				];
				// this.$store.commit("iscollection", item.is_collect);
				localStorage.setItem("route", JSON.stringify(arr));
				this.$store.commit("newRoute", arr);
				this.$router.push({
					name: "QaInfo",
					query: {
						id:item.id
					}
				});
			},
			getHotList() {
				queryQuestionsList({
					page: 1,
					perpage: 5,
					istop: 1
				}).then(res => {
					// console.log(res)
					this.hotList = res.data.list
				})
			},
			changeId(item) {
				if (this.activeClass == item.id) return;
				this.activeClass = item.id
				this.$EventBus.$emit('changeIds', item.id, item.name)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.content {
		// width: 380px;
		border-radius: 6px;
		overflow: hidden;
		flex: 1;

	}



	// 入驻
	.setIn {
		width: 100%;
		height: 140px;
		background: #FFFBFA;
		border-radius: 6px;
		background: #FFFBFA;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		.setTop {
			position: absolute;
			top: 0;
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.setSub {
				background: #FFF3EF;
				padding: 7px 10px;
				border-radius: 0 0 8px 0;

				font-size: 12px;
				font-family: SourceHanSansCN-Normal, SourceHanSansCN;
				font-weight: 400;
				color: #EC977A;
				line-height: 18px;
			}

			.webEntrance {
				padding-right: 15px;
				font-size: 12px;
				color: #EC977A;
				cursor: pointer;
			}
		}

		.bgimage {
			position: absolute;
			bottom: 0;
			right: 0;

			img {
				width: 102px;
				height: 64px;
			}
		}

		.setting {
			display: flex;
			align-items: center;
			justify-content: center;

			.logo {
				img {
					width: 50px;
					height: 50px;
					border-radius: 10px;
				}
			}

			.settip {
				margin: 0 40px 0 10px;

				.settip1 {
					font-size: 18px;
					font-weight: 800;
					color: #4B4B65;
					line-height: 27px;
				}

				.settip2 {
					margin-top: 10px;
					font-size: 14px;
					font-weight: 400;
					color: #C0C4CC;
					line-height: 20px;
				}
			}

			.goApp {
				text-align: center;
				line-height: 31px;
				width: 74px;
				height: 31px;
				background: #EC977A;
				box-shadow: 0px 2px 5px 0px #F6DDD7;
				font-size: 12px;
				font-weight: 600;
				color: #FFFFFF;
				// text-shadow: 0px 2px 5px #F6DDD7;
				border-radius: 30px;
				position: relative;
				z-index: 2;
			}
		}
	}

	//分类
	.list {
		background-color: #fff;
		padding: 20px;

		.listTit {
			:first-child {
				font-size: 20px;
				color: #4B4B65;
				line-height: 28px;
			}

			:last-child {
				font-size: 12px;
				color: #717083;
				line-height: 14px;
			}
		}

		.classBox {
			flex-wrap: wrap;

			.classOne {
				font-size: 12px;
				font-weight: 400;
				color: #4B4B65;
				line-height: 26px;
				padding: 0px 6px;
				background: #FDFDFD;
				border-radius: 17px;
				border: 1px solid #f7f7f7;
			}

			.activeClass {
				color: #EC977A;
				background: rgba(236, 151, 122, 0.05);
				border: 1px solid #EC977A;
			}
		}
	}

	.qaOne {
		padding: 20px 0;

		// flex: 0 0 49%;
		// width: 0;
		font-size: 14px;
		color: #cdcccf;
		cursor: pointer;
		transition: all 0.5s;

		&:not(:last-child) {
			border-bottom: 1px solid #eeeeee;
		}

		.qaTop {
			font-weight: 800;
			font-size: 18px;
		}

		.qaMid {
			height: 32px;
			line-height: 16px;
		}

		.qaBot {
			font-size: 12px;
		}

		.gray {
			filter: grayscale(100%);
		}
	}

	.qaHover {
		color: #4B4B65;

		.gray {
			filter: grayscale(0);
		}
	}

	.qaHide {
		opacity: 0;
	}
</style>
