<template>
	<div class="content">
		<div class="contentBox">
			<div class="leftpic">
				<img src="../../../src/assets/image/loginPic.png" alt="" />
			</div>
			<div class="boxtop display">
				<div class="logo display">
					<img style="width:154px;height:48px;" src="../../../src/assets/image/topLogo.png" alt="" />
					<!-- <div>
            <div class="LogoTop">慢慢心理</div>
            <div class="LogoBottom">专业心理咨询平台</div>
          </div> -->
				</div>
				<div class="goOn" @click="skip">直接进入</div>
			</div>
			<div class="boxbot">
				<div class="rightbox">
					<div class="tit">登录/注册</div>
					<div class="form">
						<div class="account display">
							<iconpark-icon style="margin-right: 10px" name="avatar"></iconpark-icon>
							<el-input placeholder="输入手机号码或邮箱" maxlength="30" v-model="form.account"></el-input>
						</div>

						<div class="account display" style="margin-top: 30px">
							<iconpark-icon style="margin-right: 10px" name="mail"></iconpark-icon>
							<el-input placeholder="输入验证码" maxlength="4" v-model="form.code"></el-input>
							<div class="sendCode" @click="sednCode">
								{{
                  cuttime == 60 ? "发送验证码" : "重新发送（" + cuttime + "s）"
                }}
							</div>
						</div>

						<div class="login" style="margin-top: 30px">
							<div class="loginbtn" @click="loginSubmit">立即进入</div>
						</div>

						<div class="agree">
							<el-checkbox v-model="checked" style="margin-right: 10px" @change="changeAgree">
							</el-checkbox>我已阅读并接受<span @click="showAgree(1, '用户协议')">《 用户协议 》</span>和<span
								@click="showAgree(2, '隐私政策')">《隐私政策》</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="botfont"><a target="_blank" href="http://beian.miit.gov.cn" style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
        ">
				<p style="
            float: left;
            height: 26px;
            line-height: 26px;
            margin: 0px 0px 0px 5px;
            color: #939393;
          ">
					<img src="../../assets/image/gh.png" alt="">
					京公网安备
					11010502047884号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;京ICP备2021033378号
				</p>
			</a></div>

		<agreement-popup :show.sync="showPop" :content="content" @close="showPop = false" :title="title">
		</agreement-popup>
	</div>
</template>

<script>
	let timer = null;
	const regMobile = /^1([38][0-9]|4[579]|5[0-3,5-9]|6[0-9]|7[0135678]|9[89])\d{8}$/;
	const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;

	import {
		SendPhoneCode,
		SendEmailCode,
		LoginByPhone,
		LoginByEmail,
		GetAgreementInfo,
		GetUserinfo,
	} from "@/api/api.js";
	import {
		createSocket,
		sendWSPush,
		closeSocket
	} from '@/utils/websocket'
	
	import AgreementPopup from "@/components/AgreementPopup/AgreementPopup.vue";
	export default {
		name: "LoginPage",
		data() {
			return {
				form: {
					account: "",
					code: "",
				},
				cuttime: 60, //倒计时
				sendFlag1: false, //手机号码验证
				sendFlag2: false, //邮箱验证
				sendLock: false, //发送验证码锁
				loginLock: false, //登录锁
				checked: false, //同意协议
				showPop: false, //显示协议弹窗
				content: "", //协议内容
				title: "", //协议标题
			};
		},
		watch: {
			"form.account"(val) {
				if (regMobile.test(val)) {
					this.sendFlag1 = true;
				} else if (regEmail.test(val)) {
					this.sendFlag2 = true;
				} else {
					this.sendFlag1 = false;
					this.sendFlag2 = false;
				}
			},
		},
		components: {
			AgreementPopup,
		},
		created() {},
		methods: {
			showAgree(id, title) {
				this.title = title;
				GetAgreementInfo({
					ids: id
				}).then((res) => {
					console.log(res);
					this.content = res.data.list.content;
					this.showPop = true;
				});
			},
			changeAgree() {
				console.log(123123, this.checked);
			},
			loginSubmit() {
				if (!this.checked) {
					this.$message.warning("请先阅读《 用户协议 》和 《隐私政策》");
					return;
				}
				if (this.loginLock) {
					return;
				}
				this.loginLock = true;
				let data = {};
				if (this.sendFlag1) {
					data = {
						mobile: this.form.account,
						captcha: this.form.code,
						group: 2,
						imei: 1,
						sources: "WEB",
						openid: "",
					};
					LoginByPhone(data).then((res) => {
						console.log(res);
						this.loginSuccess(res);
					});
				} else if (this.sendFlag2) {
					data = {
						email: this.form.account,
						captcha: this.form.code,
						group: 2,
						imei: 1,
						sources: "WEB",
						openid: "",
					};
					LoginByEmail(data).then((res) => {
						this.loginSuccess(res);
					});
				} else {
					this.loginLock = false;
					this.$message.error("请输入正确的手机号码或邮箱！");
				}
			},
			loginSuccess(res) {
				this.loginLock = false;
				if (res.code == 1) {
					createSocket();
					this.$EventBus.$emit('onMessage');
					this.$store.commit("login", res.data);
					this.$message.success("登录成功");
					if (this.$route.query.back) {
						this.$router.back(); // 返回上一个路由
					} else {
						this.$router.push({
							name: "AllHome"
						});
					}
					
					GetUserinfo({
						token: res.data.token
					}).then((res) => {
						console.log(123, res);
						this.$store.commit("setuserInfo", res.data.list);
					});
				} else {
					this.$message.error(res.msg);
				}
			},
			skip() {
				this.$router.push({
					name: "AllHome"
				});
			},
			sednCode() {
				if (this.sendLock) {
					return;
				}
				this.sendLock = true;
				let account = this.form.account;
				let data = {};
				if (this.sendFlag1) {
					data = {
						mobile: account,
						event: "mobile",
						group: 2,
					};
					SendPhoneCode(data).then((res) => {
						if (res.code == 1) {
							console.log("发送成功");
						} else {
							this.$message.error(res.msg);
						}
					});
					this.cutdownTime();
				} else if (this.sendFlag2) {
					data = {
						email: account,
						event: "email",
						group: 2,
					};
					SendEmailCode(data).then((res) => {
						if (res.code == 1) {
							console.log("发送成功");
						} else {
							this.$message.error(res.msg);
						}
					});
					this.cutdownTime();
				} else {
					this.sendLock = false;
					this.$message.error("请输入正确的手机号码或邮箱！");
				}
			},
			cutdownTime() {
				timer = setInterval(() => {
					if (this.cuttime > 0) {
						this.cuttime -= 1;
					} else {
						clearInterval(timer);
						this.cuttime = 60;
						this.sendLock = false;
					}
				}, 1000);
			},
		},
	};
</script>
<style lang="scss">

</style>
<style lang="scss" scoped>
	::v-deep .el-input__inner {
		border: none;
	}

	.content {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		background: url("/src/assets/image/loginBg.png") 100% 100%;
		box-sizing: border-box;
		padding: 100px;
		// min-width: 1300px;
		// max-width: 1300px;
		min-height: 850px;

		.contentBox {
			background: url("/src/assets/image/loginBox.png") 100% 100%;
			// background: linear-gradient(
			//   360deg,
			//   rgba(255, 255, 255, 0.1) 0%,
			//   rgba(255, 255, 255, 0.8) 100%
			// );
			box-shadow: 0px 30px 20px 0px rgba(0, 0, 0, 0.05);
			// border: 2px solid #f7f7f7;
			backdrop-filter: blur(30.75334821428572px);
			border-radius: 50px;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			padding: 30px;

			.boxtop {
				justify-content: space-between;
			}

			.logo {
				padding: 16px 0;

				img {
					width: 48px;
					height: 48px;
				}

				.LogoTop {
					width: 73px;
					height: 18px;
					font-size: 18px;
					font-weight: 800;
					color: #51516a;
					line-height: 27px;
					margin-left: 10px;
				}

				.LogoBottom {
					width: 96px;
					height: 12px;
					font-size: 12px;
					font-weight: 400;
					color: #c0c4cc;
					line-height: 18px;
					margin-top: 8px;
				}
			}

			.goOn {
				padding: 13px 28px;
				font-size: 16px;
				font-weight: 600;
				color: #ffffff;
				line-height: 22px;
				border-radius: 50px;
				background: #252631;
			}

			.leftpic {
				position: absolute;
				bottom: 30px;
				left: 10%;
			}

			.boxbot {
				.rightbox {
					background: linear-gradient(360deg,
							rgba(255, 255, 255, 0.1) 0%,
							rgba(255, 255, 255, 0.8) 100%);
					box-shadow: 0px 30px 20px 0px rgba(0, 0, 0, 0.05);
					border: 2px solid #f7f7f7;
					backdrop-filter: blur(30.75334821428572px);
					padding: 30px;
					position: absolute;
					right: 10%;
					top: 180px;
					width: 460px;
					box-sizing: border-box;
					border-radius: 20px;

					.tit {
						text-align: center;
						font-size: 32px;
						font-weight: 600;
						color: #252631;
						line-height: 45px;
						margin-bottom: 30px;
					}

					.form {
						width: 100%;

						.account {
							border: 1px solid #dcdfe6;
							border-radius: 6px;
							padding: 0 20px;

							.sendCode {
								font-size: 14px;
								font-weight: 400;
								color: #c0c4cc;
								line-height: 20px;
								width: 200px;
								text-align: right;
							}
						}
					}

					.login {
						.loginbtn {
							width: 100%;
							background: #5473e8;
							font-size: 18px;
							font-weight: 600;
							color: #ffffff;
							line-height: 25px;
							text-align: center;
							padding: 8px;
							box-sizing: border-box;
							border-radius: 6px;
						}
					}

					.agree {
						margin-top: 20px;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #c0c4cc;
						line-height: 20px;
					}
				}
			}
		}

		.botfont {
			margin-top: 40px;
			text-align: center;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #3d3d40;
			line-height: 22px;
		}
	}
</style>
