<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <div v-html="content"></div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "AgreementPopup",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    show() {
      this.dialogVisible = this.show;
    },
  },

  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">

</style>
<style lang="scss" scoped>
::v-deep ::-webkit-scrollbar {
  display: none;
}

::v-deep .el-dialog {
  margin-top: 20px;
  height: 80%;
  overflow: hidden;
  border-radius: 6px;
  .el-dialog__body {
    position: absolute;
    left: 0;
    top: 54px;
    bottom: 0;
    right: 0;
    padding: 0;
    z-index: 1;
    overflow: hidden;
    overflow-y: auto;
    padding: 40px;
  }
}
</style>