import Vue from 'vue'
import Vuex from 'vuex'
import cookie from "vue-cookies"

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		route: '',
		token: cookie.get("token"),
		chatid: cookie.get("chatid"),
		userInfo: '',
		collection: 0,
		walletInfo: {},
		navbarId: cookie.get("navbarId") || 0,
		classArr:[],
	},
	mutations: {
		//设置面包屑路由
		newRoute(state, route) {
			state.route = route
		},
		//登录
		login(state, param) {
			state.token = param.token
			state.chatid = param.chatid
			cookie.set("token", param.token);
			cookie.set("chatid", param.chatid);
		},
		//退出登录
		logout(state) {
			state.token = ''
			state.chatid = ''
			state.userInfo = ''
			cookie.remove("token");
			cookie.remove("chatid");
			Vue.prototype.$EventBus.$emit('closeSocket')
		},
		//获取用户信息
		setuserInfo(state, param) {
			state.userInfo = param
		},
		//是否收藏测试题
		iscollection(state, param) {
			state.collection = param
		},
		setwalletInfo(state, param) {
			state.walletInfo = param
		},
		changeId(state, param) {
			// cookie.set("navbarId", param);
			state.navbarId = param
		},
		SET_CLASS_ARR(state, param){
			state.classArr = param
		},
	},
	actions: {
		logoutAction({
			commit,
			state,
			dispatch
		}) {
			commit('logout')
		},
	},
	getters: {

	}
})

export default store
