import { render, staticRenderFns } from "./ArRight.vue?vue&type=template&id=4220814a&scoped=true&"
import script from "./ArRight.vue?vue&type=script&lang=js&"
export * from "./ArRight.vue?vue&type=script&lang=js&"
import style0 from "./ArRight.vue?vue&type=style&index=0&id=4220814a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4220814a",
  null
  
)

export default component.exports