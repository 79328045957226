<template>
  <div class="content">
    <div class="userInfo">
      <div class="avatar display">
        <el-avatar :size="100" :src="userInfo.avatar"></el-avatar>
      </div>
      <div class="nickname">{{ userInfo.nickname }}</div>
      <div class="grade"></div>

      <div class="info display">
        <div>
          <div class="topNum">
            {{ 0 }}<span style="font-size: 16px">张</span>
          </div>
          <div class="subTit">优惠券</div>
        </div>
        <div>
          <div class="topNum">
            {{ userInfo.score>1000?'999+': userInfo.score}}<span style="font-size: 16px">分</span>
          </div>
          <div class="subTit">积分</div>
        </div>
        <div>
          <div class="topNum">
            {{ userInfo.growth>1000?'999+': userInfo.growth }}
          </div>
          <div class="subTit">成长值</div>
        </div>
        <div v-if="false">
          <div class="topNum">
            {{ 0 }}<span style="font-size: 16px">人</span>
          </div>
          <div class="subTit">粉丝数量</div>
        </div>
      </div>
    </div>
    <div class="quickEnter" v-if="false">
      <div class="title">快速入口</div>

      <div class="quickList">
        <div class="quickOne">
          <iconpark-icon class="middle" name="home"></iconpark-icon>
          <span class="middle" style="margin-left: 10px">我的主页</span>
        </div>
        <div class="quickOne">
          <iconpark-icon
            class="middle"
            style="color: #c8aa75"
            name="level"
          ></iconpark-icon>
          <span class="middle" style="margin-left: 10px">会员中心</span>
        </div>
        <div class="quickOne">
          <iconpark-icon class="middle" name="local"></iconpark-icon>
          <span class="middle" style="margin-left: 10px">地址管理</span>
        </div>
        <div class="quickOne">
          <iconpark-icon class="middle" name="protect"></iconpark-icon>
          <span class="middle" style="margin-left: 10px">安全设置</span>
        </div>

        <div class="line"></div>
      </div>
    </div>
    <div class="tabList" v-for="(item, index) in tabList" :key="index">
      <div class="title" style="margin-bottom: 16px">{{ item.title }}</div>

      <div
        v-for="(x, i) in item.children"
        :key="i"
        :class="['listOne', activeId == x.id ? 'active' : '']"
        @click="changeId(x, i)"
      >
        {{ x.title }}
      </div>

      <div class="line" v-if="index != tabList.length - 1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PersonalLeft",
  data() {
    return {
      activeId: 0,
      tabList: [
        {
          title: "我的账户",
          children: [
            {
              title: "个人中心",
              url: "GoApp",
              id: 0,
            },
            {
              title: "消息通知",
              url: "GoApp",
              id: 1,
            },
            // {
            //   title: "个人信息",
            //   url: "GoApp",
            //   id: 2,
            // },
            {
              title: "我的余额",
              url: "MyBalance",
              id: 3,
            },
            // {
            //   title: "我的足迹",
            //   url: "GoApp",
            //   id: 4,
            // },
            // {
            //   title: "邀请有礼",
            //   url: "GoApp",
            //   id: 5,
            // },
            // {
            //   title: "幸运抽奖",
            //   url: "GoApp",
            //   id: 6,
            // },
          ],
        },
        {
          title: "交易管理",
          children: [
            {
              title: "我的订单",
              url: "GoApp",
              id: 7,
            },
            // {
            //   title: "优惠券",
            //   url: "GoApp",
            //   id: 8,
            // },
          ],
        },
        {
          title: "内容管理",
          children: [
            {
              title: "我的收藏",
              url: "MyCollection",
              id: 9,
            },
          ],
        },
        {
          title: "帮助中心",
          children: [
            {
              title: "帮助中心",
              url: "HelpCenter",
              id: 10,
            },
            // {
            //   title: "在线客服",
            //   url: "GoApp",
            //   id: 11,
            // },
          ],
        },
      ],
    };
  },
  computed: {
    userInfo: {
      get() {
        return this.$store.state.userInfo;
      },
      set() {},
    },
  },
  mounted(){
      if(this.$route.query.title){
          let list = this.tabList
          list.forEach((item)=>{
              item.children.forEach(i=>{
                  if(i.title == this.$route.query.title){
                      this.activeId = i.id
                  }
              })
          })
      }
  },
  methods: {
    changeId(x, i) {
      console.log(x, i);
      this.activeId = x.id;
      if(x.url=='GoApp'){
          this.$router.push({name:'GoApp',query:{title:x.title}})
      }else{
          this.$router.push({name:x.url,query:{title:x.title}})
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  background-color: #fff;
  border-radius: 6px;
  width: 360px;
  min-width: 360px;
  box-sizing: border-box;
  padding-bottom: 30px;
  .title {
    margin-top: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    color: #4b4b65;
    line-height: 30px;
  }
  .line {
    width: 300px;
    height: 1px;
    background-color: #f7f7f7;
    margin: 30px auto 0;
  }
  .userInfo {
    padding: 30px 30px 0;
    .avatar {
      width: 100%;
      justify-content: center;
    }
    .nickname {
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #252631;
      line-height: 28px;
      margin-top: 10px;
    }
    .info {
      text-align: center;
      margin-top: 30px;
      padding-bottom: 30px;
      justify-content: space-between;
      border-bottom: 1px solid #f7f7f7;
      .topNum {
        font-size: 30px;
        font-weight: 600;
        color: #252631;
        line-height: 42px;
        height: 47px;
      }
      .subTit {
        font-size: 14px;
        font-weight: 400;
        color: #c0c4cc;
        line-height: 20px;
      }
    }
  }
  .quickList {
    padding: 0 30px;
    margin-top: 30px;
    .quickOne {
      width: 300px;
      padding: 10px 0;
      background: #fbfbfb;
      border: 1px solid #f4f4f4;
      font-size: 14px;
      font-weight: 400;
      color: #c0c4cc;
      line-height: 20px;
      border-radius: 6px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .tabList {
    // padding: 0 30px;
    .listOne {
      height: 50px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: #717083;
      line-height: 50px;
    }
    .active {
      height: 50px;
      background: #f5f5f5;
      font-weight: 600;
      color: #252631;
      border-left: 5px solid #ec977a;
      border-right: 5px solid transparent;
      position: relative;
      box-sizing: border-box;
    }
  }
}
</style>